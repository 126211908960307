import { publish, subscribe, pubSubTopic } from "../../pubSub";
import { carRepairIcon } from "../../svgIcons";

export function loginDialog(text: string = "") {
  var subscriptions: ReturnType<typeof subscribe>[] = [];

  var el = document.createElement("DIV");
  el.id = "login-dialog";

  el.innerHTML =
    '<div class="dialog-container">' +
    '<div class="image-row">' +
    '<svg xmlns="http://www.w3.org/2000/svg" ' +
    carRepairIcon.viewBox +
    ">" +
    carRepairIcon.path +
    "</svg>" +
    "<span>Web-Verwaltung</span></div>" +
    '<div class="content">' +
    text +
    "</div>" +
    '<div class="input"><form action="#">' +
    '<div class="sf-mdl-textfield">' +
    '<input required placeholder="Benutzername / E-Mail" type="text" id="user" autocomplete="off">' +
    '</div><div class="sf-mdl-textfield">' +
    '<input required placeholder="Passwort" type="password" id="pass" autocomplete="off">' +
    "</div></form></div>" +
    '<div><input type="checkbox" id="save-auth">' +
    '<label for="save-auth">Anmeldung für eine Woche speichern</label></div>' +
    '<div class="actions">' +
    '<button class="sf-mdl-button" id="login-button">Einloggen</button></div>' +
    "</div></div>";

  var loginTextEl = el.querySelector("div.content");

  var submitFct = function () {
    loginTextEl.innerText = "Gesendet...";

    publish(pubSubTopic.loginSubmitted, {
      topic: pubSubTopic.loginSubmitted,
      user: document.getElementById("user").value,
      pass: document.getElementById("pass").value,
      saveLogin: document.getElementById("save-auth").checked,
    });
  };

  var closeFct = function () {
    el.parentNode.removeChild(el);
    el = null;
    subscriptions.forEach(function (item) {
      item.remove();
    });
  };

  interface loginErrorPayload {
    statusText: string;
  }

  subscriptions.push(
    subscribe(pubSubTopic.loginError, function (payload: loginErrorPayload) {
      loginTextEl.innerText = payload.statusText;
      loginTextEl.className = "content error";
    })
  );
  subscriptions.push(subscribe(pubSubTopic.loginCancel, closeFct));
  subscriptions.push(subscribe(pubSubTopic.loginSuccessfull, closeFct));

  el.children[0].onclick = function (evt: MouseEvent) {
    evt.stopPropagation();
  };
  el.querySelector("button#login-button").addEventListener("click", submitFct);
  el.querySelector("input#user").addEventListener("keyup", function (evt) {
    if (evt.keyCode === 13) document.getElementById("pass").focus();
  });
  el.querySelector("input#pass").addEventListener("keyup", function (evt) {
    if (evt.keyCode === 13) submitFct();
  });

  document.body.appendChild(el);
  el.querySelector("input#user").focus();
}
