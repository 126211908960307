import { subscribe, publish, pubSubTopic } from "../../pubSub";

import { fetchPage, saveData, checkLatestDataUX } from "../globalFunctions";

import { showToast } from "../components/toast";
import { deleteIcon, saveIcon } from "../../svgIcons";
import { getConfirmDialog } from "../components/dialogs/confirmDialog";

import { Artikel } from "../../../../globalData/appInterfaces";
import { EditType } from "../../../../globalData/dataEditInterfaces";
import { debounce, objToArray, getKeySearchString, strToFloat, numToStr } from "../../../../globalData/helperFunctions";
import { pageNavInitPayload } from "../navigation/navigation";
import { navDestination } from "../navigation/navConstants";
import { getSelectionDialog } from "../components/dialogs/selectionDialog";

export const articleURL = "pages/artikel/artikel.html";
let articleArr: Array<Artikel>;

var filterArticles = debounce(function (event: Event) {
  showArticles(event.target.value);
}, 350);

export function articlePageInit() {
  subscribe(pubSubTopic.pageNavInitiated, articlePageNavigation);
}

export function articlePageNavigation(payload: pageNavInitPayload) {
  if (payload.destination === navDestination.articleList) {
    articlePage();
  }
}

export function articlePage() {
  fetchPage(articleURL, "artikel", navDestination.articleList).then(function () {
    window.sessionData.setLastView(navDestination.articleList);

    document.getElementById("artikel-filter").addEventListener("input", filterArticles);
    articleArr = objToArray(window.data.artikel).sort((a: Artikel, b: Artikel) => {
      return a.nr > b.nr ? 1 : -1;
    });
    setButtonFunctionality();
    showArticles();

    checkLatestDataUX();
  });
}

function setButtonFunctionality() {
  for (const elmt of document.querySelectorAll(".addArticle")) {
    elmt.addEventListener("click", addArticle);
  }
}

export function showArticles(filterString: string = "") {
  var listEl = document.createElement("UL");
  listEl.className = "article-list";

  articleArr
    .filter(function (item) {
      if (filterString) {
        var searchResult = true;
        var search = filterString.split(" ");
        search.forEach(function (query) {
          if (query) {
            searchResult = searchResult && getKeySearchString(item).indexOf(query.toLowerCase()) !== -1;
          }
        });

        return searchResult;
      } else return true;
    })
    .forEach((item) => {
      listEl.appendChild(getElement(item));
    });

  var oldChild = document.querySelector("UL");

  if (oldChild === null) {
    var refEl = document.querySelector("#article-list-title");
    refEl.parentNode.insertBefore(listEl, refEl.nextSibling);
  } else {
    oldChild.parentNode.replaceChild(listEl, oldChild);
  }
}

const entryCheck = function entryCheck(varArtikel: Artikel): boolean {
  if (varArtikel.nr === "") {
    showToast("Artikelnummer fehlt");
    return false;
  } else if (varArtikel.bez === "") {
    showToast("Bezeichnung fehlt");
    return false;
  } else return true;
};

export function addArticle() {
  let listEl = document.querySelector("ul.article-list");
  let newEl = getElement();
  listEl.appendChild(newEl);
  newEl.scrollIntoView();
}

function deleteIconFunction(evt: Event) {
  let elArtikel = evt.target.parentNode.parentNode;
  let delArtikel = window.data.artikel[elArtikel.getAttribute("data-id")];
  if (delArtikel === undefined) elArtikel.parentNode.removeChild(elArtikel);
  else {
    getConfirmDialog(`Sind Sie sich sicher, dass Sie den Artikel: ${delArtikel.bez} löschen möchten?`).then(function (
      res
    ) {
      if (res) {
        saveData([{ type: EditType.articleDelete, payload: delArtikel.id }]).then((saveRes) => {
          if (saveRes) elArtikel.parentNode.removeChild(elArtikel);
        });
      }
    });
  }
}

function saveIconFunction(evt: Event) {
  let elArtikel = evt.target.parentNode.parentNode;
  let strAmount = elArtikel.querySelector(".artikel-menge").value;
  if (strAmount.length > 0) {
    var finAmount: undefined | number = strToFloat(strAmount);
  } else var finAmount: undefined | number = undefined;

  let savArtikel: Artikel = {
    id: elArtikel.getAttribute("data-id"),
    nr: elArtikel.querySelector(".artikel-nr").value,
    bez: elArtikel.querySelector(".artikel-bez").value,
    preis: strToFloat(elArtikel.querySelector(".artikel-preis").value),
    menge: finAmount,
  };
  let compArtikel = window.data.artikel[savArtikel.id];

  if (
    compArtikel !== undefined &&
    compArtikel.nr === savArtikel.nr &&
    compArtikel.bez === savArtikel.bez &&
    compArtikel.preis === savArtikel.preis &&
    compArtikel.menge === savArtikel.menge
  ) {
    showToast("Keine Änderung vorgenommen");
  } else if (entryCheck(savArtikel)) {
    saveData([{ type: EditType.articleEdit, payload: savArtikel }]);
  }
}

const getArticleID = function getArticleID() {
  if (window.data.artikel["Art" + articleArr.length] === undefined) return "Art" + articleArr.length;
  else {
    for (let ii = articleArr.length - 1; ii > -1; ii--) {
      if (window.data.artikel["Art" + ii] === undefined) return "Art" + ii;
    }
  }
};

function getElement(item?: Artikel) {
  let newEl = document.createElement("LI");
  newEl.setAttribute("data-id", typeof item === "object" ? item.id : getArticleID());
  newEl.innerHTML = `
  <div class="article-header-item sf-mdl-textfield">
    <input class="artikel-nr" required placeholder="Artikel-Nr." type="text"">
  </div>
  <div class="article-header-item sf-mdl-textfield">
    <input class="artikel-preis" required placeholder="Preis / Einheit" type="text">
  </div>
  <div class="article-header-item sf-mdl-textfield">
    <input class="artikel-menge" required placeholder="Menge" type="text">
  </div>
  <div class="article-header-item sf-mdl-textfield">
    <input class="artikel-bez" required placeholder="Bezeichnung" type="text">
  </div>
  <div class="article-header-item actions">
    <button class="sf-mdl-button save-icon">
    <svg class="save-icon" height="24px" ${saveIcon.viewBox}>
    ${saveIcon.path}
    </svg>
    </button>
    <button class="sf-mdl-button delete-icon">
    <svg class="delete-icon" height="24px" ${deleteIcon.viewBox}>
    ${deleteIcon.path}
    </svg>
    </button>
  </div>
  `;

  newEl.querySelector("button.delete-icon").addEventListener("click", deleteIconFunction, window.safePassiveEvent);

  newEl.querySelector("button.save-icon").addEventListener("click", saveIconFunction, window.safePassiveEvent);

  if (item) {
    newEl.querySelector(".artikel-nr").value = item.nr;
    newEl.querySelector(".artikel-preis").value = numToStr(item.preis);
    newEl.querySelector(".artikel-bez").value = item.bez;
    if (item.menge === 0 || !!item.menge) {
      newEl.querySelector(".artikel-menge").value = numToStr(item.menge);
    }
  }

  return newEl;
}

export const getArticleSelectionDialog = function getArticleSelectionDialog() {
  var selectionList: string[] = [];
  var searchList: string[] = [];
  var keyArr: string[] = [];
  var tooltipList: string[] = [];

  Object.entries(window.data.artikel)
    .sort(function (a, b) {
      return a[1].nr < b[1].nr ? -1 : 1;
    })
    .forEach(function (item) {
      selectionList.push(item[1].nr + " - " + item[1].bez);
      searchList.push(getKeySearchString(item[1]));
      keyArr.push(item[1].id);
      tooltipList.push(item[1].bez);
    });

  return getSelectionDialog(selectionList, { searchList, keyArr, tooltipList }).then(function (id) {
    if (typeof id === "string") {
      return window.data.artikel[id];
    }
  });
};
