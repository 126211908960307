import * as dedent from "dedent";

import { getEditLink } from "../htmlElements/variousElements";
import { showToast } from "../components/toast";
import { getSelectionDialog } from "../components/dialogs/selectionDialog";

import { fetchPage, saveData } from "../globalFunctions";

import { viewOrder, setButtonFunctionality, editURL } from "./orderFunctionality";
import { getOrderEditPosition, getOrderEditPosHeader, EditPositionDiv } from "./orderElements";
import {
  Auftrag,
  AuftragStatus,
  ZahlungsArt,
  AuftragsPosition,
  AuftragsPositionHeader,
  AuftragsPositionItem,
} from "../../../../globalData/appInterfaces";
import { orderNewEvent, orderEditEvent, EditType, articleEditEvent } from "../../../../globalData/dataEditInterfaces";
import {
  getDateForDOM,
  dateAddDays,
  getKeySearchString,
  numToStr,
  round,
  strToFloat,
  getVatRate,
} from "../../../../globalData/helperFunctions";
import { navDestination } from "../navigation/navConstants";
import { getConfirmDialog } from "../components/dialogs/confirmDialog";
import { getClientAsString, getSortedClients } from "../clientPage/clientHelpers";

function addEditButtonFunctionality() {
  document.getElementById("addEditPosition").addEventListener("click", addEditPosition.bind(null, null));
  document.getElementById("addPositionHeader").addEventListener("click", addEditPosHeader.bind(null, null));

  let scopedZahlungsEl = document.getElementById("zahlungsziel");
  document.getElementById("datum").addEventListener("change", (evt: Event) => {
    if (evt.target.value && !scopedZahlungsEl.value)
      scopedZahlungsEl.value = getDateForDOM(dateAddDays(new Date(evt.target.value), 7));
  });

  document.querySelector("#datum").addEventListener("change", calculatePositionSum);

  setButtonFunctionality();
}

function fillEditView(argOrder: Auftrag) {
  document.getElementById("titel").value = argOrder.titel;
  document.getElementById("status").value = argOrder.status;
  document.getElementById("datum").value = argOrder.datum;
  if (argOrder.beschreibung) document.getElementById("beschreibung").value = argOrder.beschreibung;
  if (argOrder.kmStand) document.getElementById("kmStand").value = argOrder.kmStand;
  if (argOrder.zahlungsart) document.getElementById("zahlungsart").value = argOrder.zahlungsart;
  if (argOrder.zahlungsziel) document.getElementById("zahlungsziel").value = argOrder.zahlungsziel;

  var linkTarget = document.getElementById("auto-assign-button");
  if (argOrder.auto && argOrder.auto !== "") {
    linkTarget.parentNode.insertBefore(orderEditLink(getEditLink(), argOrder.auto, oeLinkType.car), linkTarget);
  }
  linkTarget = document.getElementById("kunde-assign-button");
  if (argOrder.kunde && argOrder.kunde !== "") {
    linkTarget.parentNode.insertBefore(orderEditLink(getEditLink(), argOrder.kunde, oeLinkType.client), linkTarget);
  }

  argOrder.positionen.forEach(function (pos) {
    if ("ueberschrift" in pos) addEditPosHeader(pos);
    else addEditPosition(pos);
  });
  calculatePositionSum();
}

export function editOrder(currOrderID?: string) {
  return fetchPage(editURL, "eingabe", navDestination.order).then(function () {
    window.sessionData.setLastView(navDestination.order, currOrderID);

    if (typeof currOrderID === "string") fillEditView(window.data.auftrag[currOrderID]);

    addEditButtonFunctionality();
  });
}

export const enum oeLinkType {
  client = "kunde",
  car = "auto",
}

function orderEditLink(el: HTMLElement, key: string, type: oeLinkType) {
  el.id = type;
  el.setAttribute("data-key", key);
  el.classList.add("unique-button");
  if (type === oeLinkType.client) {
    el.innerHTML += getClientAsString(window.data.kunde[key]);
  } else {
    el.innerHTML = el.innerHTML + window.data.auto[key].kennzeichen + " - " + window.data.auto[key].hersteller;
  }
  el.children[0].onclick = function () {
    this.parentNode.parentNode.removeChild(this.parentNode);
  };

  return el;
}

export function orderAddDatalink(type: oeLinkType) {
  // set the second type (if "auto" was selected, the second type is "kunde")
  var secType = type === oeLinkType.car ? oeLinkType.client : oeLinkType.car;
  // dataLink is the button with the assigned car (has the ID of "auto" or "kunde").
  // Null if no item is selected
  var secDataLink = document.getElementById(secType);

  var itemArr: string[] = [];
  var searchList: string[] = [];
  var keyArr: string[] = [];

  // The case for not preselected other item (first assignment)
  if (secDataLink === null) {
    if (type === oeLinkType.car) {
      Object.values(window.data.auto).forEach((car) => {
        itemArr.push(car.kennzeichen + " - " + car.hersteller);
        searchList.push(getKeySearchString(car).toLocaleLowerCase());
        keyArr.push(car.autoNr);
      });
    } else {
      getSortedClients(Object.values(window.data.kunde)).forEach((client) => {
        itemArr.push(getClientAsString(client));
        searchList.push(getKeySearchString(client).toLocaleLowerCase());
        keyArr.push(client.kundenNr);
      });
    }
  } else {
    // pre-filtered case (secDataLink is selected)
    showToast((type !== oeLinkType.car ? "Auto" : "Kunde") + " bereits ausgewählt. Vorgefilterte Liste wird angezeigt");

    if (secType === oeLinkType.client) {
      window.data.kunde[secDataLink.getAttribute("data-key")].autos.forEach(function (clientsCar) {
        itemArr.push(window.data.auto[clientsCar].kennzeichen + " - " + window.data.auto[clientsCar].hersteller);
        searchList.push(JSON.stringify(window.data.auto[clientsCar]).toLowerCase());
        keyArr.push(clientsCar);
      });
    } else {
      window.data.auto[secDataLink.getAttribute("data-key")].kunden.forEach(function (carOwner) {
        itemArr.push(getClientAsString(window.data.kunde[carOwner]));
        searchList.push(JSON.stringify(window.data.kunde[carOwner]).toLowerCase());
        keyArr.push(carOwner);
      });
    }
  }

  getSelectionDialog(itemArr, { searchList, keyArr }).then(function (resKey: string) {
    if (resKey !== null) {
      var dataLink = document.getElementById(type);

      if (dataLink && dataLink.getAttribute("data-key") === resKey) return showToast("Bereits ausgewählt");

      if (dataLink) {
        dataLink.parentNode.replaceChild(orderEditLink(getEditLink(), resKey, type), dataLink);
      } else {
        var linkTarget = document.getElementById(type + "-assign-button");
        linkTarget.parentNode.insertBefore(orderEditLink(getEditLink(), resKey, type), linkTarget);
      }

      // autofill corresponding entry, if the secDataLink is null
      var secKey =
        secType === oeLinkType.client ? window.data.auto[resKey].kunden[0] : window.data.kunde[resKey].autos[0];
      if (secDataLink === null && secKey) {
        var secLinkTarget = document.getElementById(secType + "-assign-button");
        secLinkTarget.parentNode.insertBefore(orderEditLink(getEditLink(), secKey, secType), secLinkTarget);
      }
    }
  });
}

function entryCheck(entry: Auftrag) {
  if (!entry.titel) {
    showToast("Titel fehlt.");
    return false;
  } else if (!entry.datum) {
    showToast("Datum fehlt.");
    return false;
  } else if (!entry.auto) {
    showToast("Auto fehlt.");
    return false;
  } else if (!entry.kunde) {
    showToast("Kunde fehlt.");
    return false;
  }

  for (let index = 0; index < entry.positionen.length; index++) {
    const position = entry.positionen[index];
    if ("ueberschrift" in position) continue;

    if (position.preisEinheit === null) {
      showToast(`Ungültiger Preis für Position ${index + 1}.`);
      return false;
    }
    if (position.rabatt === null) {
      showToast(`Ungültiger Rabatt für Position ${index + 1}.`);
      return false;
    }
    if (position.menge === null) {
      showToast(`Ungültige Menge für Position ${index + 1}.`);
      return false;
    }
  }

  return true;
}

const getStatus = function (strStatus: string): AuftragStatus {
  switch (strStatus) {
    case AuftragStatus.abgebrochen:
      return AuftragStatus.abgebrochen;
    case AuftragStatus.fertig:
      return AuftragStatus.fertig;
    default:
      return AuftragStatus.inArbeit;
  }
};

const getZahlung = function (strZahlung: string): ZahlungsArt {
  switch (strZahlung) {
    case ZahlungsArt.ueberweisung:
      return ZahlungsArt.ueberweisung;
    default:
      return ZahlungsArt.bar;
  }
};

function getOrderDetailsFromDom(): Auftrag {
  let newOrder: Auftrag = {
    titel: document.getElementById("titel").value,
    datum: document.getElementById("datum").value,
    zahlungsart: getZahlung(document.querySelector("#zahlungsart").value),
    zahlungsziel: document.getElementById("zahlungsziel").value,
    kmStand: document.getElementById("kmStand").value,
    beschreibung: document.getElementById("beschreibung").value,
    status: getStatus(document.getElementById("status").value),
    positionen: [],
    dokumente: [],
    auftragsNr: "",
    auto: "",
    kunde: "",
  };

  if (window.sessionData.order) {
    newOrder.dokumente = window.data.auftrag[window.sessionData.order].dokumente;
  }

  let positions = document.querySelectorAll("div.invoice-position");

  for (let i = 0; i < positions.length; ++i) {
    if (positions[i].getAttribute("data-art") === "Header") {
      var newPosition: AuftragsPosition = {
        ueberschrift: positions[i].querySelector("#ueberschrift" + (i + 1)).value,
      };
    } else {
      var newPosition: AuftragsPosition = {
        artikelNr: positions[i].querySelector("#artikelNr" + (i + 1)).value,
        bezeichnung: positions[i].querySelector("#bezeichnung" + (i + 1)).value,
        preisEinheit: strToFloat(positions[i].querySelector("#preisEinheit" + (i + 1)).value),
        rabatt: strToFloat(positions[i].querySelector("#rabatt" + (i + 1)).value),
        menge: strToFloat(positions[i].querySelector("#menge" + (i + 1)).value),
      };
    }

    newOrder.positionen.push(newPosition);
  }

  let dataLink = document.getElementById(oeLinkType.car);
  if (dataLink) newOrder.auto = dataLink.getAttribute("data-key");
  else newOrder.auto = "";

  dataLink = document.getElementById(oeLinkType.client);
  if (dataLink) newOrder.kunde = dataLink.getAttribute("data-key");
  else newOrder.kunde = "";

  newOrder.auftragsNr = window.sessionData.order || setOrderNumber();

  return newOrder;
}

function editOrderAmountDif(newPos: AuftragsPositionItem, oldOrder: null | Auftrag) {
  if (!oldOrder) return newPos.menge;

  const oldPos = oldOrder.positionen.find((pos) => {
    if (!("artikelNr" in pos)) return false;
    return pos.artikelNr === newPos.artikelNr;
  }) as null | AuftragsPositionItem;

  if (!oldPos) {
    return newPos.menge;
  } else {
    return newPos.menge - oldPos.menge;
  }
}

export async function saveOrder(): Promise<null | true> {
  const newOrder = getOrderDetailsFromDom();

  if (entryCheck(newOrder)) {
    let orderEditObject: orderNewEvent | orderEditEvent;

    if (!window.sessionData.order) {
      orderEditObject = { type: EditType.orderNew, payload: newOrder };
    } else {
      orderEditObject = { type: EditType.orderEdit, payload: newOrder };
    }

    let amountEdits: articleEditEvent[] = [];
    const overUsed: string[] = [];

    for (const newPos of Object.values(newOrder.positionen)) {
      if ("artikelNr" in newPos) {
        const article = Object.values(window.data.artikel).find((article) => article.nr === newPos.artikelNr);

        if (article && article.menge !== undefined) {
          const diffAmount = editOrderAmountDif(newPos, window.data.auftrag[newOrder.auftragsNr]);

          if (article.menge < diffAmount)
            overUsed.push(`${article.nr} - ${article.bez}: Verfügbar: ${numToStr(article.menge)}`);

          if (diffAmount !== 0) {
            amountEdits.push({
              type: EditType.articleEdit,
              payload: {
                ...article,
                menge: Math.max(article.menge - diffAmount, 0),
              },
            });
          }
        }
      }
    }

    if (overUsed.length > 0) {
      const res = await getConfirmDialog(
        dedent(`
          Bei den folgenden Artikeln wird mehr als die zur Verfügung stehende Menge verwendet.
          Wollen Sie trotzdem speichern?
        `) +
          "\n\n" +
          overUsed.join("\n")
      );
      if (!res) return null;
    }

    window.sessionData.order = newOrder.auftragsNr;
    const result = await saveData([orderEditObject, ...amountEdits]);
    if (result) viewOrder(window.sessionData.order);
    return true;
  } else return null;
}

function setOrderNumber() {
  var tempNr = 0;

  while (window.data.auftrag["Auf" + tempNr] !== undefined) {
    tempNr++;
  }

  return "Auf" + tempNr;
}

function addEditPosition(pos: AuftragsPositionItem) {
  const addSection = document.getElementById("add-position-section");
  var el = getOrderEditPosition(pos);

  addSection.parentNode.insertBefore(el, addSection);
  setPositionNumber();
  if (el.scrollIntoView) el.scrollIntoView();
}

function addEditPosHeader(pos?: AuftragsPositionHeader) {
  const addSection = document.getElementById("add-position-section");
  var el = getOrderEditPosHeader(pos);

  addSection.parentNode.insertBefore(el, addSection);
  setPositionNumber();
  if (el.scrollIntoView) el.scrollIntoView();
}

export function setPositionNumber() {
  var elements = document.querySelectorAll(".invoice-position");

  for (var i = 0; i < elements.length; ++i) {
    elements[i].querySelector("span.section-title").innerText = "Position " + (i + 1) + ":";
    var contentItems = elements[i].querySelectorAll("div.content-item");

    for (var j = 0; j < contentItems.length; ++j) {
      // the input
      contentItems[j].children[0].id = contentItems[j].getAttribute("name") + (i + 1);
      // the label
      if (elements[i].getAttribute("name") === "Item") {
        contentItems[j].children[1].setAttribute("for", contentItems[j].getAttribute("name") + (i + 1));
      }
    }
  }
}

export function calculatePositionSum() {
  let netSum = 0;
  let nodeList = <NodeListOf<EditPositionDiv>>document.querySelectorAll(".invoice-position");
  for (let ii = 0; ii < nodeList.length; ii++) {
    netSum += nodeList[ii].dataNet;
  }

  const isoDate = document.getElementById("datum").value;
  const vatRate = getVatRate(isoDate);

  document.getElementById("data-auftrag-netto").innerText = numToStr(round(netSum, 2)) + " €";
  document.getElementById("data-auftrag-ust").innerText = numToStr(round(netSum * vatRate, 2)) + " €";
  document.getElementById("data-auftrag-brutto").innerText = numToStr(round(netSum * (1 + vatRate), 2)) + " €";
}
