import { getSelectionDialog } from "../dialogs/selectionDialog";
import { showToast } from "../toast";
import { Auto } from "../../../../../globalData/appInterfaces";

export async function listAllAttachments(car: Auto, attachments: string[]) {
  const result = await getSelectionDialog(attachments);

  if (result === 0 || result) {
    const link = await getAttachmentLink(car.autoNr, attachments[result]);
    if (link) window.open(link, "_blank");
  }
}

export async function getAttachmentLink(carID: string, attachment: string) {
  showToast("Link angefordert");
  try {
    const response = await fetch(`/cars/${carID}/dropbox/attachments/${attachment}/link`);

    if (response.status === 200) {
      return await response.text();
    } else {
      showToast("Kein Link erhalten.");
      return false;
    }
  } catch (err) {
    showToast("Fehler: " + err);
    return false;
  }
}

export async function getAttachment(carID: string, attachment: string) {
  showToast("Bild angefordert");
  try {
    const response = await fetch(`/cars/${carID}/dropbox/attachments/${attachment}/file`);

    if (response.status === 200) {
      return await response.text();
    } else {
      showToast("Kein Bild erhalten.");
      return false;
    }
  } catch (err) {
    showToast("Fehler: " + err);
    return false;
  }
}
