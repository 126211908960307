import * as React from "react";
import axios from "axios";

import { Button } from "../formComponents/button";
import { FileInputButton } from "../formComponents/fileInput";
import { Auto } from "../../../../../globalData/appInterfaces";
import { showToast } from "../toast";
import { Spinner } from "../spinner";
import { DropboxPreviewer } from "./dbxPreviewer";
import { DbxEditDialog } from "./dbxEditDialog";

interface DbxPageSectionProps {
  car: Auto;
}

interface DbxPageSectionState {
  uploadProgress: string;
  loading: boolean;
  attachments: string[];
  editOpen: boolean;
  prevCarID: string;
}

export class DropboxPageSection extends React.Component<DbxPageSectionProps, DbxPageSectionState> {
  constructor(props: DbxPageSectionProps) {
    super(props);

    this.state = {
      uploadProgress: "",
      loading: true,
      attachments: [],
      editOpen: false,
      prevCarID: props.car.autoNr,
    };
    this.getAttachments();
  }

  getAttachments = async () => {
    const { car } = this.props;
    const response = await fetch(`/cars/${car.autoNr}/dropbox/attachments`);
    if (response.status === 200) {
      this.setState({ attachments: await response.json(), loading: false });
    } else {
      showToast("Fehler beim laden der Anhänge in Dropbox");
      this.setState({ attachments: [], loading: false });
    }
  };

  addAttachment = async (evt: React.ChangeEvent) => {
    const { car } = this.props;
    const data = new FormData();
    const inputEl = (evt.target as any) as HTMLInputElement;

    for (const file of inputEl.files as FileList) {
      data.append(file.name, file);
    }

    showToast("Gesendet", 4000);

    try {
      this.setState({ loading: true });
      const reactEl = this;

      const response = await axios.request({
        url: `/cars/${car.autoNr}/dropbox/attachments`,
        method: "POST",
        data,
        onUploadProgress: function (progressEvent: ProgressEvent) {
          const upProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          if (reactEl.state.uploadProgress === "100%") return;

          if (upProgress === 100) showToast("Bild an Server gesendet. Dropboxupload ausstehend.");

          reactEl.setState({
            loading: true,
            uploadProgress: upProgress + "%",
          });
        },
      });

      if (response.status === 200) {
        showToast("Datei hochgeladen");
        this.getAttachments();
      } else {
        showToast(`Hochladen fehlgeschlagen`);
      }
    } catch (error) {
      showToast(error.toString());
    }
    inputEl.value = "";
  };

  dbxEditOpenHandler = () => {
    this.setState({ editOpen: true });
  };

  dbxEditCloseHandler = (attachments: string[]) => {
    this.setState({ editOpen: false, attachments });
  };

  componentDidUpdate(prevProps: DbxPageSectionProps) {
    if (prevProps.car.autoNr !== this.props.car.autoNr) {
      this.getAttachments();
      this.setState({ loading: true, prevCarID: this.props.car.autoNr });
    }
  }

  render() {
    const { car } = this.props;
    const { loading, attachments, editOpen, uploadProgress } = this.state;

    return (
      <>
        {loading ? (
          <Spinner margin="0px auto" size={40} progress={uploadProgress} />
        ) : (
          <DropboxPreviewer car={car} attachments={attachments} />
        )}
        <Button className="ripple" onClick={this.dbxEditOpenHandler}>
          Anlagen bearbeiten
        </Button>
        <FileInputButton
          className="ripple"
          title="Anlagen hinzufügen"
          encType="multipart/form-data"
          inputProps={{ multiple: true, onChange: this.addAttachment }}
        />
        {!editOpen ? null : (
          <DbxEditDialog
            attachments={attachments}
            car={car}
            closeHandler={this.dbxEditCloseHandler}
          />
        )}
      </>
    );
  }
}
