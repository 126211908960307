import { phoneQuerySize } from "../../styleConstants";
import { subscribe, publish, pubSubBasePayload, pubSubTopic } from "../../pubSub";
import { navDestination } from "./navConstants";

export interface destObject {
  element: string;
  subElement?: string;
}

export interface pageNavInitPayload extends pubSubBasePayload {
  topic: pubSubTopic.pageNavInitiated;
  destination: navDestination;
  destObject?: destObject;
  back?: boolean;
}

export function setHeaderText(payload: pageNavInitPayload) {
  let strText: string;
  switch (payload.destination) {
    case navDestination.order:
      strText = "Aufträge";
      break;
    case navDestination.client:
      strText = "Kunden";
      break;
    case navDestination.docsOverview:
    case navDestination.singleDocument:
      strText = "Dokumente";
      break;
    case navDestination.articleList:
      strText = "Artikel";
      break;
    case navDestination.settings:
      strText = "Einstellungen";
      break;
    case navDestination.start:
    default:
      strText = "Seite";
      break;
  }

  document.getElementById("mobile-nav-location").textContent = strText.charAt(0).toUpperCase() + strText.slice(1);
}

export function navigateDefault() {
  if (window.location.href.indexOf("#") !== -1) handleHashChange(window.location.href);
  else
    publish(pubSubTopic.pageNavInitiated, {
      topic: pubSubTopic.pageNavInitiated,
      destination: window.sessionData.lastView,
      destObject: null, // Kevin möchte nicht standardmäßig Daten sehen...
    });
}

export function handleHashChange(evt: HashChangeEvent | string) {
  let newURL = typeof evt === "string" ? evt : evt.newURL;
  if (newURL.indexOf("#") === -1) return; // do nothing at start page

  let destinationHash: string = newURL.split("#")[1];
  let navDest: navDestination = destinationHash.split("-")[0].replace("/", "") as navDestination;
  let hashTimeNew = parseInt(destinationHash.split("-")[1]);

  if (!destinationHash.includes("-")) {
    publish(pubSubTopic.pageNavInitiated, {
      topic: pubSubTopic.pageNavInitiated,
      destination: navDest,
      back: true,
      destObject: window.sessionData.getLastDest(),
    });
  } else if (Date.now() - hashTimeNew > 1000) {
    publish(pubSubTopic.pageNavInitiated, {
      topic: pubSubTopic.pageNavInitiated,
      destination: navDest,
      back: true,
      destObject: null,
    });
  }
}

export function mobileNavClick(evt: MouseEvent) {
  let navEl = document.getElementById("navigation");

  if (window.innerHeight < phoneQuerySize || window.innerWidth < phoneQuerySize) {
    if (navEl.classList.contains("collapsed")) {
      let startHeight = navEl.clientHeight + "px";
      navEl.classList.toggle("collapsed");
      navEl.getBoundingClientRect();
      let endHeight = navEl.clientHeight + "px";

      navEl.style.height = startHeight;
      navEl.getBoundingClientRect();
      if (startHeight !== endHeight) navEl.style.height = endHeight;
      else navEl.style.height = null;

      navEl.querySelector("#mobile-nav-icon").innerHTML =
        '<path fill="white" d="M7.4 15.4l4.6-4.6 4.6 4.6L18 14l-6-6-6 6z"/>';
    } else {
      navEl.classList.add("collapsed");
      navEl.style.removeProperty("height");
      navEl.querySelector("#mobile-nav-icon").innerHTML =
        '<path fill="white" d="M7.4 7.8l4.6 4.6 4.6-4.6L18 9.3l-6 6-6-6z"/>';
    }
  }

  if ((evt.target as any).nodeName === "A") {
    publish(pubSubTopic.pageNavInitiated, {
      topic: pubSubTopic.pageNavInitiated,
      destination: evt.target.getAttribute("data-destination") as navDestination,
      // Kevin möchte keine Navigation zur vorherigen Ansicht...
      //destObject: window.sessionData.getLastDest(evt.target.getAttribute("data-destination") as navDestination),
    });
  }
}

export function navigationInit() {
  subscribe(pubSubTopic.pageLoaded, setHeaderText);

  subscribe(pubSubTopic.pageNavInitiated, function setSiteHash(payload: pageNavInitPayload) {
    if (!payload.back) window.location.hash = "#" + payload.destination + "-" + Date.now();
  });

  let navEl = document.getElementById("navigation");
  navEl.addEventListener("click", mobileNavClick);
  let tempA = document.createElement("A");
  (tempA.innerText = "Kunden"), tempA.setAttribute("data-destination", navDestination.client);
  navEl.appendChild(tempA);

  tempA = document.createElement("A");
  (tempA.innerText = "Autos"), tempA.setAttribute("data-destination", navDestination.car);
  navEl.appendChild(tempA);

  tempA = document.createElement("A");
  (tempA.innerText = "Aufträge"), tempA.setAttribute("data-destination", navDestination.order);
  navEl.appendChild(tempA);

  tempA = document.createElement("A");
  (tempA.innerText = "Übersicht"), tempA.setAttribute("data-destination", navDestination.overview);
  navEl.appendChild(tempA);

  tempA = document.createElement("A");
  (tempA.innerText = "Dokumente"), tempA.setAttribute("data-destination", navDestination.docsOverview);
  navEl.appendChild(tempA);

  tempA = document.createElement("A");
  (tempA.innerText = "Artikel"), tempA.setAttribute("data-destination", navDestination.articleList);
  navEl.appendChild(tempA);

  window.addEventListener("hashchange", handleHashChange);
}
