export const saveIcon = {
  viewBox: ' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 438.533 438.533"',
  path:
    '<path d="M432.8 121c-3.8-9-8.4-16.3-13.7-21.6l-79.8-80c-5.4-5.3-12.6-10-21.7-13.' +
    "7C308.3 2 300 0 292.5 0h-265C19.7 0 13.2 2.7 8 8c-5.3 5.3-8 11.8-8 19.4V411c0 7.7 2.7 14" +
    ".2 8 19.5 5.3 5.4 11.8 8 19.4 8H411c7.7 0 14.2-2.6 19.5-8 5.4-5.3 8-11.8 8-19.4V146.3c0-7" +
    ".6-2-16-5.7-25.2zm-250-75.3c0-2.5.8-4.6 2.6-6.4 1.8-2 4-2.8 6.5-2.8h54.7c2.4 0 4.6 1 6.4 2" +
    ".8 2 1.8 2.8 4 2.8 6.4V137c0 2.5-1 4.7-2.7 6.5-1.7 1.8-4 2.7-6.3 2.7H192c-2.6 0-4.8-1-6.6-" +
    "2.7-1.8-1.8-2.7-4-2.7-6.5V45.7zm146 356.3H109.7V292.4H329V402zm73.2 0h-36.6V283.2c0-7.6-2." +
    "6-14-8-19.4-5.3-5.3-11.7-8-19.4-8H100.5c-7.6 0-14 2.7-19.4 8-5.2 5.3-8 11.8-8 19.4V402H36.6V3" +
    "6.5H73v118.8c0 7.6 2.7 14 8 19.4 5.4 5.4 12 8 19.5 8H265c7.6 0 14-2.6 19.4-8 5.3-5.3 8-11.8 8" +
    '-19.4V36.5c2.8 0 6.5 1 11 3 4.7 1.8 8 3.7 9.8 5.6l80.2 80.3c2 2 3.8 5.2 5.7 10 2 4.5 3 8.2 3 11V402z"/>'
};

export const deleteIcon = {
  viewBox: ' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"',
  path:
    '<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.' +
    '41 17.59 19 19 17.59 13.41 12z"/>'
};

export const searchIcon = {
  viewBox: ' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"',
  path:
    '<path d="M15.5 14h-.8l-.3-.3c1-1 1.6-2.6 1.6-4.2C16 6 13 3 9.5 3S3 6 3 9.5 6 16 9.' +
    '5 16c1.6 0 3-.6 4.2-1.6l.3.3v.8l5 5 1.5-1.5-5-5zm-6 0C7 14 5 12 5 9.5S7 5 9.5 5 14 7 14 9.5 12 14 9.5 14z"/>'
};

export const linkIcon = {
  viewBox: ' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"',
  path:
    '<path d="M19 19H5V5h7V3H5C4 3 3 4 3 5v14c0 1 1 2 2 2h14c1 0 2-1 2-2v-7h-2v7zM14 3v2h3.6l-9.8 9.8 1.4 1' +
    '.4L19 6.4V10h2V3h-7z"></path>'
};

export const downIcon = {
  viewBox: ' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"',
  path: '<path d="M7.4 7.8l4.6 4.6 4.6-4.6L18 9.3l-6 6-6-6z"></path>'
};

export const upIcon = {
  viewBox: ' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"',
  path: '<path d="M7.4 15.4l4.6-4.6 4.6 4.6L18 14l-6-6-6 6z"></path>'
};

export const carIcon = {
  viewBox: ' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"',
  path:
    '<path d="M19 6c-.3-.6-.8-1-1.5-1h-11c-.7 0-1.2.4-1.4 1l-2 6' +
    "v8c0 .6.5 1 1 1h1c.6 0 1-.5 1-1v-1h12v1c0 .6.5 1 1 1h1c.6 0 1-.5 1" +
    "-1v-8l-2-6zM6.4 16c-.8 0-1.5-.7-1.5-1.5S5.7 13 6.5 13s1.5.7 1.5 1" +
    ".5S7.3 16 6.5 16zm11 0c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1" +
    '.5 1.5-.7 1.5-1.5 1.5zM5 11l1.5-4.5h11L19 11H5z"></path>'
};

export const carRepairIcon = {
  viewBox: ' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.4 41.4"',
  path:
    '<path d="M41 16.6c-.4-2.3-6.5 0-6.5 0l-.3 3.2s-.3 0-.4-.2L32.' +
    "2 13c-.5-3.3-3-5.7-6.5-5.5h-9c0 .3 0 .7-.2 1h10c2 0 3.8 1.4 4.3 3." +
    "6l1.7 6.7H13V25h12v1.6H13v.4h11.7v1.5H13V34h12.7v3.3c0 1.4 1.7 2.6 3" +
    ".8 2.6s4-1.3 4-2.7v-3.8c1.7-.7 3-2.2 3-4v-6c0-1.5-.8-2.7-2-3.5l6.4-." +
    '3s.2-.8 0-3zm-7.4 11.7h-7V25h7v3.3zM12.8 27h.2v1.4h-.2zM13 25v1.5z"></path>' +
    '<path d="M15.3 6.5c0-2.8-1.5-5.2-3.7-6.5v6L8 8 4 6V0C2 1.3.5 3.7.5 6.5' +
    "c0 3 1.6 5.4 4 6.7v24.6c0 1 .5 2 1 2.5.7.7 1.6 1 2.6 1 2 0 3.5-1.5 3" +
    ".5-3.5V13c2.3-1.3 3.8-3.7 3.8-6.5zm-6.5 33c-.5.4-1.4.4-2 0-.5-.6-.5-" +
    '1.5 0-2 .6-.5 1.5-.5 2 0s.5 1.4 0 2z"></path>'
};
