import { fetchPage, saveData, checkLatestDataUX } from "../globalFunctions";
import { getEditLink } from "../htmlElements/variousElements";
import { showToast } from "../components/toast";
import { getSelectionDialog } from "../components/dialogs/selectionDialog";

import { editURL, kundenAnzeige, setButtonFunctionality } from "./clientFunctionality";
import { Kunde } from "../../../../globalData/appInterfaces";
import {
  clientEditEvent,
  clientNewEvent,
  EditType,
} from "../../../../globalData/dataEditInterfaces";
import { navDestination } from "../navigation/navConstants";
import { getKeySearchString } from "../../../../globalData/helperFunctions";

var properties = [
  "vorname",
  "nachname",
  "strasse",
  "plz",
  "stadt",
  "festnetz",
  "mobil",
  "email",
  "geburtstag",
  "kommentar",
  "firma",
];

export async function clientEdit(kundenID?: string) {
  await fetchPage(editURL, "eingabe", navDestination.client);
  setButtonFunctionality();
  if (typeof kundenID === "string") {
    var el;
    properties.forEach(function (prop) {
      if ((<any>window.data.kunde[window.sessionData.client])[prop] !== "") {
        el = document.getElementById(prop);
        el.value = (<any>window.data.kunde[window.sessionData.client])[prop];
      }
    });

    var linkTarget = document.getElementById("assign-button");
    window.data.kunde[window.sessionData.client].autos.forEach(function (kundenAuto) {
      linkTarget.parentNode.insertBefore(clientEditLink(getEditLink(), kundenAuto), linkTarget);
    });
  }

  window.sessionData.setLastView(navDestination.client, kundenID);

  checkLatestDataUX();
}

function clientEditLink(el: HTMLElement, kundenAutos: string) {
  el.id = kundenAutos;
  el.innerHTML +=
    window.data.auto[kundenAutos].kennzeichen + " - " + window.data.auto[kundenAutos].hersteller;
  el.children[0].onclick = function () {
    this.parentNode.parentNode.removeChild(this.parentNode);
  };

  return el;
}

export function clientAddCar() {
  var selectionList: string[] = [];
  var searchList: string[] = [];
  var keyArr: string[] = [];

  Object.values(window.data.auto)
    .sort(function (a, b) {
      return a.kennzeichen < b.kennzeichen ? -1 : 1;
    })
    .forEach(function (item) {
      selectionList.push(item.kennzeichen + " - " + item.hersteller);
      searchList.push(getKeySearchString(item));
      keyArr.push(item.autoNr);
    });

  getSelectionDialog(selectionList, { searchList, keyArr }).then(function (el) {
    if (typeof el === "string") {
      if (document.getElementById(el)) {
        showToast("Schon vorhanden");
      } else {
        var linkTarget = document.getElementById("assign-button");
        linkTarget.parentNode.insertBefore(clientEditLink(getEditLink(), el), linkTarget);
      }
    }
  });
}

export function entryCheck(entry: Kunde) {
  if (!entry.mobil && !entry.email && !entry.festnetz) {
    showToast("Kontaktdaten fehlen.");
    return false;
  }

  if (!entry.firma && (!entry.nachname || !entry.vorname)) {
    showToast("Ansprechpartner fehlt: Firma oder Vor- und Nachname ist erforderlich.");
    return false;
  }

  return true;
}

export function saveClient() {
  var newClient: Kunde = {
    vorname: document.getElementById("vorname").value.trim(),
    nachname: document.getElementById("nachname").value.trim(),
    firma: document.getElementById("firma").value.trim(),
    strasse: document.getElementById("strasse").value.trim(),
    plz: document.getElementById("plz").value.trim(),
    stadt: document.getElementById("stadt").value.trim(),
    festnetz: document.getElementById("festnetz").value.trim(),
    mobil: document.getElementById("mobil").value.trim(),
    email: document.getElementById("email").value.trim(),
    geburtstag: document.getElementById("geburtstag").value,
    kommentar: document.getElementById("kommentar").value,
    autos: [],
    auftraege: [],
    kundenNr: window.sessionData.client,
  };

  if (!window.sessionData.client) {
    var tempNr = 0;

    while (window.data.kunde["K" + ("000" + tempNr).substr(-3)] !== undefined) {
      tempNr++;
    }
    newClient.kundenNr = "K" + ("000" + tempNr).substr(-3);
  }

  var tempArr = document.querySelectorAll(".data-link");
  for (var i = 0; i < tempArr.length; i++) {
    newClient.autos.push(tempArr[i].id);
  }

  if (window.sessionData.client)
    newClient.auftraege = window.data.kunde[window.sessionData.client].auftraege;

  tempNr = null;
  tempArr = null;

  if (entryCheck(newClient)) {
    let editObject: clientEditEvent | clientNewEvent;
    if (!window.sessionData.client) {
      editObject = { type: EditType.clientNew, payload: newClient };
      window.sessionData.client = newClient.kundenNr;
    } else editObject = { type: EditType.clientEdit, payload: newClient };

    saveData([editObject]).then(function (result) {
      if (result) kundenAnzeige(window.sessionData.client);
    });
  }
}
