import * as React from "react";

interface props {
  className?: string;
  style?: React.CSSProperties;
  onClick?: (evt: React.MouseEvent<SVGSVGElement>) => void;
}

export const SearchIcon = function SearchIcon({ className, style, onClick }: props) {
  const iconPath =
    "M15.5 14h-.8l-.3-.3c1-1 1.6-2.6 1.6-4.2C16 6 13 3 9.5 3S3 6 3 9.5 6 16 9." +
    "5 16c1.6 0 3-.6 4.2-1.6l.3.3v.8l5 5 1.5-1.5-5-5zm-6 0C7 14 5 12 5 9.5S7 5 9.5 5 14 7 14 9.5 12 14 9.5 14z";
  return (
    <svg
      onClick={onClick}
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d={iconPath} />
    </svg>
  );
};

export const DeleteIcon = function DeleteIcon({ className, style, onClick }: props) {
  const iconPath =
    "M19 6.4L17.6 5 12 10.6 6.4 5 5 6.4l5.6 5.6L5 17.6 6.4 19l5.6-5.6 5.6 5.6 1.4-1.4-5.6-5.6z";
  return (
    <svg
      onClick={onClick}
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d={iconPath} />
    </svg>
  );
};

export const UpIcon = function UpIcon({ className, style, onClick }: props) {
  const iconPath = "M7.4 15.4l4.6-4.6 4.6 4.6L18 14l-6-6-6 6z";
  return (
    <svg
      onClick={onClick}
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d={iconPath} />
    </svg>
  );
};

export const DownIcon = function DownIcon({ className, style, onClick }: props) {
  const iconPath = "M7.4 7.8l4.6 4.6 4.6-4.6L18 9.3l-6 6-6-6z";
  return (
    <svg
      onClick={onClick}
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d={iconPath} />
    </svg>
  );
};

export const RightIcon = function RightIcon({ className, style, onClick }: props) {
  const iconPath = "M10 6L8.6 7.4l4.6 4.6-4.6 4.6L10 18l6-6z";
  return (
    <svg
      onClick={onClick}
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d={iconPath} />
    </svg>
  );
};

export const LeftIcon = function LeftIcon({ className, style, onClick }: props) {
  const iconPath = "M15.4 7.4L14 6l-6 6 6 6 1.4-1.4-4.6-4.6z";
  return (
    <svg
      onClick={onClick}
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d={iconPath} />
    </svg>
  );
};

export const LinkIcon = function LinkIcon({ className, style, onClick }: props) {
  const iconPath =
    "M19 19H5V5h7V3H5C4 3 3 4 3 5v14c0 1 1 2 2 2h14c1 0 2-1 2-2v-7h-2v7zM14 3v2h3.6l-9.8 9.8 1.4 1.4L19 6.4V10h2V3h-7z";
  return (
    <svg
      onClick={onClick}
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d={iconPath} />
    </svg>
  );
};
