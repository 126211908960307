import { subscribe, publish, pubSubTopic } from "../../pubSub";

import { saveData, fetchPage } from "../globalFunctions";
import { showToast } from "../components/toast";
import { getSelectionDialog } from "../components/dialogs/selectionDialog";
import { getConfirmDialog } from "../components/dialogs/confirmDialog";

import { EditType } from "../../../../globalData/dataEditInterfaces";
import { pageNavInitPayload } from "../navigation/navigation";
import { navDestination } from "../navigation/navConstants";
import { prettyByteSize, validEMail } from "../../../../globalData/helperFunctions";
import { AppSettings } from "../../../../globalData/appInterfaces";

export var settingsURL = "pages/settings/settings.html";

export function settingsAnzeige() {
  return fetchPage(settingsURL, "settings", navDestination.settings).then(function () {
    setButtonFunctionality();

    try {
      return fillSettings();
    } catch (err) {
      console.warn("Error filling fields - refetching settingsData");
      console.warn(err);
      return fetchSettingsData().then(fillSettings);
    }
  });
}

function setButtonFunctionality() {
  let elmts = document.querySelectorAll("button.saveBackup");
  for (var i = elmts.length - 1; i >= 0; i--) {
    elmts[i].addEventListener("click", saveBackup);
  }
  elmts = document.querySelectorAll("button.importBackup");
  for (var i = elmts.length - 1; i >= 0; i--) {
    elmts[i].addEventListener("click", importBackup);
  }
  elmts = document.querySelectorAll("button.saveSettings");
  for (var i = elmts.length - 1; i >= 0; i--) {
    elmts[i].addEventListener("click", saveSettings);
  }
}

function fetchSettingsData() {
  return fetch("/settingsData", { credentials: "same-origin" })
    .then(function (res) {
      return res.json();
    })
    .then(function (settingsRes) {
      window.settingsData = settingsRes;
    })
    .catch(function (err) {
      console.warn("Serverfehler: " + err);
      showToast("Serverfehler ");
    });
}

function fillSettings() {
  document.getElementById("email").value = window.settingsData.settings.notification.email.toString();
  document.getElementById("taeglich").value = window.settingsData.settings.backup.taeglich.toString();
  document.getElementById("monatlich").value = window.settingsData.settings.backup.monatlich.toString();

  var tempDate = new Date(window.data.lastEdit);
  document.getElementById("last-edit").innerText =
    tempDate.toLocaleDateString() + " - " + tempDate.toLocaleTimeString();
  document.getElementById("data-storage").innerText =
    prettyByteSize(window.settingsData.dbStats.storageSize) + " von 500 MB";
}

export async function saveBackup() {
  window.open(`/appdata/backup`, "_blank");
}

function importBackup() {
  var inputDiv = document.createElement("DIV");
  inputDiv.innerHTML = '<input type="file" id="import" style="display:none" ' + 'accept=".json">Backup importieren';
  inputDiv.children[0].addEventListener("change", importData);
  inputDiv.children[0].click();
  inputDiv = null;
}

function importData(evt: Event) {
  var file = this.files;
  var reader = new FileReader();
  reader.readAsText(file[0]);

  reader.onload = function () {
    saveData([{ type: EditType.import, payload: JSON.parse(reader.result as string) }]).then(function (result) {
      if (result)
        setTimeout(function () {
          window.location.reload();
        }, 500);
    });
  };
}

export function saveSettings() {
  var tempSettings: AppSettings = {
    _id: 0,
    notification: {
      email: document.getElementById("email").value,
    },
    backup: {
      taeglich: Number(document.getElementById("taeglich").value),
      monatlich: Number(document.getElementById("monatlich").value),
    },
  };

  if (entryCheck(tempSettings)) saveSettingsToServer(tempSettings).then(fillSettings);
}

function entryCheck(entry: AppSettings) {
  if (!validEMail(entry.notification.email)) {
    showToast("Ungültige E-Mail");
    return false;
  } else if (!Number.isInteger(entry.backup.monatlich)) {
    showToast("Anzahl von Monatsbackups fehlt oder ist keine Zahl.");
    return false;
  } else if (!Number.isInteger(entry.backup.taeglich)) {
    showToast("Anzahl von Tagesbackups fehlt oder ist keine Zahl.");
    return false;
  }

  return true;
}

function saveSettingsToServer(payload: AppSettings) {
  return fetch("/saveSettings", {
    method: "POST",
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then(function (res) {
      if (res.status !== 200)
        res.text().then(function (textRes) {
          console.warn(textRes);
        });
      else {
        window.settingsData.settings = payload;
        showToast("Gespeichert");
        return true;
      }
    })
    .catch(function (err) {
      showToast("Fehler beim Speichern");
      console.warn("Error saving data to server", err);
    });
}

export const settingsNavHandling = function settingsNavHandling(payload: pageNavInitPayload) {
  if (payload.destination === navDestination.settings) {
    window.sessionData.setLastView(navDestination.settings);
    return settingsAnzeige();
  }
};

export function settingsPageInit() {
  subscribe(pubSubTopic.pageNavInitiated, settingsNavHandling);
}
