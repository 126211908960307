import * as React from "react";
import * as ReactDOM from "react-dom";
import { SearchBar } from "./searchBar";
import { FilterRules } from "./filterRules";
import { SearchResults } from "./searchResults";

import { filterInit, FilterValue } from "./filterFunctions";
import { pubSubTopic, pubSubBasePayload, subscribe, publish } from "../../pubSub";
import { fetchPage } from "../globalFunctions";
import { navDestination } from "../navigation/navConstants";
import { FilterRulesEnum, FilterbasisEnum } from "./constants";
import { pageNavInitPayload } from "../navigation/navigation";

export const overviewURL = "pages/overview/overview.html";

export interface overviewFilterPayload extends pubSubBasePayload {
  topic: pubSubTopic.overviewFilter;
  specifySearch: boolean;
  deleteFilter?: FilterRulesEnum;
  editFilter?: { key: FilterRulesEnum; value: FilterValue };
  filterList?: { [key: string]: FilterValue };
}

interface props {}

interface state {
  filterList: { [key: string]: FilterValue };
  specifySearch: boolean;
}

export const deleteFilterRule = function deleteFilterRule(
  deleteFilter: FilterRulesEnum,
  specifySearch: boolean = false
) {
  publish(pubSubTopic.overviewFilter, {
    topic: pubSubTopic.overviewFilter,
    specifySearch,
    deleteFilter
  });
};

export const setFilterList = function setFilterList(
  filterList: { key: FilterRulesEnum; value: string } | {},
  specifySearch: boolean = false
) {
  publish(pubSubTopic.overviewFilter, {
    topic: pubSubTopic.overviewFilter,
    specifySearch,
    filterList
  });
};

export const setFilterRule = function setFilterRule(
  key: FilterRulesEnum,
  value: FilterValue,
  specifySearch: boolean = false
) {
  publish(pubSubTopic.overviewFilter, {
    topic: pubSubTopic.overviewFilter,
    specifySearch,
    editFilter: { key, value }
  });
};

export class OverviewComponent extends React.Component<props, state> {
  private static subscriberArr: ReturnType<typeof subscribe>[] = [];

  constructor(props: props) {
    super(props);
    let filterList: { [key: string]: FilterValue } = {};
    filterList[FilterRulesEnum.filterbasis] = FilterbasisEnum.orders;

    this.state = {
      specifySearch: false,
      filterList
    };

    filterInit();
  }

  editFilter = (overviewFilterPayload: overviewFilterPayload) => {
    let tmpList: { [key: string]: FilterValue };
    if (overviewFilterPayload.filterList) {
      tmpList = overviewFilterPayload.filterList;
    } else if (overviewFilterPayload.deleteFilter) {
      tmpList = { ...this.state.filterList };
      delete tmpList[overviewFilterPayload.deleteFilter];
    } else if (overviewFilterPayload.editFilter) {
      tmpList = { ...this.state.filterList };
      tmpList[overviewFilterPayload.editFilter.key] = overviewFilterPayload.editFilter.value;
    }

    this.setState({ specifySearch: overviewFilterPayload.specifySearch, filterList: tmpList });
  };

  componentDidMount() {
    OverviewComponent.subscriberArr.push(subscribe(pubSubTopic.overviewFilter, this.editFilter));
  }

  componentWillUnmount() {
    OverviewComponent.subscriberArr.forEach(item => {
      item.remove();
    });
  }

  render() {
    return (
      <>
        <SearchBar
          filterList={this.state.filterList}
          value={(this.state.filterList.search as string) || ""}
        />
        <FilterRules filterList={this.state.filterList} />
        <SearchResults
          filterList={this.state.filterList}
          specifySearch={this.state.specifySearch}
        />
      </>
    );
  }
}

export const overview = function overview() {
  return fetchPage(overviewURL, "uebersicht", navDestination.overview).then(function() {
    window.sessionData.setLastView(navDestination.overview);

    ReactDOM.render(<OverviewComponent />, document.getElementById("overviewRoot"));
    const reactUnmountListener = subscribe(
      pubSubTopic.pageNavInitiated,
      function unMountOverview() {
        ReactDOM.unmountComponentAtNode(document.getElementById("overviewRoot"));
        reactUnmountListener.remove();
      }
    );
  });
};

export const overviewNavigation = async function overviewNavigation(payload: pageNavInitPayload) {
  if (payload.destination === navDestination.overview) {
    return overview();
  }
};

export const overviewInit = function overviewInit() {
  subscribe(pubSubTopic.pageNavInitiated, overviewNavigation);
};
