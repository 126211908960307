export enum navDestination {
  start = "",
  order = "ORDER",
  overview = "OVERVIEW",
  client = "CLIENT",
  car = "CAR",
  docsOverview = "DOCUMENT_OVERVIEW",
  singleDocument = "DOCUMENT",
  articleList = "ARTICLE",
  settings = "SETTINGS",
}
