import styled from "styled-components";
import * as React from "react";
import { shadow0, phoneMediaQuery } from "../../../styleConstants";

 const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(0,0,0,.4);

  .dialog-container {
    min-height: 10rem;
    max-width: 80vw;
    max-height: 80vh;
    min-width: 40vh;
    display: flex;
    flex-direction: column;
    padding: 12px;
    background-color: #fff;
    box-shadow: ${shadow0};

    @media ${phoneMediaQuery} {
      width: 80vw;
    }
  }
`;

interface DialogProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

export function DialogContainer({children, ...otherProps}:DialogProps) {
  return <StyledContainer {...otherProps}>
    <div className="dialog-container">
      {children}
    </div>
  </StyledContainer>
}

export const DialogContent = styled.div`
  flex-grow: 1;
  font-size: 1.25rem;
  line-height: 200%;
  text-align: center;
  overflow-y: auto;
  margin-bottom: 35px;
  white-space: pre-line;
`;

export const DialogActions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: space-between;
`;