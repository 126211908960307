import * as React from "react";
import * as ReactDOM from "react-dom";

import { getCarSelectionDialog } from "./carHelpers";
import { pageNavInitPayload } from "../navigation/navigation";
import { Auto } from "../../../../globalData/appInterfaces";
import { EditType } from "../../../../globalData/dataEditInterfaces";

import { subscribe, publish, pubSubTopic } from "../../pubSub";

import { fetchPage, saveData } from "../globalFunctions";

import { getViewLink } from "../htmlElements/variousElements";
import { showToast } from "../components/toast";
import { getConfirmDialog } from "../components/dialogs/confirmDialog";

import { carEdit, saveCar, addClient } from "./editFunctionality";
import { navDestination } from "../navigation/navConstants";
import { strToDate } from "../../../../globalData/helperFunctions";
import { getSelectionDialog } from "../components/dialogs/selectionDialog";
import { DropboxPageSection } from "../components/dropboxPreviewer/dbxPageSection";
import { getClientAsString } from "../clientPage/clientHelpers";

export var editURL = "pages/auto/eingabe.html";
export var viewURL = "pages/auto/anzeige.html";

export function carPageInit() {
  subscribe(pubSubTopic.pageNavInitiated, carNavHandling);
}

export function carNavHandling(payload: pageNavInitPayload) {
  if (payload.destination === navDestination.car) {
    if (!payload || !payload.destObject || !window.data.auto[payload.destObject.element]) {
      carEdit();
    } else autoAnzeige(payload.destObject.element);
  }
}

export function autoAuswahl() {
  getCarSelectionDialog().then((result) => {
    if (result) autoAnzeige(result.autoNr);
  });
}

export function autoAnzeige(carID: string) {
  fetchPage(viewURL, "anzeige", navDestination.car).then(function () {
    window.sessionData.setLastView(navDestination.car, carID);

    autoDatenEintragen(window.data.auto[carID]);
    setButtonFunctionality();
  });
}

export function setButtonFunctionality() {
  let elmts = document.querySelectorAll("button.autoEingabe");
  for (var i = elmts.length - 1; i >= 0; i--) {
    elmts[i].addEventListener("click", function () {
      carEdit();
    });
  }
  elmts = document.querySelectorAll("button.autoAuswahl");
  for (var i = elmts.length - 1; i >= 0; i--) {
    elmts[i].addEventListener("click", autoAuswahl);
  }
  elmts = document.querySelectorAll("button.carEdit");
  for (var i = elmts.length - 1; i >= 0; i--) {
    // onlick is used for the function to be accessable in tests
    elmts[i].onclick = carEdit.bind(null, window.sessionData.car);
  }
  elmts = document.querySelectorAll("button.deleteCar");
  for (var i = elmts.length - 1; i >= 0; i--) {
    elmts[i].addEventListener("click", deleteCar);
  }
  elmts = document.querySelectorAll("button.showServices");
  for (var i = elmts.length - 1; i >= 0; i--) {
    elmts[i].addEventListener("click", showServices);
  }
  elmts = document.querySelectorAll("button.saveCar");
  for (var i = elmts.length - 1; i >= 0; i--) {
    elmts[i].addEventListener("click", saveCar);
  }
  elmts = document.querySelectorAll("button.addClient");
  for (var i = elmts.length - 1; i >= 0; i--) {
    elmts[i].addEventListener("click", addClient);
  }
}

function autoDatenEintragen(auto: Auto) {
  [
    "kennzeichen",
    "hubraum",
    "leistung",
    "vin",
    "zu2",
    "zu3",
    "kraftstoff",
    "farbe",
    "kommentar",
    "oelwechselKM",
    "zahnriemenKM",
    "reifen",
  ].forEach(function (id) {
    document.getElementById("data-auto-" + id).innerHTML = (auto as any)[id];
  });

  document.getElementById("data-auto-autonr").innerHTML = auto.autoNr;

  if (auto.erstzulassung) {
    document.getElementById("data-auto-erstzulassung").innerHTML = strToDate(auto.erstzulassung);
  }
  if (auto.oelwechselDatum) {
    document.getElementById("data-auto-oelwechselDatum").innerHTML = strToDate(auto.oelwechselDatum);
  }
  if (auto.zahnriemenDatum) {
    document.getElementById("data-auto-zahnriemenDatum").innerHTML = strToDate(auto.zahnriemenDatum);
  }
  if (auto.tuev) {
    document.getElementById("data-auto-tuev").innerHTML = strToDate(auto.tuev);
  }
  if (auto.hersteller || auto.modell) {
    document.getElementById("data-auto-modell").innerHTML = auto.hersteller + " " + auto.modell;
  }

  var linkTarget = document.getElementById("data-auto-kunden");
  auto.kunden.forEach(function (carOwner) {
    var el = getViewLink();
    el.innerHTML = el.innerHTML + getClientAsString(window.data.kunde[carOwner]);
    el.setAttribute("data-element", carOwner);
    el.onclick = function autoKundenLinkClick(evt: MouseEvent) {
      publish(pubSubTopic.pageNavInitiated, {
        topic: pubSubTopic.pageNavInitiated,
        destination: navDestination.client,
        destObject: { element: evt.target.getAttribute("data-element") },
      });
    };
    linkTarget.appendChild(el);
  });

  emptyCheck(auto);

  ReactDOM.render(<DropboxPageSection car={auto} />, document.querySelector("#attachment-content"));
}

function emptyCheck(auto: Auto) {
  [
    "erstzulassung",
    "hubraum",
    "leistung",
    "vin",
    "zu2",
    "zu3",
    "kraftstoff",
    "farbe",
    "oelwechselKM",
    "oelwechselDatum",
    "zahnriemenKM",
    "zahnriemenDatum",
    "reifen",
  ].forEach(function (id) {
    if (!(auto as any)[id]) document.getElementById("data-auto-" + id).parentNode.style.display = "none";
  });

  if (!auto.oelwechselKM && !auto.oelwechselDatum) {
    document.getElementById("oelwechselSection").style.display = "none";
  }

  if (!auto.zahnriemenKM && !auto.zahnriemenDatum) {
    document.getElementById("zahnriemenSection").style.display = "none";
  }

  if (!auto.tuev) document.getElementById("tuevSection").style.display = "none";

  if (!auto.kommentar) document.getElementById("kommentarSection").style.display = "none";
}

export function deleteCar() {
  getConfirmDialog("Soll dieses Auto wirklich permanent gelöscht werden?").then(function (res) {
    if (res) {
      var tempCar = window.data.auto[window.sessionData.car];
      if (
        window.data.auto[window.sessionData.car].auftraege &&
        window.data.auto[window.sessionData.car].auftraege.length > 0
      ) {
        showToast("Fehler: Service ist zugeordnet - Zuerst Service, dann Auto löschen");
      } else {
        saveData([{ type: EditType.carDelete, payload: window.sessionData.car }]).then(function (result) {
          if (result) carEdit();
        });
      }
    }
  });
}

function showServices() {
  let selectionList = window.data.auto[window.sessionData.car].auftraege.map(function (item) {
    return window.data.auftrag[item].datum + " - " + window.data.auftrag[item].titel;
  });
  let keyArr = window.data.auto[window.sessionData.car].auftraege.map(function (item) {
    return window.data.auftrag[item].auftragsNr;
  });

  getSelectionDialog(selectionList, { keyArr }).then(function (id) {
    if (typeof id === "string") {
      publish(pubSubTopic.pageNavInitiated, {
        topic: pubSubTopic.pageNavInitiated,
        destination: navDestination.order,
        destObject: {
          element: id,
        },
      });
    }
  });
}
