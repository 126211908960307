import { pageStart } from "./pageStart";
import * as Sentry from "@sentry/browser";

import "./clientInterface";

let asyncPolyFlag: number;

if (process.env.USE_SENTRY) {
  Sentry.init({ dsn: process.env.SENTRY_DSN });
  console.log("Instantiated Sentry");
}

function loadPolyfill(src: string, callback: Function) {
  try {
    const js = document.createElement("script");
    js.src = src;
    js.onload = function () {
      asyncPolyFlag--;
      if (asyncPolyFlag === 0) callback();
    };
    js.onerror = function () {
      const error = new Error("Failed to load script " + src);
      console.warn(error);
      Sentry.captureException(error);

      asyncPolyFlag--;
      if (asyncPolyFlag === 0) callback();
    };
    document.head.appendChild(js);
  } catch (err) {
    console.warn("Error: Loading polyfills.");
    console.warn(err);
    Sentry.captureException(err);
  }
}

// this is for Safari 10.1 where the nomodule tag is not supported
if (window.sfAppStarted !== true) {
  window.sfAppStarted = true;

  const src = "/js/polyfills/";

  asyncPolyFlag = 0;
  if (!window.fetch) {
    asyncPolyFlag++;
    loadPolyfill(src + "fetch.js", pageStart);
  } else if (!Object.entries) {
    Object.entries = function <O extends Object, K extends keyof O>(obj: O) {
      let ownProps = Object.keys(obj);
      let i = ownProps.length;
      let resArray: Array<[string, O[K]]> = <any>new Array(i); // preallocate the Array
      while (i--) resArray[i] = [ownProps[i], obj[<K>ownProps[i]]];

      return resArray;
    };
  }

  if (asyncPolyFlag === 0) pageStart();
}
