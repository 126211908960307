import { AppSettings } from "./appInterfaces";

export enum NodeEnv {
  production = "production",
  dev = "development",
  test = "testing",
}

export interface SendMailOptions {
  toEmail: string;
  subject: string;
  message: string;
}

export interface DBsettings {
  _id?: any;
  settings: AppSettings;
  dbStats: { storageSize: number };
  dailyBackups: Array<{ _id: number }>;
  monthlyBackups: Array<{ _id: number }>;
}

export interface userData {
  userArr: Array<dbUser>;
  timestamp: number;
}

export interface userData {
  userArr: Array<dbUser>;
  timestamp: number;
}

export interface PassLoginRequest {
  email: string;
  pass: string;
}

export interface HashLoginRequest {
  email: string;
  hash: string;
}
export function isPassLogin(
  argLogin: HashLoginRequest | PassLoginRequest
): argLogin is PassLoginRequest {
  return (argLogin as any).pass;
}

export interface dbUser {
  email: string;
  hash: string;
}
