import * as React from 'react';
import styled from 'styled-components'

import { Label } from './label';

export const StyledTextfield = styled.div`
  box-sizing: border-box;
  font-size: 16px;
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 20px 0;
  
  label {
    opacity: 0;
  }

  input {
    font-size: 16px;
    padding: 4px 0;
    min-width: 100%;
    border: none;
    border-bottom: 2px solid rgba(0,0,0,.12);
    outline: none;
    font-family: inherit;

    &:focus {
      border-bottom-color: rgb(96,125,139);
      border-bottom-width: 2px;
    }

    &:focus + label, &:valid + label {
      opacity: 1
    }
  }
`;

interface TextfieldProps extends React.HTMLAttributes<HTMLDivElement> {
  label?:string,
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
}

export function Textfield(props:TextfieldProps = {}) {
  let typeProp = "text";
  const { label, inputProps, ...containerProps } = props;
  let otherInputprops:React.InputHTMLAttributes<HTMLInputElement> = {};

  if (inputProps) {
    const {type, ...rest} = inputProps;
    typeProp = type || typeProp;
    otherInputprops = rest;
  }

  return <StyledTextfield {...containerProps}>
    <input required type={typeProp} {...otherInputprops} />
    {label?<Label>{label}</Label>:null}
  </StyledTextfield>;
}