import * as React from 'react';
import styled from 'styled-components';
import { white3 } from '../../../styleConstants';
import { getUniqueArray } from '../../../../../globalData/helperFunctions';
import { StyledTextfield } from './textfield';
import { Label } from './label';

interface DatalistOptionProps {
  selected:boolean,
  option:string,
  select:(event:React.MouseEvent<HTMLDivElement>)=>void,
  index:number,
}

const DatalistOption = function DatalistOption(props:DatalistOptionProps) {
    return <div className={'react-datalist-option'+(props.selected?' react-datalist-option-selected':"")}
            onClick={props.select}>{props.option}</div>;
}

interface DatalistProps {
  options:string[],
  selected:number,
  select:(evt:React.MouseEvent<HTMLDivElement>) => void,
  hide:boolean,
  id:string,
}

const DatalistStyle = styled.div`
  position: absolute;
  min-width: 100%;
  background-color: white;
  box-sizing: border-box;
  border: 1px solid black;
  z-index: 2;
  /* transform to make up for the margin of the sf-mdl-textfield */
  transform: translateY(-20px); 

  & .react-datalist-option {
    margin-bottom: 5px;

    &:hover, &.react-datalist-option-selected {
      background-color: ${white3}
    }
  }
`;

const Datalist = function Datalist(props:DatalistProps) {
  let options = props.options.map((option, index) => {
    return <DatalistOption 
              key={option+index}
              option={option} 
              index={index} 
              selected={props.selected === index} 
              select={props.select} />
  })
  let elStyle:React.CSSProperties = {};

  if (props.hide) elStyle.display = 'none';
  else if (props.options.length == 0) elStyle.display = 'none';
  else elStyle.display = 'block';
  
  return <DatalistStyle id={props.id} className="react-datalist" style={elStyle}>{options}</DatalistStyle>;
}

interface ReactDatalistProps {
  options:string[],
  list:string,
  onInputChange?:(event:React.ChangeEvent<HTMLInputElement>) => void,
  onOptionSelected?:(value:string) => void,
  tooltipFct?:(value:string) => string,
  maxOptions?:number,
  label?:string,
  inputStyle?:React.CSSProperties,
}

interface ReactDatalistState {
  filter:string,
  hide:boolean,
  selected:number,
}

export class ReactDatalist extends React.Component<ReactDatalistProps, ReactDatalistState> {
  inputEl:React.RefObject<HTMLInputElement>;

  constructor(props:ReactDatalistProps) {
    super(props)
    this.inputEl = React.createRef();
    this.state = {
      filter: '',
      hide: true,
      selected: -1
    }
  }

  componentDidMount() {
    this.inputEl.current.focus()
  }

  render() {
    var options = this.filterOptions(this.props.options, this.state.filter, this.props.maxOptions);
    return (
      <div className="react-datalist-container" title={this.props.tooltipFct(this.state.filter)} style={{position: "relative"}}>
          <StyledTextfield style={{display: "flex"}}>
            <input list={this.props.list} value={this.state.filter} ref={this.inputEl} type="text" style={this.props.inputStyle}
                onChange={this.handleInputChange} onKeyUp={this.handleInputKeyUp} onKeyDown={this.handleInputKeyDown}
                onClick={this.handleInputClick} onBlur={this.handleInputBlur}/>
            {this.props.label?<Label>{this.props.label}</Label>:null}
          </StyledTextfield>
          <Datalist 
            id={this.props.list}
            hide={this.state.hide}
            select={this.handleOptionClick}
            options={options}
            selected={this.state.selected}
          />
      </div>
    )
  }
  
  handleInputClick = (event:React.MouseEvent<HTMLInputElement>) => {
    this.setState({ hide: false, selected: -1})
  }

  handleOptionClick = (event:React.MouseEvent<HTMLDivElement>) => {
    this.selectOption(event.target.innerText);
  }

  handleInputBlur = () => {
    setTimeout(() => this.setState({ hide: true }), 500); // such a hack, that I am ashamed...
  }

  handleInputChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filter   : event.target.value,
      selected : -1,
      hide     : false
    });

    if (typeof this.props.onInputChange === 'function') {
      event.persist();
      this.props.onInputChange(event);
    }
  }

  handleInputKeyDown = (event:React.KeyboardEvent<HTMLInputElement>) => {
    
    switch(event.which) {
      case 40:
        // DOWN Arrow
        var newSelectedIndex  = this.state.selected < 0 ? 0 : this.state.selected + 1
        var availableOptions  = this.filterOptions(this.props.options, this.state.filter, this.props.maxOptions)
        if (newSelectedIndex >= availableOptions.length) newSelectedIndex = availableOptions.length - 1
        this.setState({ selected : newSelectedIndex, hide: false })
        break
      case 38:
        // UP arrow
        var newSelectedIndex = this.state.selected > -1 ? this.state.selected - 1 : -1
        this.setState({selected : newSelectedIndex, hide: newSelectedIndex < 0})
        break
      case 9:
        // TAB
        if (this.state.selected > -1) { this.selectFilteredOption(this.state.selected) }
        else { this.selectOption(event.target.value) }
        break
      case 13:
        // ENTER
        if (this.state.selected > -1) { this.selectFilteredOption(this.state.selected) }
        else { this.selectOption(event.target.value) }
        break
    }
  }

  handleInputKeyUp = (event:React.KeyboardEvent<HTMLInputElement>) => {
    switch(event.which) {
      case 27:
        // ESC
        this.setState({ hide: true });
        break;
    }
  }

  filterOptions = (options:Array<string>, filter:string, maxOptions:number) => {
    if (!maxOptions) maxOptions = options.length;
    if (!filter) return getUniqueArray(options).slice(0, maxOptions);
    if (!options) return []
    return getUniqueArray(options).filter(function(option) {
        return option.toLowerCase().indexOf(filter.toLowerCase()) >= 0
    }).slice(0, maxOptions);
  }

  selectFilteredOption = (index:number) => {
    this.selectOption(this.filterOptions(this.props.options, this.state.filter, this.props.maxOptions)[index])
  }

  selectOption = (value:string) => {
    this.inputEl.current.value = value;
    this.setState({
      filter   : value||"",
      selected : -1,
      hide     : true
    })
    if (typeof this.props.onOptionSelected === 'function') this.props.onOptionSelected(value)
  }
}