import { getKeySearchString } from "../../../../globalData/helperFunctions";
import { getSelectionDialog } from "../components/dialogs/selectionDialog";

export const getCarSelectionDialog = async function getCarSelectionDialog() {
  var selectionList:string[] = [];
  var searchList:string[] = [];
  var keyArr:string[] = [];

  Object.entries(window.data.auto).sort(function(a,b) {
    return a[1].kennzeichen < b[1].kennzeichen ? -1 : 1;
  }).forEach(function(item) {
    selectionList.push(item[1].kennzeichen+" - "+item[1].hersteller);
    searchList.push(getKeySearchString(item[1]));
    keyArr.push(item[1].autoNr);
  });

  let result = await getSelectionDialog(selectionList, {searchList, keyArr});
  if (typeof result  === "string" && window.data.auto[result]) return window.data.auto[result];
  else return null;
}