import { Kunde } from "../../../../globalData/appInterfaces";
import { getKeySearchString } from "../../../../globalData/helperFunctions";
import { getSelectionDialog } from "../components/dialogs/selectionDialog";

export async function getClientSelectionDialog() {
  var selectionList: string[] = [];
  var searchList: string[] = [];
  var keyArr: string[] = [];

  getSortedClients(Object.values(window.data.kunde)).forEach(function (client) {
    selectionList.push(getClientAsString(client));
    searchList.push(getKeySearchString(client));
    keyArr.push(client.kundenNr);
  });

  let result = await getSelectionDialog(selectionList, { searchList, keyArr });
  if (typeof result === "string" && window.data.kunde[result]) return window.data.kunde[result];
  else return null;
}

export function getClientAsString(client: Kunde) {
  if (client.firma && client.nachname) {
    return `${client.firma} - ${client.vorname} ${client.nachname}`;
  } else if (client.firma) {
    return client.firma;
  } else {
    return `${client.vorname} ${client.nachname}`;
  }
}

export function getSortedClients(clients: Kunde[]) {
  return clients.sort(function (a, b) {
    const sortStringA = a.nachname || "" + a.vorname || "" + a.firma || "";
    const sortStringB = b.nachname || "" + b.vorname || "" + b.firma || "";
    return sortStringA < sortStringB ? -1 : 1;
  });
}
