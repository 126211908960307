import { navDestination } from "../navigation/navConstants";
import { subscribe, publish, pubSubTopic } from "../../pubSub";

import { fetchPage, saveData, checkLatestDataUX } from "../globalFunctions";
import { getConfirmDialog } from "../components/dialogs/confirmDialog";

import { showToast } from "../components/toast";
import { searchIcon, deleteIcon } from "../../svgIcons";
import { Dokument } from "../../../../globalData/appInterfaces";
import { EditType } from "../../../../globalData/dataEditInterfaces";
import { debounce, getKeySearchString } from "../../../../globalData/helperFunctions";
import { pageNavInitPayload } from "../navigation/navigation";
import { docPageInit } from "./docFunctionality";

interface overviewDoc extends Dokument {
  checked: boolean;
  index: number;
}

const overviewURL = "/pages/docs/docsOverview.html";
let docArray: Array<overviewDoc>;
const initialListLength = 20;
let listLength: number;

const filterOverview = debounce(function (event: Event) {
  showItems(event.target.value);
}, 350);

const saveMultiplePDF = function saveMultiplePDF() {
  let checkedArr = docArray.filter((item) => item.checked);
  if (checkedArr.length === 0) showToast("Keine Dokumente ausgewählt");
  else {
    let confirmText = "Wollen Sie für die folgenden Dokumente eine PDF-Datei erstellen: <br>";
    checkedArr.forEach((item) => {
      confirmText += item.nummer + " <br>";
    });
    getConfirmDialog(confirmText).then((res) => {
      if (res) {
        showToast("Warten auf Antwort des Servers...", 10000);
        return fetch("/getMultiplePDF", {
          method: "POST",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(checkedArr),
        })
          .then(function (res) {
            if (res.status > 199 && res.status < 300) return res.text();
            else throw new Error("Bad Server Statuscode: " + res.status);
          })
          .then(function openPDF(res) {
            var link = document.createElement("a");
            link.href = "/temp/" + res;
            link.download = `Sammelrechnung.pdf`;
            link.dispatchEvent(new MouseEvent("click"));

            setTimeout(function () {
              showToast("PDF öffnen", 8000, {
                text: "Öffnen",
                callback: function openPDF() {
                  window.open("/temp/" + res);
                },
              });
            }, 1000);
          })
          .catch(function (err) {
            console.warn(err);
            showToast("Serverfehler beim herunterladen");
          });
      }
    });
  }
};

export function docsOverviewInit() {
  docPageInit();

  subscribe(pubSubTopic.pageNavInitiated, function setPage(payload: pageNavInitPayload) {
    if (payload.destination === navDestination.docsOverview) docsOverview();
  });
}

export function docsOverview() {
  window.sessionData.setLastView(navDestination.docsOverview);

  docArray = Object.entries(window.data.dokument)
    .sort(function (a, b) {
      return a[1].nummer > b[1].nummer ? -1 : 1;
    })
    .map(function (item, index) {
      return { ...item[1], checked: false, index };
    });

  fetchPage(overviewURL, "docsOverview", navDestination.docsOverview).then(function () {
    document.querySelector("#docs-filter").addEventListener("input", filterOverview);
    listLength = initialListLength;
    setButtonFunctionality();
    showItems();
    checkLatestDataUX();
  });
}

function setButtonFunctionality() {
  let btnEl = document.querySelectorAll(".showMore");

  for (let i = 0; i < btnEl.length; i++) {
    btnEl[i].addEventListener("click", function () {
      listLength += initialListLength;
      showItems(document.querySelector("#docs-filter").value);
    });
  }

  btnEl = document.querySelectorAll(".saveMutliplePDF");

  for (let ii = 0; ii < btnEl.length; ii++) {
    btnEl[ii].addEventListener("click", saveMultiplePDF);
  }

  document.querySelector("#checkbox-header").addEventListener("change", function () {
    let chkBoxList = document.querySelectorAll("li input[type=checkbox]");

    for (let ii = 0; ii < chkBoxList.length; ii++) {
      chkBoxList[ii].checked = this.checked;

      let htmlEvent = new Event("change");
      chkBoxList[ii].dispatchEvent(htmlEvent);
    }
  });
}

function showItems(filterString: string = "") {
  var listEl = document.createElement("UL");
  listEl.id = "docs-list";

  var filteredArray = docArray.filter(function (item) {
    if (filterString) {
      var searchResult = true;
      var search = filterString.split(" ");
      search.forEach(function (query) {
        if (query) {
          searchResult = searchResult && getKeySearchString(item).indexOf(query.toLowerCase()) !== -1;
        }
      });

      return searchResult;
    } else return true;
  });

  for (var i = 0; i < filteredArray.length && i < listLength; i++) {
    listEl.appendChild(getElement(filteredArray[i]));
  }
  var showMoreBtn = document.querySelectorAll(".showMore");
  for (var i = 0; i < showMoreBtn.length; i++) {
    showMoreBtn[i].disabled = filteredArray.length < listLength;
  }

  var oldChild = document.getElementById("docs-list");
  oldChild.parentNode.replaceChild(listEl, oldChild);
}

function getElement(item: overviewDoc) {
  var newEl = document.createElement("LI");
  newEl.innerHTML = `<label class="sf-mdl-checkbox" for="checkbox-${item.index}">
    <input type="checkbox" id="checkbox-${item.index}" data-index="${item.index}" ${item.checked ? "checked" : ""}>
  </label>
  <div class="page-data doc-width nummer">${item.nummer}</div>
  <div class="page-data doc-width art">${item.art}</div>
  <div class="page-data doc-width titel">${item.titel}</div>
  <div class="button-box">
    <button class="sf-mdl-button view" data-element="${item.nummer}">
      <svg height="24px" ${searchIcon.viewBox}>${searchIcon.path}</svg>
    </button>
    <button class="sf-mdl-button remove" data-element="${item.nummer}">
      <svg height="24px" ${deleteIcon.viewBox}>${deleteIcon.path}</svg>
    </button>
  </div>`;

  newEl.querySelector("input[type=checkbox]").addEventListener("change", function () {
    docArray[parseInt(this.getAttribute("data-index"))].checked = this.checked;
  });
  newEl.querySelector("button.view").addEventListener("click", function () {
    publish(pubSubTopic.pageNavInitiated, {
      topic: pubSubTopic.pageNavInitiated,
      destination: navDestination.singleDocument,
      destObject: { element: this.getAttribute("data-element") },
    });
  });
  newEl.querySelector("button.remove").addEventListener("click", function () {
    deleteDocument(this.getAttribute("data-element"));
  });

  return newEl;
}

export function deleteDocument(elID: string) {
  getConfirmDialog("Soll dieses Dokument wirklich permanent gelöscht werden?").then(function (res) {
    if (res) {
      saveData([{ type: EditType.documentDelete, payload: elID }]).then(function (result) {
        if (result) docsOverview();
      });
    }
  });
}
