import { Dokument, Auftrag, Kunde, Artikel, AppData, Auto } from "./appInterfaces";

export enum EditType {
  clientDelete = "CLIENT_DELETE",
  orderDelete = "ORDER_DELETE",
  carDelete = "CAR_DELETE",
  clientNew = "CLIENT_NEW",
  orderNew = "ORDER_NEW",
  carNew = "CAR_NEW",
  clientEdit = "CLIENT_EDIT",
  orderEdit = "ORDER_EDIT",
  carEdit = "CAR_EDIT",
  documentNew = "DOCUMENT_NEW",
  documentDelete = "DOCUMENT_DELETE",
  articleEdit = "ARTICLE_EDIT",
  articleDelete = "ARTICLE_DELETE",
  import = "IMPORT"
}

export type clientDeletePayload = string;

export interface clientDeleteEvent {
  type: EditType.clientDelete;
  payload: clientDeletePayload;
}

export type clientNewPayload = Kunde;

export interface clientNewEvent {
  type: EditType.clientNew;
  payload: clientNewPayload;
}

export type clientEditPayload = Kunde;

export interface clientEditEvent {
  type: EditType.clientEdit;
  payload: clientEditPayload;
}

export type carDeletePayload = string;

export interface carDeleteEvent {
  type: EditType.carDelete;
  payload: carDeletePayload;
}

export type carNewPayload = Auto;

export interface carNewEvent {
  type: EditType.carNew;
  payload: carNewPayload;
}

export type carEditPayload = Auto;

export interface carEditEvent {
  type: EditType.carEdit;
  payload: carEditPayload;
}

export type orderDeletePayload = string;

export interface orderDeleteEvent {
  type: EditType.orderDelete;
  payload: orderDeletePayload;
}

export type orderNewPayload = Auftrag;

export interface orderNewEvent {
  type: EditType.orderNew;
  payload: orderNewPayload;
}

export type orderEditPayload = Auftrag;

export interface orderEditEvent {
  type: EditType.orderEdit;
  payload: orderEditPayload;
}

export type documentDeletePayload = string;

export interface documentDeleteEvent {
  type: EditType.documentDelete;
  payload: documentDeletePayload;
}

export type documentNewPayload = Dokument;

export interface documentNewEvent {
  type: EditType.documentNew;
  payload: documentNewPayload;
}

export type articleEditPayload = Artikel;

export interface articleEditEvent {
  type: EditType.articleEdit;
  payload: articleEditPayload;
}

export type articleDeletePayload = string;

export interface articleDeleteEvent {
  type: EditType.articleDelete;
  payload: articleDeletePayload;
}

export type importPayload = AppData;

export interface importEvent {
  type: EditType.import;
  payload: importPayload;
}

export type EditObject =
  | clientDeleteEvent
  | clientNewEvent
  | clientEditEvent
  | carDeleteEvent
  | carNewEvent
  | carEditEvent
  | orderDeleteEvent
  | orderNewEvent
  | orderEditEvent
  | documentDeleteEvent
  | documentNewEvent
  | articleEditEvent
  | articleDeleteEvent
  | importEvent;
