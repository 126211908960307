import * as React from "react";
import styled from "styled-components";

import { Label } from "./label";

const StyledContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 20px 0;

  select {
    box-sizing: border-box;
    font-size: 14px;
    margin: 0;
  }

  select:focus {
    outline: none;
  }

  label {
    position: absolute;
    top: 4px;
    left: 0;
    pointer-events: none;
    overflow: hidden;
    white-space: nowrap;
    color: rgb(96,125,139);
    font-size: 12px;
  }
`;


interface SelectProps extends React.HTMLAttributes<HTMLDivElement> {
  options: {value: string, text?:string, disabled?: boolean}[],
  label?:string,
  selectProps?: React.SelectHTMLAttributes<HTMLSelectElement>
}

export function Select(props:SelectProps) {
  const { options, label, selectProps, ...containerProps } = props;
  return <StyledContainer {...containerProps}>
    <select {...selectProps}>
      {options.map(option => {
        return <option value={option.value} key={option.value} disabled={option.disabled || null} >
          {option.text || option.value}
        </option>
      })}
    </select>
    {label?<Label>{label}</Label>:null}
  </StyledContainer>
}