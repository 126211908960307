import { Kunde } from "../../../../globalData/appInterfaces";
import { pageNavInitPayload } from "../navigation/navigation";
import { subscribe, publish, pubSubTopic } from "../../pubSub";
import { fetchPage, saveData } from "../globalFunctions";

import { getViewLink } from "../htmlElements/variousElements";
import { getConfirmDialog } from "../components/dialogs/confirmDialog";
import { showToast } from "../components/toast";

import { clientEdit, saveClient, clientAddCar } from "./editFunctionality";
import { EditType } from "../../../../globalData/dataEditInterfaces";
import { navDestination } from "../navigation/navConstants";
import { getClientSelectionDialog } from "./clientHelpers";
import { strToDate } from "../../../../globalData/helperFunctions";
import { getSelectionDialog } from "../components/dialogs/selectionDialog";

export var viewURL = "pages/kunde/anzeige.html";
export var editURL = "pages/kunde/eingabe.html";

export function clientPageInit() {
  subscribe(pubSubTopic.pageNavInitiated, clientNavHandling);
}

export function clientNavHandling(payload: pageNavInitPayload) {
  if (payload.destination === navDestination.client) {
    if (!payload || !payload.destObject || !window.data.kunde[payload.destObject.element]) kundenEingabe();
    else kundenAnzeige(payload.destObject.element);
  }
}

export function kundenAuswahl() {
  getClientSelectionDialog().then((result) => {
    if (result) kundenAnzeige(result.kundenNr);
  });
}

export function kundenAnzeige(kundenID: string) {
  fetchPage(viewURL, "anzeige", navDestination.client).then(function () {
    window.sessionData.setLastView(navDestination.client, kundenID);

    setButtonFunctionality();
    kundenDatenEintragen(window.data.kunde[kundenID]);
  });
}
export function kundenEingabe() {
  window.sessionData.setLastView(navDestination.client, null);
  return fetchPage(editURL, "eingabe", navDestination.client).then(function () {
    setButtonFunctionality();
  });
}

export function setButtonFunctionality() {
  let elmts = document.querySelectorAll("button.kundenEingabe");
  for (var i = elmts.length - 1; i >= 0; i--) {
    elmts[i].addEventListener("click", kundenEingabe);
  }
  elmts = document.querySelectorAll("button.kundenAuswahl");
  for (var i = elmts.length - 1; i >= 0; i--) {
    elmts[i].addEventListener("click", kundenAuswahl);
  }
  elmts = document.querySelectorAll("button.clientAddCar");
  for (var i = elmts.length - 1; i >= 0; i--) {
    elmts[i].addEventListener("click", clientAddCar);
  }
  elmts = document.querySelectorAll("button.clientSave");
  for (var i = elmts.length - 1; i >= 0; i--) {
    elmts[i].addEventListener("click", saveClient);
  }
  elmts = document.querySelectorAll("button.clientEdit");
  for (var i = elmts.length - 1; i >= 0; i--) {
    // onclick because then it can be accessed from a test
    elmts[i].onclick = () => {
      clientEdit(window.sessionData.client);
    };
  }
  elmts = document.querySelectorAll("button.deleteClient");
  for (var i = elmts.length - 1; i >= 0; i--) {
    elmts[i].addEventListener("click", deleteClient);
  }
  elmts = document.querySelectorAll("button.showServices");
  for (var i = elmts.length - 1; i >= 0; i--) {
    elmts[i].addEventListener("click", showOrders);
  }
}

function kundenDatenEintragen(kunde: Kunde) {
  document.getElementById("data-kunde-kommentar").innerHTML = kunde.kommentar;
  document.getElementById("data-kunde-kundenNr").innerHTML = kunde.kundenNr;
  document.getElementById("data-kunde-firma").innerHTML = kunde.firma;

  document.getElementById("data-kunde-festnetz").innerHTML =
    '<a href="tel:' + kunde.festnetz + '">' + kunde.festnetz + "</a>";
  document.getElementById("data-kunde-mobil").innerHTML = '<a href="tel:' + kunde.mobil + '">' + kunde.mobil + "</a>";
  document.getElementById("data-kunde-email").innerHTML =
    '<a href="mailto:' + kunde.email + '">' + kunde.email + "</a>";

  document.getElementById("data-kunde-geburtstag").innerHTML = strToDate(kunde.geburtstag);
  document.getElementById("data-kunde-name").innerHTML = kunde.vorname || "" + " " + kunde.nachname || "";
  document.getElementById("data-kunde-adresse").innerHTML = kunde.strasse + ", " + kunde.plz + " " + kunde.stadt;

  var linkTarget = document.getElementById("data-kunde-autos");

  kunde.autos.forEach(function (kundenAuto) {
    var el = getViewLink();
    el.innerHTML =
      el.innerHTML + window.data.auto[kundenAuto].kennzeichen + " - " + window.data.auto[kundenAuto].hersteller;
    el.addEventListener("click", function () {
      publish(pubSubTopic.pageNavInitiated, {
        topic: pubSubTopic.pageNavInitiated,
        destination: navDestination.car,
        destObject: { element: kundenAuto },
      });
    });
    linkTarget.appendChild(el);
  });

  emptyCheck(kunde);
}

function emptyCheck(kunde: Kunde) {
  ["festnetz", "mobil", "email", "geburtstag", "kommentar", "kundenNr", "firma"].forEach(function (id) {
    if (!(<any>kunde)[id]) document.getElementById("data-kunde-" + id).parentNode.style.display = "none";
  });

  if (!kunde.strasse && !kunde.plz && !kunde.stadt) {
    document.getElementById("adresseSection").style.display = "none";
  }

  if (!kunde.geburtstag && !kunde.kundenNr && !kunde.kommentar) {
    document.getElementById("variousSection").style.display = "none";
  }
}

export function deleteClient() {
  getConfirmDialog("Soll dieser Kunde wirklich permanent gelöscht werden?").then(function (res) {
    if (res) {
      if (window.data.kunde[window.sessionData.client].auftraege.length > 0) {
        showToast("Dieser Kunde wird in einem Service genutzt. Zuerst Service löschen.");
      } else {
        saveData([{ type: EditType.clientDelete, payload: window.sessionData.client }]).then(function (result) {
          if (result) kundenEingabe();
        });
      }
    }
  });
}

function showOrders() {
  let selectionList = window.data.kunde[window.sessionData.client].auftraege.map(function (item) {
    return window.data.auftrag[item].datum + " - " + window.data.auftrag[item].titel;
  });
  let keyArr = window.data.kunde[window.sessionData.client].auftraege.map(function (item) {
    return window.data.auftrag[item].auftragsNr;
  });

  getSelectionDialog(selectionList, { keyArr }).then(function (id) {
    if (typeof id === "string") {
      publish(pubSubTopic.pageNavInitiated, {
        topic: pubSubTopic.pageNavInitiated,
        destination: navDestination.order,
        destObject: {
          element: id,
        },
      });
    }
  });
}
