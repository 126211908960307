import * as React from "react";
import styled from "styled-components";
import { white2, shadow0 } from "../../../styleConstants";

const FileInputForm = styled.form<Partial<FileInputProps>>`
  appearance: button;
  margin: 15px 0 16px 15px;
  display: inline-flex;
  vertical-align: top;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: ${white2};
  flex-shrink: 0;
  box-shadow: ${shadow0};
  min-width: 64px;
  border: none;
  border-radius: 2px;
  cursor: pointer;

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    background-color: inherit;
    text-align: center;
    color: ${({ disabled }) => {
      return disabled ? "graytext" : "buttontext";
    }};
    text-transform: uppercase;
    line-height: 36px;
    cursor: pointer;
    padding: 0 16px;
    outline: none;
    font-size: 0.75rem;
    font-weight: bold;
    ${({ disabled }) => {
      return disabled ? "pointer-events:none" : "";
    }};
  }
`;

interface FileInputProps extends React.FormHTMLAttributes<HTMLFormElement> {
  title: string;
  disabled?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

export function FileInputButton(props: FileInputProps) {
  const { title, disabled, inputProps, ...formProps } = props;

  return (
    <FileInputForm disabled={!!disabled} {...formProps}>
      <label>
        <input type="file" {...inputProps} />
        {title}
      </label>
    </FileInputForm>
  );
}
