import * as Sentry from "@sentry/browser";

import { AppData } from "../../../globalData/appInterfaces";
import { publish, pubSubTopic } from "../pubSub";

import { loginDialog } from "./loginPages/loginDialog";
import { showToast } from "./components/toast";
import { getConfirmDialog } from "./components/dialogs/confirmDialog";
import { EditObject } from "../../../globalData/dataEditInterfaces";
import { navDestination } from "./navigation/navConstants";
import { upIcon, downIcon } from "../svgIcons";
import { phoneQuerySize } from "../styleConstants";

/**
 * The function compares the timestamp when the data was last edited on backend and what is stored locally in the frontend
 * @param latestEditOnFrontend is the locally stored timestamp when the data was last edited
 * @return whether the local timestamp and the timestamp from the backend are equal
 */
export async function checkLatestEdit(latestEditOnFrontend: number): Promise<boolean> {
  try {
    const result = await fetch("/latestDataEdit", {
      method: "GET",
      credentials: "same-origin",
    });
    const textResult = await result.text();

    let latestEditOnBackend = parseInt(textResult);
    if (latestEditOnBackend < latestEditOnFrontend) {
      throw new Error("Local version of data should not be able to be more current, than the server");
    }

    return latestEditOnBackend === latestEditOnFrontend;
  } catch (err) {
    if (err.status === 401) {
      showToast("Unauthorisiert");
      loginDialog();
    } else {
      showToast("Fehler beim Aktualitätscheck Daten");
      console.warn("Error checking latest data: " + err);
      Sentry.captureException(err);
    }
    return null;
  }
}

export const checkLatestDataUX = function checkLatestDataUX() {
  return checkLatestEdit(window.data.lastEdit).then(function latestCheck(result) {
    if (result === false) {
      showToast("Auf dem Server sind neuere Daten. Bitte vor dem Bearbeiten synchronisieren.", 8000, {
        text: "Aktualisieren",
        callback: function aktualisieren() {
          location.reload();
        },
      });
    }
  });
};

export const sendSaveRequest = function sendSaveRequest(edit: Array<EditObject>) {
  showToast("An Server gesendet...");
  return fetch("/saveData", {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(edit),
  })
    .then(function (res: any) {
      if (res.status !== 200) {
        return res.text().then(function (textRes: string) {
          console.warn(textRes);
          showToast("Fehler beim Speichern");
          Sentry.captureMessage(textRes);
          return false;
        });
      } else {
        return res.json().then(function handleSuccessfullSave(jsonRes: AppData) {
          window.data = jsonRes;
          showToast("Gespeichert");
          return true;
        });
      }
    })
    .catch(function (err) {
      if (err.status === 401) {
        showToast("Unauthorisiert");
        loginDialog();
      } else {
        showToast("Fehler beim Speichern");
        console.warn("Error saving data to server: " + err);
        Sentry.captureException(err);
      }
      return false;
    });
};

export function saveData(edit: Array<EditObject>) {
  return checkLatestEdit(window.data.lastEdit)
    .then(function handleLatestEdit(varLatest) {
      if (varLatest) return true;
      else if (varLatest === false)
        return getConfirmDialog(
          "Die Daten auf dem Server sind aktueller als die lokalen Daten. <br>" +
            "Es wird empfohlen vor dem Speichern nochmals zu aktualisieren (F5). <br><br>" +
            "Wollen Sie <strong>trotzdem</strong> fortfahren und speichern? <br><br>" +
            "<strong>Achtung: Fortfahren kann zu unvorhergesehen Daten führen!</strong>"
        );
    })
    .then(function handleUserResponse(res) {
      if (!res) return false;
      else return sendSaveRequest(edit);
    });
}

export const fetchPage = function fetchPage(url: string, addClass: string, section: navDestination) {
  return fetch(url, {
    method: "GET",
    credentials: "same-origin",
  })
    .then(function (res) {
      return res.text();
    })
    .then(function (text) {
      var pageContent = document.getElementById("page-content");

      pageContent.innerHTML = text;

      // weirdly empty text was inserted here before the table in the invoice-view
      // , which printed a character on mobile devices.
      // The following loop removes this text and other such nodes
      for (var i = pageContent.childNodes.length - 1; i > -1; i--) {
        if (pageContent.childNodes.item(i).nodeName === "#text") {
          pageContent.childNodes.item(i).remove();
        }
      }

      pageContent.className = `page-content ${addClass} ${section}`;

      if (addClass === "eingabe") addTabOnEnter();
      else if (addClass === "anzeige") addDisplayToggle();

      publish(pubSubTopic.pageLoaded, { topic: pubSubTopic.pageLoaded, destination: section });
    })
    .catch(function (err) {
      showToast("Fehler beim Laden der Seite");
      console.warn(err);
      Sentry.captureException(err);
    });
};

function addTabOnEnter() {
  var inputs = document.querySelectorAll("input");

  var evtFunction = function (nextTarget: HTMLElement, evt: KeyboardEvent) {
    if (evt.key === "Enter") {
      nextTarget.focus();
    }
  };

  for (var i = 0; i < inputs.length; i++) {
    inputs[i].addEventListener("keyup", evtFunction.bind(null, inputs[i + 1]));
  }
}

export const addDisplayToggle = function addDisplayToggle() {
  if (phoneQuerySize > window.innerWidth) {
    let spans = document.querySelectorAll(".toggleSectionDisplay");

    for (let ii = 0; ii < spans.length; ii++) {
      spans[ii].addEventListener(
        "click",
        () => {
          lineCollapse(spans[ii].parentNode, { heightPx: 36, hiddenInitial: true });
          let svgEl = spans[ii].querySelector("svg");
          if (svgEl.innerHTML === upIcon.path) svgEl.innerHTML = downIcon.path;
          else svgEl.innerHTML = upIcon.path;
        },
        window.safePassiveEvent
      );
    }
  }
};

export var saveTxtFile = function saveTxtFile(data: string, dataName: string) {
  if (!dataName) dataName = "data.txt";
  var tempEl = document.createElement("a");
  tempEl.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(data));
  tempEl.setAttribute("download", dataName);

  if (document.createEvent) {
    var event = document.createEvent("MouseEvents");
    event.initEvent("click", true, true);
    tempEl.dispatchEvent(event);
  } else {
    tempEl.click();
  }
};

interface lineCollapseOptions {
  timing?: number;
  heightPx?: number;
  hiddenInitial?: boolean;
}

export const lineCollapse = function (elmt: HTMLElement | EventTarget | Node, varOptions: lineCollapseOptions = {}) {
  if (!varOptions.timing) varOptions.timing = 350;
  let hiddenHeight = (varOptions.heightPx || "0") + "px";

  if (elmt.getAttribute("data-style-set") !== "true") {
    let tempStyles = window.getComputedStyle(<Element>elmt);
    if (tempStyles.getPropertyValue("transition").indexOf("height") === -1) {
      elmt.style.transition += `height ${varOptions.timing}ms ease-in-out`;
    }
    if (tempStyles.getPropertyValue("overflow") !== "hidden") elmt.style.overflow = "hidden";
    elmt.setAttribute("data-style-set", "true");
    tempStyles = null;
  }

  let visibleCheck: boolean;
  if (varOptions.hiddenInitial && !elmt.classList.contains("animate-visible")) visibleCheck = false;
  else if (!varOptions.hiddenInitial && elmt.classList.contains("animate-hidden")) visibleCheck = false;
  else visibleCheck = true;

  // If the element is visible, hide it
  if (visibleCheck) {
    // Give the element a height to change from
    elmt.style.height = elmt.clientHeight + "px";

    // Set the height to 0
    setTimeout(function () {
      elmt.style.height = hiddenHeight;
      elmt.classList.remove("animate-visible");
      elmt.classList.add("animate-hidden");
    }, 1);
  } else {
    // If the element does contain the hidden class, show it
    // Get the natural height of the element without animating
    let tempHolder = elmt.style.transition;
    elmt.style.transition = "unset";
    elmt.style.height = "unset";
    let height = elmt.clientHeight + "px"; // Get it's height
    elmt.style.height = hiddenHeight; //  Hide it again

    setTimeout(() => {
      elmt.style.transition = tempHolder; // activate transitioning

      // Once the transition is complete, remove the inline max-height so the content can scale responsively
      elmt.addEventListener(
        "transitionend",
        function showFct(evt) {
          elmt.style.height = "unset";
          elmt.classList.remove("animate-hidden");
          elmt.classList.add("animate-visible");
          evt.target.removeEventListener(evt.type, showFct);
        },
        window.safePassiveEvent
      );
      elmt.style.height = height; // Update the max-height
    }, 1);
  }
};
