import * as React from "react";
import * as ReactDOM from "react-dom";
// own packages
import { Button } from "../formComponents/button";
import { DialogContainer, DialogContent, DialogActions } from "./genericDialogStyles";
import { pubSubTopic, publish, pubSubBasePayload, subscribe } from "../../../pubSub";

interface invisibleState {
  visible: false;
}

interface visibleState {
  visible: true;
  text: string;
  promiseResolve?: (res: boolean) => void;
}

type DialogState = invisibleState | visibleState;

export class ConfirmDialog extends React.PureComponent<{}, DialogState> {
  constructor(props: {}) {
    super(props);
    this.state = { visible: false };
    subscribe(pubSubTopic.showConfirmDialog, (pl: confirmDialogPayload) => {
      this.setState({
        visible: true,
        text: pl.text,
        promiseResolve: pl.promiseResolve
      });
    });
  }

  onCancel = () => {
    const state = this.state as visibleState;
    state.promiseResolve(false);
    this.setState({ visible: false });
  };

  onConfirm = () => {
    const state = this.state as visibleState;
    state.promiseResolve(true);
    this.setState({ visible: false });
  };

  render() {
    if (!this.state.visible) return null;
    else {
      const { text } = this.state;

      return (
        <DialogContainer>
          <DialogContent>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </DialogContent>
          <DialogActions>
            <Button className="cancel" onClick={this.onCancel}>
              Abbrechen
            </Button>
            <Button className="confirm" onClick={this.onConfirm}>
              Bestätigen
            </Button>
          </DialogActions>
        </DialogContainer>
      );
    }
  }
}

export interface confirmDialogPayload extends pubSubBasePayload {
  text: string;
  topic: pubSubTopic.showConfirmDialog;
  promiseResolve: (res: boolean) => void;
}

export function getConfirmDialog(text: string): Promise<boolean> {
  return new Promise(function(resolve) {
    publish(pubSubTopic.showConfirmDialog, {
      text,
      topic: pubSubTopic.showConfirmDialog,
      promiseResolve: resolve
    });
  });
}

export function confirmDialogInit() {
  ReactDOM.render(<ConfirmDialog />, document.getElementById("confirm-dialog-container"));
}
