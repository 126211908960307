export enum FilterRulesEnum {
  search = "Suche",
  filterbasis = "Filterbasis",
  //order: article
  article = "Artikel/Position",
  //order: parameter based
  client = "Kunde",
  orderTitle = "Titel",
  orderStatus = "Auftragstatus",
  //order: invoice based
  invDate = "Rechnungsdatum",
  invDueDate = "Zahlungsziel (Rechnung)",
  // car based
  owner = "Besitzer",
  timingBeltDate = "Zahnriemen [Datum]",
  oilDate = "Ölwechsel [Datum]",
  tuevDate = "TÜV [Datum]",
}

// Order is also used to show these items
export const orderFilterOptions: FilterRulesEnum[] = [
  FilterRulesEnum.client, // = "Kunde",
  FilterRulesEnum.orderTitle, // = "Bauvorhaben",
  FilterRulesEnum.orderStatus, // = "Auftragstatus",
  FilterRulesEnum.article, // = "Artikel/Position",
  FilterRulesEnum.invDate, // = "Rechnungsdatum",
  FilterRulesEnum.invDueDate, // = "Zahlungsziel (Rechnung)",
];

export const carFilterOptions: FilterRulesEnum[] = [
  FilterRulesEnum.owner, // = "Besitzer",
  FilterRulesEnum.tuevDate, // = "TÜV [Datum]"
  FilterRulesEnum.timingBeltDate, // = "Zahnriemen [Datum]",
  FilterRulesEnum.oilDate, // = "Ölwechsel [Datum]",
];

export enum FilterbasisEnum {
  orders = "Aufträge",
  cars = "Autos",
}
