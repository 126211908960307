import { selectionDialogPayload } from "./modules/components/dialogs/selectionDialog";
import { confirmDialogPayload } from "./modules/components/dialogs/confirmDialog";
import { toastPubSubPayload } from "./modules/components/toast";
import { navDestination } from "./modules/navigation/navConstants";
import { overviewFilterPayload } from "./modules/overviewPage/overview";
import { pageNavInitPayload } from "./modules/navigation/navigation";

export enum pubSubTopic {
  loginSuccessfull = "LOGIN_SUCCESSFULL",
  loginCancel = "LOGIN_CANCEL",
  pageNavInitiated = "PAGE_NAVIGATION_INITIATED",
  pageLoaded = "PAGE_LOADED",
  sideNavOpen = "SIDENAV_OPEN",
  loginSubmitted = "LOGIN_SUBMITTED",
  loginError = "LOGIN_ERROR",
  logoutInit = "LOGOUT_INITIATED",
  showSpinner = "SHOW_SPINNER",
  showOnlySpinner = "SHOW_ONLY_SPINNER",
  hideSpinner = "HIDE_SPINNER",
  // React Data Model
  overviewShowMore = "OVERVIEW_SHOW_MORE",
  overviewFilter = "OVERVIEW_FILTER",
  // dialogs
  showSelectionDialog = "SHOW_SELECTION_DIALOG",
  showToast = "SHOW_TOAST",
  showConfirmDialog = "SHOW_CONFIRM_DIALOG",
}

type pubSubPayload =
  | pageNavInitPayload
  | pageLoadedPayload
  | loginSubmittedPayload
  | loginErrorPayload
  | overviewFilterPayload
  | toastPubSubPayload
  | selectionDialogPayload
  | confirmDialogPayload;

export interface pubSubBasePayload {
  topic: string;
}

export interface pageLoadedPayload extends pubSubBasePayload {
  topic: pubSubTopic.pageLoaded;
  destination: navDestination;
}

export interface loginSubmittedPayload extends pubSubBasePayload {
  topic: pubSubTopic.loginSubmitted;
  user: string;
  pass: string;
  saveLogin?: boolean;
}

export interface loginErrorPayload extends pubSubBasePayload {
  topic: pubSubTopic.loginError;
  statusText: string;
}

const topics: { [key: string]: Function[] } = {};

export function subscribe(topic: pubSubTopic, listener: Function): { remove: Function } {
  // Create the topic's object if not yet created
  if (!topics.hasOwnProperty(topic)) topics[topic] = [];

  // Add the listener to queue
  let index = topics[topic].push(listener) - 1;

  // Provide handle back for removal of topic
  return {
    remove: function () {
      delete topics[topic][index];
    },
  };
}

export function publish(topic: pubSubTopic, payload?: pubSubPayload) {
  // If the topic doesn't exist, or there's no listeners in queue, just leave
  if (!topics.hasOwnProperty(topic)) return;

  // Cycle through topics queue, fire!
  topics[topic].forEach(function (listener) {
    if (payload === undefined) (payload as any) = {};
    listener(payload);
  });
}
