import axios from "axios";

import { subscribe, publish, pubSubTopic } from "../../pubSub";

import { fetchPage, saveData } from "../globalFunctions";
import { showToast } from "../components/toast";

import { deleteDocument, docsOverview } from "./overviewFunctionality";

import {
  AuftragsPosition,
  Dokument,
  DokumentArt,
  Auftrag,
} from "../../../../globalData/appInterfaces";
import { EditType, EditObject } from "../../../../globalData/dataEditInterfaces";
import { pageNavInitPayload } from "../navigation/navigation";
import { navDestination } from "../navigation/navConstants";
import {
  strToDate,
  numToStr,
  getDateForDOM,
  getVatRate,
} from "../../../../globalData/helperFunctions";
import { editOrder } from "../orderPage/editFunctionality";

var tempDocument: Dokument;
var invoiceURL = "/pages/docs/invoice.html";

var getInvoicePosition = function getInvoicePosition(
  pos: AuftragsPosition,
  positionCounter: number
) {
  var newEl = document.createElement("TR");
  newEl.innerHTML =
    "<td>Pos.</td><td>Art.-Nr.</td><td>Bezeichnung</td><td>Preis/Einheit</td>" +
    "<td>Rabatt</td><td>Menge</td><td>Preis (Netto)</td>";

  if ("ueberschrift" in pos) {
    newEl.children[0].innerText = newEl.children[1].innerText = newEl.children[6].innerText = "";
    newEl.children[3].innerText = newEl.children[4].innerText = newEl.children[5].innerText = "";
    newEl.children[2].innerText = pos.ueberschrift;
    newEl.children[2].style.fontWeight = "bold";
  } else {
    newEl.children[0].innerText = positionCounter.toString();
    newEl.children[1].innerText = pos.artikelNr;
    newEl.children[2].innerText = pos.bezeichnung;
    newEl.children[3].innerText = String(pos.preisEinheit).replace(".", ",");
    newEl.children[4].innerText = String(pos.rabatt).replace(".", ",") + " %";
    newEl.children[5].innerText = String(pos.menge).replace(".", ",");

    var summe = Math.round(pos.preisEinheit * pos.menge * (1 - pos.rabatt / 100) * 100) / 100;
    newEl.children[6].innerText = String(summe).replace(".", ",");
  }

  return newEl;
};

export function setDocNumber(tempDoc: Dokument) {
  const getInvoiceID = function getInvoiceID(argDate: Date, argNumber: number) {
    return (
      argDate.getFullYear().toString().substr(-2) +
      "-" +
      ("00" + (argDate.getMonth() + 1)).substr(-2) +
      "-" +
      ("000" + argNumber).substr(-3) +
      (tempDoc.art === "Kostenvoranschlag" ? "-K" : "")
    );
  };

  var initDate = new Date(tempDoc.auftrag.datum);
  var tempNumber = 1;

  while (window.data.dokument[getInvoiceID(initDate, tempNumber)] !== undefined) {
    tempNumber++;
  }

  return getInvoiceID(initDate, tempNumber);
}

function fillInvoiceView(docData: Dokument) {
  window.scrollTo(0, 0);

  document.getElementById("dokumentenNr").innerText = docData.nummer;
  document.getElementById("document-type").innerText = docData.art;
  document.getElementById("datum").innerText = strToDate(docData.auftrag.datum);

  ["zahlungsart", "kmStand"].forEach(function (item) {
    document.getElementById(item).innerText = (<any>docData.auftrag)[item] || "";
  });

  if (docData.auto)
    ["kennzeichen", "hersteller", "modell", "vin"].forEach(function (item) {
      document.getElementById(item).innerText = (<any>docData.auto)[item] || "";
    });

  if (docData.kunde)
    ["nachname", "vorname", "strasse", "plz", "stadt", "kundenNr", "firma"].forEach(function (
      item
    ) {
      document.getElementById(item).innerText = (<any>docData.kunde)[item] || "";
    });

  if (docData.auftrag.zahlungsziel) {
    document.getElementById("zahlungsziel").innerText =
      "Bitte zahlen Sie die Rechnung fristgerecht bis zum " +
      strToDate(docData.auftrag.zahlungsziel) +
      ".";
  }

  var contentTable = document.querySelector("table.content tbody");

  var posCounter = 1;
  docData.auftrag.positionen.forEach(function (pos) {
    contentTable.appendChild(getInvoicePosition(pos, posCounter));
    if ("artikelNr" in pos) posCounter++;
  });

  var netSum = docData.auftrag.positionen.reduce(function (curr: number, item: AuftragsPosition) {
    if ("ueberschrift" in item) return curr;
    return item.preisEinheit * item.menge * (1 - item.rabatt / 100) + curr;
  }, 0);
  var sumTable = document.querySelector("table.sum");

  const vatRate = getVatRate(docData.auftrag.datum) * 100;
  document.querySelector("#vat-rate").innerText = vatRate.toString();

  sumTable.querySelector("td.net").innerText = numToStr(Math.round(netSum * 100) / 100) + " €";
  sumTable.querySelector("td.tax").innerText = numToStr(Math.round(netSum * vatRate) / 100) + " €";
  sumTable.querySelector("td.gross").innerText =
    numToStr(Math.round(netSum * (100 + vatRate)) / 100) + " €";
}

async function invoicePrint() {
  try {
    showToast("PDF wird erstellt...", 3000);
    const fileName = (
      await axios.request({
        url: "/getPDF",
        method: "POST",
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
        data: tempDocument,
      })
    ).data;

    const newWindow = window.open("/temp/" + fileName);
    newWindow.print();
  } catch (error) {
    window.print();
  }
}

function savePDF() {
  showToast("Warten auf Antwort des Servers...", 10000);
  return fetch("/getPDF", {
    method: "POST",
    credentials: "same-origin",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(tempDocument),
  })
    .then(function (res) {
      if (res.status > 199 && res.status < 300) return res.text();
      else throw new Error("Bad Server Statuscode: " + res.status);
    })
    .then(function openPDF(txtRes) {
      var link = document.createElement("a");
      link.href = "/temp/" + txtRes;
      link.download = `${tempDocument.art}_${tempDocument.nummer}.pdf`;
      link.dispatchEvent(new MouseEvent("click"));

      setTimeout(function () {
        showToast("PDF öffnen", 8000, {
          text: "Öffnen",
          callback: function openPDF() {
            window.open("/temp/" + txtRes);
          },
        });
      }, 1000);
    })
    .catch(function (err) {
      console.warn(err);
      showToast("Serverfehler beim herunterladen");
    });
}

function setButtonFunctionality() {
  var containerEl = document.getElementById("side-nav-content");
  var tempEl;
  for (var ii = containerEl.children.length - 1; ii > -1; ii--) {
    if (containerEl.children[ii].nodeName === "BUTTON") {
      tempEl = containerEl.children[ii];
      switch (containerEl.children[ii].id) {
        case "invoicePrint":
          tempEl.addEventListener("click", invoicePrint);
          break;
        case "pdfSave":
          tempEl.addEventListener("click", savePDF);
          break;
        case "documentOverview":
          tempEl.addEventListener("click", function () {
            publish(pubSubTopic.pageNavInitiated, {
              topic: pubSubTopic.pageNavInitiated,
              destination: navDestination.docsOverview,
            });
          });
          break;
        case "deleteDocument":
          tempEl.addEventListener("click", deleteDocument.bind(null, tempDocument.nummer));
          break;
        case "orderView":
          tempEl.addEventListener("click", () => {
            publish(pubSubTopic.pageNavInitiated, {
              topic: pubSubTopic.pageNavInitiated,
              destination: navDestination.order,
              destObject: { element: tempDocument.auftrag.auftragsNr },
            });
          });
          break;
        case "orderEdit":
          tempEl.addEventListener("click", () => {
            editOrder(tempDocument.auftrag.auftragsNr);
          });
          break;
      }
    }
  }
  containerEl = tempEl = null;
}

export function showInvoice() {
  fetchPage(invoiceURL, "invoice", navDestination.singleDocument).then(function () {
    fillInvoiceView(tempDocument);
    setButtonFunctionality();
  });
}

function saveDocument(order: Auftrag) {
  var today = new Date();

  tempDocument.titel = order.titel;
  tempDocument.speicherdatum = getDateForDOM(today);
  tempDocument.auto = window.data.auto[order.auto];
  tempDocument.kunde = window.data.kunde[order.kunde];
  tempDocument.auftrag = order;
  tempDocument.nummer = setDocNumber(tempDocument);

  let editObject: EditObject = { type: EditType.documentNew, payload: tempDocument };

  saveData([editObject]).then(showInvoice);
}

export function getOffer(order: Auftrag) {
  tempDocument = <Dokument>{
    art: DokumentArt.kostenvoranschlag,
  };
  saveDocument(order);
}

export function getInvoice(order: Auftrag) {
  tempDocument = <Dokument>{
    art: DokumentArt.rechnung,
  };
  saveDocument(order);
}

export function docPageInit() {
  subscribe(pubSubTopic.pageNavInitiated, function setPage(payload: pageNavInitPayload) {
    if (payload.destination === navDestination.singleDocument) {
      tempDocument = null;
      if (payload.destObject && payload.destObject.element) {
        tempDocument = window.data.dokument[payload.destObject.element];
      }
      if (tempDocument) showInvoice();
      else docsOverview();
    }
  });
}
