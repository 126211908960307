import styled from "styled-components";

export const Label = styled.label`
  transition: opacity .3s;
  position: absolute;
  color: #607d8b;
  font-size: 14px;
  top: 4px;
  left: 0;
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
`;