// Smartphone breakpoint
export const phoneQuerySize = 550;
export const phoneMediaQuery =
  "screen and (max-width: " +
  phoneQuerySize +
  "px), screen and (max-height: " +
  phoneQuerySize +
  "px)";

export const viewSectionMargin = "23.5px 1px 23.5px 24px";

export const shadow0 =
  "0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12)";
export const shadow1 = "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)";
export const shadow2 = "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)";
export const shadow3 = "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)";
export const shadow4 = "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)";

export const dark1 = "#323232";

export const white0 = "white";
export const white1 = "#FAFAFA";
export const white2 = "#F5F5F5";
export const white3 = "#EEEEEE";
export const white4 = "#E0E0E0";
export const white5 = "#BDBDBD";

export const labelColor = "#607d8b";

export const grey = "#607d8b";

export const primaryColor = "#607d8b";

export const maxPageWidth = 900;
