import * as React from "react";
import styled from "styled-components";

interface IconProps extends React.HTMLAttributes<SVGSVGElement> {
  // this is a hack to get styled components and react to play together
  ref?: any;
  viewBox?: string;
}

interface BaseProps extends React.HTMLAttributes<SVGSVGElement> {
  children: JSX.Element | JSX.Element[];
  viewBox?: string;
}

const StyledSVG = styled.svg`
  min-width: 24px;
  min-height: 24px;
  height: 24px;

  path {
    pointer-events: none;
  }
`;

function BaseIcon(props: BaseProps) {
  const { children, viewBox, ...restProps } = props;
  return (
    <StyledSVG {...restProps} xmlns="http://www.w3.org/2000/svg" viewBox={viewBox || "0 0 24 24"}>
      {children}
    </StyledSVG>
  );
}

export function SearchIcon(props: IconProps) {
  const iconPath =
    "M15.5 14h-.8l-.3-.3c1-1 1.6-2.6 1.6-4.2C16 6 13 3 9.5 3S3 6 3 9.5 6 16 9." +
    "5 16c1.6 0 3-.6 4.2-1.6l.3.3v.8l5 5 1.5-1.5-5-5zm-6 0C7 14 5 12 5 9.5S7 5 9.5 5 14 7 14 9.5 12 14 9.5 14z";
  return (
    <BaseIcon {...props}>
      <path d={iconPath} />
    </BaseIcon>
  );
}

export function DeleteIcon(props: IconProps) {
  const iconPath =
    "M19 6.4L17.6 5 12 10.6 6.4 5 5 6.4l5.6 5.6L5 17.6 6.4 19l5.6-5.6 5.6 5.6 1.4-1.4-5.6-5.6z";
  return (
    <BaseIcon {...props}>
      <path d={iconPath} />
    </BaseIcon>
  );
}

export function PlusIcon(props: IconProps) {
  const iconPath = "M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z";
  return (
    <BaseIcon {...props}>
      <path d={iconPath} />
    </BaseIcon>
  );
}

export function UpIcon(props: IconProps) {
  const iconPath = "M7.4 15.4l4.6-4.6 4.6 4.6L18 14l-6-6-6 6z";
  return (
    <BaseIcon {...props}>
      <path d={iconPath} />
    </BaseIcon>
  );
}

export function DownIcon(props: IconProps) {
  const iconPath = "M7.4 7.8l4.6 4.6 4.6-4.6L18 9.3l-6 6-6-6z";
  return (
    <BaseIcon {...props}>
      <path d={iconPath} />
    </BaseIcon>
  );
}

export function RightIcon(props: IconProps) {
  const iconPath = "M10 6L8.6 7.4l4.6 4.6-4.6 4.6L10 18l6-6z";
  return (
    <BaseIcon {...props}>
      <path d={iconPath} />
    </BaseIcon>
  );
}

export function LeftIcon(props: IconProps) {
  const iconPath = "M15.4 7.4L14 6l-6 6 6 6 1.4-1.4-4.6-4.6z";
  return (
    <BaseIcon {...props}>
      <path d={iconPath} />
    </BaseIcon>
  );
}

export function LinkIcon(props: IconProps) {
  const iconPath =
    "M19 19H5V5h7V3H5C4 3 3 4 3 5v14c0 1 1 2 2 2h14c" +
    "1 0 2-1 2-2v-7h-2v7zM14 3v2h3.6l-9.8 9.8 1.4 1.4L19 6.4V10h2V3h-7z";
  return (
    <BaseIcon {...props}>
      <path d={iconPath} />
    </BaseIcon>
  );
}

export function MenuIcon(props: IconProps) {
  return (
    <BaseIcon {...props}>
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
    </BaseIcon>
  );
}

export function EditIcon(props: IconProps) {
  const path2d =
    "M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39" +
    "-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z";

  return (
    <BaseIcon {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d={path2d} />
    </BaseIcon>
  );
}

export function SaveIcon(props: IconProps) {
  const path2d =
    "M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-." +
    "9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-" +
    "3 3zm3-10H5V5h10v4z";

  return (
    <BaseIcon {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d={path2d} />
    </BaseIcon>
  );
}

export function FilesIcon(props: IconProps) {
  const path2d =
    "M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4l6 6v10c0 1.1-.9 2-2 2H7." +
    "99C6.89 23 6 22.1 6 21l.01-14c0-1.1.89-2 1.99-2h7zm-1 7h5.5L14 6.5V12z";

  return (
    <BaseIcon {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d={path2d} />
    </BaseIcon>
  );
}

export function FileIcon(props: IconProps) {
  const path2d =
    "M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2" +
    "H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z";

  return (
    <BaseIcon {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d={path2d} />
    </BaseIcon>
  );
}

export function ImageIcon(props: IconProps) {
  const path2d =
    "M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2" +
    "h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z";

  return (
    <BaseIcon {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d={path2d} />
    </BaseIcon>
  );
}
