import * as React from "react";
import styled from "styled-components";

import { SearchIcon, DeleteIcon } from "../../reactSvgIcons";
import { Button } from "../components/formComponents/button";
import { Textfield } from "../components/formComponents/textfield";
import { FilterRulesEnum } from "./constants";
import { setFilterRule, setFilterList } from "./overview";
import { FilterValue } from "./filterFunctions";

const StyledDiv = styled.div`
  position: fixed;
  top: 56px;
  display: flex;
  flex-direction: row;
  width: 100vw;
  max-width: 900px;
  box-sizing: border-box;
  padding-right: 25px;
  background-color: #fff;
  z-index: 2;
`;

const StyledSearchIcon = styled(SearchIcon)`
  height: 25px;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  height: 25px;
`;

interface SearchBarProps {
  value: string;
  filterList: { [key: string]: FilterValue };
}

export class SearchBar extends React.Component<SearchBarProps, { visibleValue: string }> {
  inputEl: React.RefObject<HTMLInputElement>;
  prevSearch: string;

  constructor(props: SearchBarProps) {
    super(props);
    this.inputEl = React.createRef();
    this.state = { visibleValue: props.value };
    this.prevSearch = "";
  }

  componentDidMount() {
    this.inputEl.current.focus();
  }

  searchSpecifityCheck = (currValue: string): boolean => {
    let tmp = this.prevSearch;
    this.prevSearch = currValue;
    if (currValue === tmp) return true;
    else if (currValue.trim() === "") return false;
    else if (currValue.includes(tmp)) return true;
    else return false;
  };

  searchKeyInput = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === "Enter") {
      setFilterRule(
        FilterRulesEnum.search,
        this.state.visibleValue,
        this.searchSpecifityCheck(this.state.visibleValue)
      );
    }
  };

  handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ visibleValue: evt.target.value });
  };

  searchButtonClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    setFilterRule(
      FilterRulesEnum.search,
      this.state.visibleValue,
      this.searchSpecifityCheck(this.state.visibleValue)
    );
  };

  cancelFilter = () => {
    this.setState({ visibleValue: "" });
    setFilterList({
      [FilterRulesEnum.filterbasis]: this.props.filterList[FilterRulesEnum.filterbasis],
    });
  };

  render() {
    return (
      <StyledDiv className="topSearchBar">
        <Textfield
          style={{ flex: "1 1 auto" }}
          inputProps={{
            value: this.state.visibleValue,
            onChange: this.handleChange,
            onKeyUp: this.searchKeyInput,
            ref: this.inputEl,
            placeholder: "Suche...",
          }}
        />
        <Button className="search-filter-btn" onClick={this.searchButtonClick}>
          <StyledSearchIcon />
        </Button>
        <Button className="deleteFilterBtn" onClick={this.cancelFilter}>
          <StyledDeleteIcon />
        </Button>
      </StyledDiv>
    );
  }
}
