import { Auftrag, Auto } from "../../../../globalData/appInterfaces";
import { FilterRulesEnum, FilterbasisEnum } from "./constants";
import { getKeySearchString } from "../../../../globalData/helperFunctions";

interface carSearchItem {
  item: Auto;
  itemSearchKey: string;
}

interface orderSearchItem {
  item: Auftrag;
  itemSearchKey: string;
}

export interface carHitObject {
  searchItem: carSearchItem;
  itemHit: boolean;
}

export interface orderHitObject {
  searchItem: orderSearchItem;
  itemHit: boolean;
}

export type FilterValue = string | boolean | { start: string; end: string };

let searchCarArr: carSearchItem[];
let searchOrderArr: orderSearchItem[];
let orderHitObjectArr: orderHitObject[];
let carHitObjectArr: carHitObject[];

const stringSearch = function stringSearch(query: string, searchString: string): boolean {
  let search = query.toLocaleLowerCase().split(" ");
  return search.every((subString) => {
    return searchString.includes(subString);
  });
};

const getCarResultArr = function getCarResultArr(
  argSearchArr: carSearchItem[],
  filterArr: { key: FilterRulesEnum; value: FilterValue }[]
) {
  let resultArr: carHitObject[] = [];
  let tmp: carHitObject;

  argSearchArr.forEach(function checkItem(searchItem) {
    // get a temporary hitElement in order to know, what item actually matched the query (e.g. which invoice)
    tmp = {
      searchItem,
      itemHit: true,
    };

    // every filter must be matched. Otherwise the item is not added to the hitList
    let filterResult = filterArr.every((filter) => {
      // subBlnTmp summarizes the hits of the suborders in order to asses if the whole order should be shown
      let subBlnTmp: boolean;
      switch (filter.key) {
        case FilterRulesEnum.filterbasis:
          if (filter.value !== FilterbasisEnum.cars)
            console.warn("Searching cars, while order are selected");
          return true;
        case FilterRulesEnum.search:
          if (typeof filter.value !== "string") {
            console.warn("Passed a non-string to the search-filter", filter.value);
            return true;
          } else if (tmp.itemHit)
            tmp.itemHit = stringSearch(filter.value, searchItem.itemSearchKey);
          let filterVal = <string>filter.value;
          // if a match was found in the order, the suborders do not matter for the result
          if (tmp.itemHit) return true;
        case FilterRulesEnum.owner:
          if (typeof filter.value !== "string") {
            console.warn("Passed a non-string to the owner-filter", filter.value);
          } else {
            if (tmp.itemHit) tmp.itemHit = tmp.searchItem.item.kunden.includes(filter.value);
          }
          return tmp.itemHit;
        case FilterRulesEnum.tuevDate:
        case FilterRulesEnum.timingBeltDate:
        case FilterRulesEnum.oilDate:
          if (typeof filter.value !== "object" || !filter.value.end || !filter.value.start) {
            console.warn("Passed a false value to a carDate-filter", filter.value);
            return true;
          } else {
            if (tmp.itemHit) {
              let invDate: Date;
              if (filter.key === FilterRulesEnum.tuevDate)
                invDate = new Date(searchItem.item.tuev);
              else if (filter.key === FilterRulesEnum.timingBeltDate)
                invDate = new Date(searchItem.item.zahnriemenDatum);
              else if (filter.key === FilterRulesEnum.oilDate)
                invDate = new Date(searchItem.item.oelwechselDatum);
              let start = new Date(filter.value.start);
              let end = new Date(filter.value.end);

              tmp.itemHit = invDate >= start && invDate <= end;
            }

            return tmp.itemHit;
          }
        default:
          // unknown filters have no effect
          console.warn("unknown filter: ", filter);
          break;
      }

      return true;
    });

    if (filterResult) resultArr.push(tmp);
  });

  return resultArr;
};

const getOrderResultArr = function getOrderResultArr(
  argSearchArr: orderSearchItem[],
  filterArr: { key: FilterRulesEnum; value: FilterValue }[]
) {
  let resultArr: orderHitObject[] = [];
  let tmp: orderHitObject;

  argSearchArr.forEach(function checkItem(searchItem) {
    // get a temporary hitElement in order to know, what item actually matched the query (e.g. which invoice)
    tmp = {
      searchItem,
      itemHit: true,
    };

    // every filter must be matched. Otherwise the item is not added to the hitList
    let filterResult = filterArr.every((filter) => {
      // subBlnTmp summarizes the hits of the suborders in order to asses if the whole order should be shown
      let subBlnTmp: boolean;
      switch (filter.key) {
        case FilterRulesEnum.filterbasis:
          if (filter.value !== FilterbasisEnum.orders)
            console.warn("Searching orders, while cars are selected");
          return true;
        case FilterRulesEnum.search:
          if (typeof filter.value !== "string") {
            console.warn("Passed a non-string to the search-filter", filter.value);
            return true;
          } else if (tmp.itemHit)
            tmp.itemHit = stringSearch(filter.value, searchItem.itemSearchKey);
          let filterVal = <string>filter.value;
          // if a match was found in the order, the suborders do not matter for the result
          if (tmp.itemHit) return true;
        case FilterRulesEnum.client:
          if (tmp.itemHit) tmp.itemHit = tmp.searchItem.item.kunde === filter.value;
          return tmp.itemHit;
        case FilterRulesEnum.orderTitle:
          if (typeof filter.value !== "string") {
            console.warn("Passed a non-string to the article-filter", filter.value);
            return true;
          } else if (tmp.itemHit)
            tmp.itemHit = tmp.searchItem.item.titel
              .toLocaleLowerCase()
              .includes(filter.value.toLocaleLowerCase());
          return tmp.itemHit;
        case FilterRulesEnum.article:
          if (typeof filter.value !== "string") {
            console.warn("Passed a non-string to the article-filter", filter.value);
            return true;
          } else {
            subBlnTmp = false;
            let searchArticle = window.data.artikel[filter.value];
            if (tmp.itemHit)
              tmp.itemHit = searchItem.item.positionen.some((pos) => {
                if ("ueberschrift" in pos) return false;
                else return pos.artikelNr === searchArticle.nr;
              });
            return tmp.itemHit;
          }
        case FilterRulesEnum.orderStatus:
          if (tmp.itemHit) tmp.itemHit = tmp.searchItem.item.status === filter.value;
          return tmp.itemHit;
        case FilterRulesEnum.invDate:
        case FilterRulesEnum.invDueDate:
          if (typeof filter.value !== "object" || !filter.value.end || !filter.value.start) {
            console.warn("Passed a false value to the invDate/invDueDate-filter", filter.value);
            return true;
          } else {
            if (tmp.itemHit) {
              let invDate: Date;
              if (filter.key === FilterRulesEnum.invDate)
                invDate = new Date(searchItem.item.datum);
              else if (filter.key === FilterRulesEnum.invDueDate)
                invDate = new Date(searchItem.item.zahlungsziel);
              let start = new Date(filter.value.start);
              let end = new Date(filter.value.end);

              tmp.itemHit = invDate >= start && invDate <= end;
            }

            return tmp.itemHit;
          }
        default:
          // unknown filters have no effect
          console.warn("unknown filter: ", filter);
          break;
      }

      return true;
    });

    if (filterResult) resultArr.push(tmp);
  });

  return resultArr;
};

const getCarBasis = function getCarBasis() {
  return Object.entries(window.data.auto).map((car) => {
    return {
      item: car[1],
      itemSearchKey: getKeySearchString({
        ...car[1],
        ...car[1].kunden.map((kunde) => window.data.kunde[kunde]),
      }),
    };
  });
};

const getOrderBasis = function getOrderBasis() {
  return Object.entries(window.data.auftrag).map((order) => {
    return {
      item: order[1],
      itemSearchKey: getKeySearchString({ ...order[1], kunde: window.data.kunde[order[1].kunde] }),
    };
  });
};

export const getFilteredOrders = function getFilteredOrders(
  blnSpecify: boolean,
  filterArr: { key: FilterRulesEnum; value: FilterValue }[]
) {
  let searchArr =
    blnSpecify && orderHitObjectArr !== undefined
      ? orderHitObjectArr.map((item) => item.searchItem)
      : searchOrderArr;
  orderHitObjectArr = getOrderResultArr(searchArr, filterArr);
  return orderHitObjectArr;
};

export const getFilteredCars = function getFilteredCars(
  blnSpecify: boolean,
  filterArr: { key: FilterRulesEnum; value: FilterValue }[]
) {
  if (searchCarArr === undefined || searchCarArr === null) searchCarArr = getCarBasis();
  let searchArr =
    blnSpecify && carHitObjectArr !== undefined
      ? carHitObjectArr.map((item) => item.searchItem)
      : searchCarArr;
  carHitObjectArr = getCarResultArr(searchArr, filterArr);
  return carHitObjectArr;
};

export const filterInit = function filterInit() {
  searchOrderArr = getOrderBasis();
};
