import { subscribe, publish, pubSubTopic } from "../../pubSub";
import { navDestination } from "../navigation/navConstants";

var sideNavContainer: HTMLElement;

function showSideNav() {
  sideNavContainer.classList.add("visible");
}

function hideSideNav() {
  sideNavContainer.classList.remove("visible");
}

export function sideNavInit() {
  sideNavContainer = document.querySelector(".side-nav-container");

  document.querySelector(".open-nav").addEventListener("click", showSideNav);
  sideNavContainer.addEventListener("click", hideSideNav);

  subscribe(pubSubTopic.sideNavOpen, showSideNav);
  subscribe(pubSubTopic.pageLoaded, fillSideNav);
}

export function fillSideNav() {
  var navContent = document.getElementById("side-nav-content");
  navContent.innerHTML = "";
  var navButtons = document.getElementById("side-nav-buttons");
  navButtons.parentNode.removeChild(navButtons);
  navButtons.appendChild(sideNavButtons());
  navContent.parentNode.appendChild(navButtons);
  navContent.parentNode.removeChild(navContent);
  navButtons.id = "side-nav-content";
  navButtons = navContent = null;
}

function sideNavButtons() {
  var settingsBtn = document.createElement("BUTTON");
  settingsBtn.className = "sf-mdl-button ripple";
  settingsBtn.innerText = "Einstellungen";
  settingsBtn.addEventListener("click", function() {
    publish(pubSubTopic.pageNavInitiated, {
      topic: pubSubTopic.pageNavInitiated,
      destination: navDestination.settings
    });
  });

  var logoutBtn = document.createElement("BUTTON");
  logoutBtn.className = "sf-mdl-button ripple";
  logoutBtn.innerText = "Ausloggen";
  logoutBtn.addEventListener("click", function() {
    publish(pubSubTopic.logoutInit);
  });

  var div = document.createElement("DIV");
  div.appendChild(settingsBtn);
  div.appendChild(logoutBtn);
  div.id = "perma-button-div";
  return div;
}
