import * as React from "react";
import styled from "styled-components";

import { Label } from "./label";

const DatepickerContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 20px 0;

  input {
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 14px;
    margin: 0;
    padding: 3px 0 1px 0;
    width: 145px;

    &:focus {
      outline: none;
      border-bottom-color: rgb(96, 125, 139);
      border-bottom-width: 2px;
    }
  }
`;

interface DatePickerInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  "data-drinput": string;
}

interface DatepickerProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
  inputProps?: DatePickerInputProps;
}

export function Datepicker(props: DatepickerProps) {
  const { label, inputProps, ...containerProps } = props;

  return (
    <DatepickerContainer {...containerProps}>
      <input {...inputProps} type="date" />
      {label ? <Label>{label}</Label> : null}
    </DatepickerContainer>
  );
}
