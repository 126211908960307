import * as React from "react";
import styled from "styled-components";
import { DeleteIcon } from "../../reactSvgIcons";
import { white2, white5 } from "../../styleConstants";
import { FilterRulesEnum, FilterbasisEnum } from "./constants";
import { deleteFilterRule, setFilterRule, setFilterList } from "./overview";
import { ReactDatalist } from "../components/formComponents/datalist";
import { Datepicker } from "../components/formComponents/datePicker";
import { Select } from "../components/formComponents/select";
import { Label } from "../components/formComponents/label";
import { showToast } from "../components/toast";
import { debounce, getDateForDOM, dateAddDays } from "../../../../globalData/helperFunctions";
import { FilterValue } from "./filterFunctions";

interface DatalistFilterProps {
  filterRule: FilterRulesEnum;
  options: string[];
  maxOptions?: number;
  label?: string;
  tooltipFct?: (value: string) => string;
  inputWidth?: string;
}

export class DatalistFilter extends React.Component<DatalistFilterProps> {
  prevValue: string;

  searchSpecifityCheck = (currValue: string): boolean => {
    let tmp = this.prevValue;
    this.prevValue = currValue;
    if (currValue === tmp) return true;
    else if (currValue.trim() === "") return false;
    else if (currValue.includes(tmp)) return true;
    else return false;
  };

  updateFilter = debounce((event: React.ChangeEvent<HTMLInputElement> | string) => {
    let value: string;
    if (typeof event === "string") value = event;
    else value = event.target.value;

    setFilterRule(this.props.filterRule, value, this.searchSpecifityCheck(value));
  }, 300);

  render() {
    let style: React.CSSProperties = {};
    if (typeof this.props.inputWidth === "string") style.width = this.props.inputWidth;
    return (
      <ReactDatalist
        list={this.props.filterRule}
        label={this.props.label}
        maxOptions={this.props.maxOptions}
        inputStyle={style}
        options={this.props.options}
        onInputChange={this.updateFilter}
        onOptionSelected={this.updateFilter}
        tooltipFct={this.props.tooltipFct}
      />
    );
  }
}

const PreselectStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${white2};
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 5px;

  & button {
    border: none;
    height: 25px;
    padding: 0;
    padding-left: 5px;
    margin-left: 5px;
    background-color: inherit;

    &:hover {
      background-color: ${white5};
    }
  }
  & svg {
    height: 25px;
  }
`;

export const PreselectFilter = function PreselectFilter(props: {
  displayVal: string;
  filterRule: FilterRulesEnum;
  tooltip?: string;
}) {
  const deleteHandler = () => {
    deleteFilterRule(props.filterRule);
  };

  return (
    <PreselectStyle title={props.tooltip}>
      {props.displayVal}
      <button onClick={deleteHandler}>
        <DeleteIcon />
      </button>
    </PreselectStyle>
  );
};

const StyledDateRange = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;

    input {
      flex-grow: 0;
    }
    span.drFilterFirst {
      margin-right: 15px;
      flex-grow: 0;
    }
    span.drFilterSec {
      margin: 0 15px;
      flex-grow: 1;
    }
  }
`;

interface DaterangeFilterProps {
  filterRule: FilterRulesEnum;
  label?: string;
}

interface DaterangeFilterState {
  start: string;
  end: string;
  prevStart: string;
  prevEnd: string;
}

export class DaterangeFilter extends React.Component<DaterangeFilterProps, DaterangeFilterState> {
  private static minStart = "1900-01-01";
  private static maxEnd = "2100-12-31";

  constructor(props: DaterangeFilterProps) {
    super(props);

    this.state = {
      start: getDateForDOM(dateAddDays(new Date(), -7)),
      end: getDateForDOM(new Date()),
      prevStart: DaterangeFilter.minStart,
      prevEnd: DaterangeFilter.maxEnd,
    };
    this.updateFilter();
  }

  onChangeFirst = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let targetDate = new Date(evt.target.value);
    let endDate = new Date(this.state.end);

    if (targetDate > endDate) showToast("Startdatum darf nicht vor dem Enddatum liegen.");
    else {
      this.setState(
        {
          start: evt.target.value,
          end: this.state.end,
          prevStart: this.state.start,
          prevEnd: this.state.end,
        },
        this.updateFilter
      );
    }
  };

  onChangeSec = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let targetDate = new Date(evt.target.value);
    let startDate = new Date(this.state.start);

    if (targetDate < startDate) showToast("Enddatum darf nicht vor dem Startdatum liegen.");
    else {
      this.setState(
        {
          start: this.state.start,
          end: evt.target.value,
          prevStart: this.state.start,
          prevEnd: this.state.end,
        },
        this.updateFilter
      );
    }
  };

  updateFilter = () => {
    if (this.state.start === "" && this.state.end === "") deleteFilterRule(this.props.filterRule);
    else
      setFilterRule(
        this.props.filterRule,
        {
          start: this.state.start || DaterangeFilter.minStart,
          end: this.state.end || DaterangeFilter.maxEnd,
        },
        this.specifityCheck()
      );
  };

  specifityCheck = () => {
    if (this.state.start === this.state.prevStart) {
      return new Date(this.state.end) < new Date(this.state.prevEnd);
    } else if (this.state.end === this.state.prevEnd) {
      return new Date(this.state.start) > new Date(this.state.prevStart);
    }
  };

  render() {
    return (
      <StyledDateRange data-filterkey={this.props.filterRule}>
        {this.props.label ? <Label>{this.props.label}</Label> : null}
        <div>
          <span className="drFilterFirst">Von</span>
          <Datepicker
            className="first-input"
            inputProps={{
              value: this.state.start,
              onChange: this.onChangeFirst,
              "data-drinput": "first",
            }}
          />
          <span className="drFilterSec">bis</span>
          <Datepicker
            className="second-input"
            inputProps={{
              value: this.state.end,
              onChange: this.onChangeSec,
              "data-drinput": "sec",
            }}
          />
        </div>
      </StyledDateRange>
    );
  }
}

export class FilterbasisFilter extends React.Component {
  updateFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let filterList: { [key: string]: FilterValue } = {};
    filterList[FilterRulesEnum.filterbasis] = event.target.value;
    setFilterList(filterList, false);
  };

  render() {
    return (
      <Select
        options={[{ value: FilterbasisEnum.orders }, { value: FilterbasisEnum.cars }]}
        label={FilterRulesEnum.filterbasis}
        selectProps={{ onChange: this.updateFilter, className: "select-filter" }}
      />
    );
  }
}
