import { linkIcon, deleteIcon } from "../../svgIcons";

export function getViewLink() {
  var newEl = document.createElement("BUTTON");
  newEl.className = "sf-mdl-button data-link";
  newEl.innerHTML = `<svg class="clickable" width="24px" ${linkIcon.viewBox}>${
    linkIcon.path
  }</svg>`;

  return newEl;
}

export function getEditLink() {
  var newEl = document.createElement("BUTTON");
  newEl.className = "sf-mdl-button data-link";
  newEl.innerHTML = `<svg class="clickable" width="24px" ${deleteIcon.viewBox}>${
    deleteIcon.path
  }</svg>`;

  return newEl;
}
