import * as React from "react";
import styled from "styled-components";
import { primaryColor } from "../../styleConstants";

const StyledDiv = styled.div`
  margin: ${({ margin }: SpinnerProps) => margin};
  width: ${({ size }: SpinnerProps) => Math.floor(size / 2) + "px"};
  height: ${({ size }: SpinnerProps) => size + "px"};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .progress {
    font-size: ${({ size }: SpinnerProps) => size * 0.375 + "px"};
  }

  .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &:before {
      content: "";
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: ${primaryColor};
      border-radius: 100%;
      animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    }
  }

  .sk-circle2 {
    transform: rotate(30deg);
    &:before {
      animation-delay: -1.1s;
    }
  }

  .sk-circle3 {
    transform: rotate(60deg);
    &:before {
      animation-delay: -1s;
    }
  }

  .sk-circle4 {
    transform: rotate(90deg);
    &:before {
      animation-delay: -0.9s;
    }
  }

  .sk-circle5 {
    transform: rotate(120deg);
    &:before {
      animation-delay: -0.8s;
    }
  }

  .sk-circle6 {
    transform: rotate(150deg);
    &:before {
      animation-delay: -0.7s;
    }
  }

  .sk-circle7 {
    transform: rotate(180deg);
    &:before {
      animation-delay: -0.6s;
    }
  }

  .sk-circle8 {
    transform: rotate(210deg);
    &:before {
      animation-delay: -0.5s;
    }
  }

  .sk-circle9 {
    transform: rotate(240deg);
    &:before {
      animation-delay: -0.4s;
    }
  }

  .sk-circle10 {
    transform: rotate(270deg);
    &:before {
      animation-delay: -0.3s;
    }
  }

  .sk-circle11 {
    transform: rotate(300deg);
    &:before {
      animation-delay: -0.2s;
    }
  }

  .sk-circle12 {
    transform: rotate(330deg);
    &:before {
      animation-delay: -0.1s;
    }
  }

  @keyframes sk-circleFadeDelay {
    0% {
      opacity: 0;
    }

    39% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }

    40% {
      opacity: 1;
    }
  }
`;

interface SpinnerProps {
  progress?: string;
  size?: number;
  margin?: string;
  inline?: boolean;
}

export class Spinner extends React.PureComponent<SpinnerProps> {
  render() {
    const { progress, size = 80, margin = "100px auto", inline = false } = this.props;
    const styleObj = {
      margin: margin,
      display: inline ? "inline-flex" : "flex"
    };
    return (
      <StyledDiv size={size} margin={margin} style={styleObj} id="loading-spinner">
        {new Array(12).fill(0).map((_, i) => {
          return <div className={"sk-circle" + (i + 1) + " sk-circle"} key={i} />;
        })}
        {!progress ? null : <div className="progress">{progress}</div>}
      </StyledDiv>
    );
  }
}
