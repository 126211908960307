import * as React from "react";
import styled from "styled-components";

import { LinkIcon, RightIcon } from "../../reactSvgIcons";
import { Button } from "../components/formComponents/button";

import { getClientAsString } from "../clientPage/clientHelpers";
import { orderHitObject, carHitObject } from "./filterFunctions";
import { publish, pubSubTopic } from "../../pubSub";
import { navDestination } from "../navigation/navConstants";
import { Auftrag, Dokument } from "../../../../globalData/appInterfaces";
import { primaryColor } from "../../styleConstants";
import { strToDate } from "../../../../globalData/helperFunctions";
import { FilterbasisEnum } from "./constants";

interface subHitProps {
  orderDoc: Dokument;
  order: Auftrag;
}

const StyledSubHitElDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
`;

const StyledRightIcon = styled(RightIcon)`
  height: 24px;
  margin-right: 7px;
`;

const SubHitEl = function (props: subHitProps) {
  const clickHandler = () => {
    publish(pubSubTopic.pageNavInitiated, {
      topic: pubSubTopic.pageNavInitiated,
      destination: navDestination.singleDocument,
      destObject: {
        element: props.orderDoc.nummer,
      },
    });
  };

  return (
    <StyledSubHitElDiv
      className="hitSubitem"
      data-key={props.orderDoc.nummer}
      onClick={clickHandler}
    >
      <StyledRightIcon />
      <div>
        {"Rechnung: " + props.orderDoc.nummer}
        <span style={{ whiteSpace: "nowrap" }}>{` Speicherdatum: ${strToDate(
          props.orderDoc.speicherdatum
        )}`}</span>
      </div>
    </StyledSubHitElDiv>
  );
};

const StyledHitElDiv = styled.div`
  border-bottom: 1px solid ${primaryColor};
  margin-top: 10px;

  & .top-row {
    display: flex;
    flex-direction: row;
    font-size: 1.05em;
    margin-bottom: 5px;

    & > span {
      flex: 1 1 auto;
    }

    & > button {
      height: 30px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

interface hitElProps {
  filterType: FilterbasisEnum;
  filterHit: carHitObject | orderHitObject;
}

export const HitEl = function HitEl(props: hitElProps) {
  if (props.filterType === FilterbasisEnum.cars) {
    return <CarHitEl filterHit={props.filterHit as carHitObject} />;
  } else return <OrderHitEl filterHit={props.filterHit as orderHitObject} />;
};

class OrderHitEl extends React.Component<{ filterHit: orderHitObject }> {
  clickHandler = () => {
    publish(pubSubTopic.pageNavInitiated, {
      topic: pubSubTopic.pageNavInitiated,
      destination: navDestination.order,
      destObject: { element: this.props.filterHit.searchItem.item.auftragsNr },
    });
  };

  render() {
    let order = this.props.filterHit.searchItem.item;
    let tmpClient = window.data.kunde[order.kunde];

    return (
      <StyledHitElDiv className="filterHit">
        <div className="top-row" data-key={order.auftragsNr}>
          <span>
            {`Auftrag: ${order.titel} - `}
            <span className="nowrap">{`Kunde: ${getClientAsString(tmpClient)}`}</span>
          </span>
          <Button className="hitBtn" onClick={this.clickHandler}>
            <LinkIcon style={{ height: "24px" }} />
          </Button>
        </div>
        {order.dokumente.map((key) => (
          <SubHitEl key={key} orderDoc={window.data.dokument[key]} order={order} />
        ))}
      </StyledHitElDiv>
    );
  }
}

class CarHitEl extends React.Component<{ filterHit: carHitObject }> {
  clickHandler = () => {
    publish(pubSubTopic.pageNavInitiated, {
      topic: pubSubTopic.pageNavInitiated,
      destination: navDestination.car,
      destObject: { element: this.props.filterHit.searchItem.item.autoNr },
    });
  };

  render() {
    let car = this.props.filterHit.searchItem.item;
    let clients = car.kunden.map((kundenNr) => getClientAsString(window.data.kunde[kundenNr]));

    return (
      <StyledHitElDiv className="filterHit">
        <div className="top-row" data-key={car.autoNr}>
          <span>
            Auto: {car.hersteller + " - " + car.modell} - Kennzeichen: {car.kennzeichen} - Kunden:{" "}
            {clients.join(", ")}
          </span>
          <Button className="hitBtn" onClick={this.clickHandler}>
            <LinkIcon style={{ height: "24px" }} />
          </Button>
        </div>
      </StyledHitElDiv>
    );
  }
}
