/** getVatRate returns the correct full German VAT rate (e.g. 0.19) depending on the date */
export function getVatRate(isoDate: string): number {
  if (isoDate === "") {
    return 0.19;
  }

  const realDate = new Date(isoDate);
  const start16VAT = new Date("2020-07-01");
  const end16VAT = new Date("2020-12-31");

  if (realDate >= start16VAT && realDate <= end16VAT) {
    return 0.16;
  } else {
    return 0.19;
  }
}

/** This function is for compile time checking of completeness of if statements
 * It needs to be passed the parameter, that has been checked exhaustively
 */
export function neverFunction(x?: never): never {
  throw new Error("This function should not have been called.");
}

export function debounce(func: Function, wait: number): any {
  let timeout: any;

  if (!Number.isInteger(wait)) {
    console.warn("Called debounce without a valid number");
    wait = 300;
  }

  return function (this: any) {
    var context: any = <any>this;
    var args = arguments;

    clearTimeout(timeout);

    timeout = setTimeout(function () {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

export function prettyByteSize(bytes: number) {
  var byteSize;
  var unit;

  if (bytes > 1024 * 1024 * 1024) {
    byteSize = Math.floor((100 * bytes) / (1024 * 1024 * 1024)) / 100;
    unit = " GB";
  } else if (bytes > 1024 * 1024) {
    byteSize = Math.floor((100 * bytes) / (1024 * 1024)) / 100;
    unit = " MB";
  } else if (bytes > 1024) {
    byteSize = Math.floor((100 * bytes) / 1024) / 100;
    unit = " kB";
  } else {
    byteSize = Math.floor(100 * bytes) / 100;
    unit = " Byte";
  }

  return byteSize.toLocaleString() + unit;
}

export function validEMail(strEMail: string) {
  var emailRegExp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
  return emailRegExp.test(strEMail);
}

export function objLength(obj: Object) {
  var temp = 0;

  for (var key in obj) {
    if (obj.hasOwnProperty(key)) temp++;
  }

  return temp;
}

export function objForEach<O extends Object>(obj: O, callback: (item: O[keyof O], key?: keyof O, obj?: O) => void) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) callback(obj[key], key, obj);
  }
}

export function objToArray<O extends Object>(obj: O): Array<O[keyof O]> {
  let temp: Array<O[keyof O]> = [];

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) temp.push(obj[key]);
  }

  return temp;
}

export function getUniqueArray<T>(array: Array<T>) {
  return array.filter(function (item, i, ar) {
    return ar.indexOf(item) === i;
  });
}

export function round(number: number, precision: number): number {
  // taken from mdn - no idea how this works
  var factor = Math.pow(10, precision);
  var tempNumber = number * factor;
  var roundedTempNumber = Math.round(tempNumber);
  return roundedTempNumber / factor;
}

/**
 * The function checks if the specified thousand separator is logically spaced by threes
 * @param strNum :Numeric string, that is checked
 */
export function _validThousandSep(strNum: string, sep: "." | ","): boolean {
  // export function only for testing purposes
  let dec: "." | "," = ",";

  if (sep === ".") dec = ",";
  else if (sep === ",") dec = ".";
  else neverFunction(sep);

  // separate string into blocks and remove decimals
  let partArr = strNum.split(dec)[0].split(sep);
  return partArr.every((item, i) => {
    if (i === 0) return item.length <= 3;
    else return item.length === 3;
  });
}
/**
 * The function takes a numeric string and returns the number or null (if it cannot be parsed)
 * @param strNum numeric string
 * @param precision optional precision of output
 */
export function strToFloat(strNum: string, precision?: number): number | null {
  let tmpStr = strNum.trim();
  // empty case is zero
  if (tmpStr.length === 0) return 0;
  // if the string contains anything but dots, commas or digits it is not a number
  if (/[^\d\,\.]/gi.test(tmpStr)) return null;

  let tempNum: number;
  const dotOccurrence = (tmpStr.match(/\./g) || []).length;
  const commaOccurrence = (tmpStr.match(/\,/g) || []).length;
  const lastDot = tmpStr.lastIndexOf(".");
  const lastComma = tmpStr.lastIndexOf(",");

  // if both commas and dots occur more than once it is not a valid number
  if (dotOccurrence > 1 && commaOccurrence > 1) return null;
  // these are the easy cases (no decimals or no thousand separation)
  // Important - This is an assumption (This is an ambiguous case):
  // If only one dot or comma is present we assume the german system (. for thousand - , for dec)
  else if (dotOccurrence === 0 && commaOccurrence === 0) tempNum = parseFloat(tmpStr);
  else if (dotOccurrence === 1 && commaOccurrence === 0 && _validThousandSep(tmpStr, ".")) {
    tempNum = parseFloat(tmpStr.replace(/\./g, ""));
    // dot could not be a valid thousand separator and is used as a decimal now
  } else if (dotOccurrence === 1 && commaOccurrence === 0) tempNum = parseFloat(tmpStr);
  else if (commaOccurrence === 1 && dotOccurrence === 0) tempNum = parseFloat(tmpStr.replace(/\,/g, "."));
  // handle cases with one comma and one dot
  else if (commaOccurrence === 1 && dotOccurrence === 1 && lastDot > lastComma && _validThousandSep(tmpStr, "."))
    tempNum = parseFloat(tmpStr.replace(/\,/g, ""));
  else if (commaOccurrence === 1 && dotOccurrence === 1 && lastComma > lastDot && _validThousandSep(tmpStr, ","))
    tempNum = parseFloat(tmpStr.replace(/\./g, "").replace(/\,/g, "."));
  // these cases filter out illogical decimal positions (e.g.: 34,324.342,23)
  else if (commaOccurrence >= dotOccurrence && dotOccurrence > 0 && lastDot < lastComma) return null;
  else if (dotOccurrence >= commaOccurrence && commaOccurrence > 0 && lastComma < lastDot) return null;
  // if everything is in order remove thousand separators and convert to number
  else if (dotOccurrence === 0 && commaOccurrence > 1 && _validThousandSep(tmpStr, ",")) {
    tempNum = parseFloat(tmpStr.replace(/\,/g, ""));
  } else if (commaOccurrence === 0 && dotOccurrence > 1 && _validThousandSep(tmpStr, ".")) {
    tempNum = parseFloat(tmpStr.replace(/\./g, ""));
  } else return null;

  if (typeof precision === "number") return round(tempNum, precision);
  else return tempNum;
}

export function getDateForDOM(date: Date) {
  var temp = date;
  return temp.getFullYear() + "-" + ("00" + (temp.getMonth() + 1)).slice(-2) + "-" + ("00" + temp.getDate()).slice(-2);
}

export function getKeySearchString(item: any, caseSensitive: boolean = false): string {
  let finalStore: Array<string> = [""];

  let recursiveKeySearch = function recursiveKeySearch(recObj: { [key: string]: any }): string[] {
    let recStore: Array<string> = [""];

    for (let key in recObj) {
      if (recObj.hasOwnProperty(key)) {
        if (typeof recObj[key] === "object") recStore = recStore.concat(recursiveKeySearch(recObj[key]));
        else if (recObj[key]) recStore.push(recObj[key]).toString();
      }
    }

    return recStore;
  };

  if (typeof item === "object") finalStore = recursiveKeySearch(item);
  else if (item) finalStore = [item.toString()];

  if (caseSensitive) return finalStore.join();
  else return finalStore.join().toLowerCase();
}

export const strToDate = function (strDate: string) {
  let tempDate = new Date(strDate);
  return (
    ("00" + tempDate.getDate()).slice(-2) +
    "." +
    ("00" + (tempDate.getMonth() + 1)).slice(-2) +
    "." +
    tempDate.getFullYear()
  );
};

export function numToStr(numVar: number) {
  let parts = numVar.toString().split(".");
  if (parts.length === 2) {
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    parts[1] = parts[1].slice(0, 2);
    return parts.join(",");
  } else return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const isNumeric = function (varParameter: any): boolean {
  return (
    !isNaN(parseFloat(varParameter)) &&
    isFinite(varParameter) &&
    !(typeof varParameter === "string" && varParameter.trim() === "")
  );
};

export const dateAddDays = function (initDate: Date, addDays: number) {
  let newDate = new Date(initDate);
  newDate.setDate(newDate.getDate() + addDays);
  return newDate;
};

export const findFirstParentEl = function findFirstParent(startEl: HTMLElement, argClass?: string, argID?: string) {
  let currEl = startEl;
  let foundFlag = false;

  while (!foundFlag) {
    currEl = <HTMLElement>currEl.parentElement;
    if (argID) foundFlag = currEl.id === argID;
    else if (argClass) foundFlag = currEl.className.split(" ").includes(argClass);
    else {
      console.warn("No query passed to findFirstParentEl");
      foundFlag = true;
    }
  }

  return currEl;
};
