import styled from "styled-components";
import { white2, shadow0 } from "../../../styleConstants";

export const Button = styled.button`
  vertical-align: top;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: ${white2};
  flex-shrink: 0;
  box-shadow: ${shadow0};
  border: none;
  border-radius: 2px;
  min-width: 64px;
  text-transform: uppercase;
  line-height: 36px;
  cursor: pointer;
  padding: 0 16px;
  outline: none;
  margin: 15px 0 16px 15px;
  font-size: 0.75rem;
  font-weight: bold;

  &:focus {
    outline: none
  }
`;

export const IconButton = styled.button`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: ${white2};
  flex-shrink: 0;
  box-shadow: ${shadow0};
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
  line-height: 36px;
  cursor: pointer;
  padding: 5px 10px;
  outline: none;
  margin: 15px;
  font-size: 0.75rem;
  font-weight: bold;

  &:focus {
    outline: none;
  }

  svg {
    pointer-events: none;
  }

  &:disabled svg {
    fill: graytext;
  }
`