import * as React from "react";
import styled from "styled-components";

import { lineCollapse } from "../globalFunctions";
import { UpIcon, DownIcon } from "../../reactSvgIcons";
import { getSelectionDialog } from "../components/dialogs/selectionDialog";

import {
  PreselectFilter,
  DatalistFilter,
  DaterangeFilter,
  FilterbasisFilter,
} from "./subFilterRules";
import {
  FilterRulesEnum,
  orderFilterOptions,
  carFilterOptions,
  FilterbasisEnum,
} from "./constants";
import { getClientAsString, getClientSelectionDialog } from "../clientPage/clientHelpers";
import { setFilterRule } from "./overview";
import { showToast } from "../components/toast";
import { getArticleSelectionDialog } from "../articlePage/articleFunctionality";
import { AuftragStatus } from "../../../../globalData/appInterfaces";
import { FilterValue } from "./filterFunctions";
import { objForEach, objLength } from "../../../../globalData/helperFunctions";

const StyledDiv = styled.div`
  margin-top: 67px;

  & .top-filter-rules-row {
    display: flex;
    flex-direction: row;
    cursor: pointer;

    & span {
      flex: 1 1 auto;
    }

    & svg {
      height: 24px;
    }
  }

  & .filter-rules-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  & .filter-rules-content > div,
  & .filter-rules-content > label {
    margin-right: 20px;
  }
`;

interface state {
  hidden: boolean;
  visibleFilters: FilterRulesEnum[];
}

interface props {
  filterList: { [key: string]: FilterValue };
}

export class FilterRules extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    let visibleFilters: FilterRulesEnum[] = [];
    objForEach(this.props.filterList, (value, key) => {
      visibleFilters.push(key as FilterRulesEnum);
    });

    this.state = {
      hidden: false,
      visibleFilters,
    };
  }

  componentWillReceiveProps(nextProps: props) {
    if (objLength(this.props.filterList) !== objLength(nextProps.filterList)) {
      let visibleFilters: FilterRulesEnum[] = [];
      objForEach(nextProps.filterList, (value, key) => {
        visibleFilters.push(key as FilterRulesEnum);
      });
      this.setState({ hidden: false, visibleFilters });
    }
  }

  toggleRules = () => {
    // might replace this eventually with a HideComponent
    lineCollapse(document.querySelector(".filter-rules-content"), { timing: 400 });
    this.setState({ ...this.state, hidden: !this.state.hidden });
  };

  addRule = () => {
    let tmpFilterOption: FilterRulesEnum[];
    if (this.props.filterList[FilterRulesEnum.filterbasis] === FilterbasisEnum.cars) {
      tmpFilterOption = carFilterOptions.filter((item) => {
        return !this.state.visibleFilters.includes(item);
      }, this);
    } else {
      tmpFilterOption = orderFilterOptions.filter((item) => {
        return !this.state.visibleFilters.includes(item);
      }, this);
    }

    getSelectionDialog(tmpFilterOption, { useSearchAsKey: true }).then((key: FilterRulesEnum) => {
      // For preselected filter the filter is set. Other filter only get their element displayed
      let selectOptions: string[];
      let keyArr: string[];
      if (typeof key === "string") {
        // not cancelled
        switch (key) {
          case FilterRulesEnum.owner:
          case FilterRulesEnum.client:
            getClientSelectionDialog().then((result) => {
              if (result) setFilterRule(key, result.kundenNr, true);
            });
            break;
          case FilterRulesEnum.article:
            getArticleSelectionDialog().then((result) => {
              if (result) setFilterRule(key, result.id, true);
            });
            break;
          case FilterRulesEnum.orderStatus:
            selectOptions = Object.entries(AuftragStatus).map((item) => item[1]);
            getSelectionDialog(selectOptions).then((result) => {
              if (typeof result === "number") setFilterRule(key, selectOptions[result], true);
            });
            break;
          case FilterRulesEnum.tuevDate:
          case FilterRulesEnum.orderTitle:
          case FilterRulesEnum.invDate:
          case FilterRulesEnum.invDueDate:
            this.setState({
              hidden: this.state.hidden,
              visibleFilters: [...this.state.visibleFilters, key],
            });
            break;
          default:
            showToast("Noch in Arbeit", 2000);
            break;
        }
      }
    });
  };

  getFilterElements = () => {
    let elmtArray: React.ReactElement<any>[] = [];

    this.state.visibleFilters.forEach((key: FilterRulesEnum) => {
      switch (key) {
        case FilterRulesEnum.owner:
        case FilterRulesEnum.client:
          let client = window.data.kunde[this.props.filterList[key] as string];
          let clientVal = "Kunde: " + getClientAsString(client);

          elmtArray.push(<PreselectFilter key={key} filterRule={key} displayVal={clientVal} />);
          break;
        case FilterRulesEnum.orderTitle:
          elmtArray.push(
            <DatalistFilter
              key={key}
              filterRule={key}
              label="Auftrag"
              maxOptions={5}
              inputWidth="275px"
              options={Object.values(window.data.auftrag).map((order) => order.titel)}
              tooltipFct={(val: string) => val}
            />
          );
          break;
        case FilterRulesEnum.article:
          let article = window.data.artikel[this.props.filterList[key] as string];
          let tooltip = article.nr + " - " + article.preis + "€ - " + article.bez;
          let articleVal = "Artikel: " + article.nr;

          elmtArray.push(
            <PreselectFilter
              tooltip={tooltip}
              key={key}
              displayVal={articleVal}
              filterRule={key}
            />
          );
          break;
        case FilterRulesEnum.orderStatus:
          elmtArray.push(
            <PreselectFilter
              key={key}
              displayVal={key + ": " + this.props.filterList[key]}
              filterRule={key}
            />
          );
          break;
        case FilterRulesEnum.tuevDate:
        case FilterRulesEnum.timingBeltDate:
        case FilterRulesEnum.oilDate:
        case FilterRulesEnum.invDate:
        case FilterRulesEnum.invDueDate:
          elmtArray.push(<DaterangeFilter key={key} filterRule={key} label={key} />);
          break;
        default:
          break;
      }
    });
    return elmtArray;
  };

  render() {
    return (
      <StyledDiv>
        <div onClick={this.toggleRules} className="top-filter-rules-row">
          <span>Filterregeln: </span>
          {this.state.hidden ? <DownIcon /> : <UpIcon />}
        </div>
        <div className="filter-rules-content">
          <FilterbasisFilter />
          {this.getFilterElements()}
          <button className="sf-mdl-button ripple addRule" onClick={this.addRule}>
            Regel hinzufügen
          </button>
        </div>
      </StyledDiv>
    );
  }
}
