import * as React from "react";
import styled from "styled-components";

import { Button } from "../formComponents/button";
import { DialogContainer, DialogActions } from "../dialogs/genericDialogStyles";
import { Auto } from "../../../../../globalData/appInterfaces";
import { DeleteIcon, EditIcon, SaveIcon, LeftIcon } from "../svgIcons";
import { Textfield } from "../formComponents/textfield";
import { showToast } from "../toast";
import { getConfirmDialog } from "../dialogs/confirmDialog";
import { primaryColor } from "../../../styleConstants";

const StyledLI = styled.li`
  display: flex;

  & > *:first-child {
    flex: 1 1 auto;
    overflow-x: auto;
    border-right: 1px solid ${primaryColor};
    padding-right: 5px;
  }

  & > span:nth-child(2) {
    flex: 0 0 auto;
    padding: 0;

    svg {
      margin: 0 5px;
      cursor: pointer;
    }
  }
`;

interface ItemProps {
  attachment: string;
  removeAttachment: (attachment: string) => void;
  renameAttachment: (oldAtt: string, newAtt: string) => void;
}

interface ItemState {
  edit: boolean;
}

class AttachmentItem extends React.Component<ItemProps, ItemState> {
  constructor(props: ItemProps) {
    super(props);
    this.state = { edit: false };
  }

  toggleEditMode = () => {
    this.setState({ edit: !this.state.edit });
  };

  handleDelete = async () => {
    if (await getConfirmDialog("Wollen Sie diese Anlage wirklich löschen?")) {
      this.props.removeAttachment(this.props.attachment);
    }
  };

  handleRename = async () => {
    this.props.renameAttachment(
      this.props.attachment,
      document.getElementById("new-att-name").value
    );
  };

  render() {
    const { attachment } = this.props;

    return (
      <StyledLI>
        {this.state.edit ? (
          <>
            <Textfield inputProps={{ placeholder: attachment, id: "new-att-name" }} />
            <span className="icon-group">
              <LeftIcon onClick={this.toggleEditMode} />
              <SaveIcon onClick={this.handleRename} />
            </span>
          </>
        ) : (
          <>
            <span>{attachment}</span>
            <span className="icon-group">
              <EditIcon onClick={this.toggleEditMode} />
              <DeleteIcon onClick={this.handleDelete} />
            </span>
          </>
        )}
      </StyledLI>
    );
  }
}

interface DialogProps {
  car: Auto;
  attachments: string[];
  closeHandler: (attachments: string[]) => void;
}

interface DialogState {
  newAttachments: string[];
}

const StyledContainer = styled(DialogContainer)`
  &#dbx-attachment-edit-dialog {
    margin: 0;

    ul {
      flex: 1 1 auto;
      overflow-y: auto;
    }
  }
`;

export class DbxEditDialog extends React.Component<DialogProps, DialogState> {
  constructor(props: DialogProps) {
    super(props);
    this.state = { newAttachments: this.props.attachments.slice(0) };
  }

  removeAttachment = async (attachment: string) => {
    showToast("Gesendet");
    try {
      const response = await fetch(
        `/cars/${this.props.car.autoNr}/dropbox/attachments/${attachment}`,
        { method: "DELETE" }
      );
      if (response.status === 200) {
        showToast("Gelöscht");
        this.setState({
          newAttachments: this.state.newAttachments.filter((att) => att !== attachment),
        });
      } else {
        showToast(`Löschen fehlgeschlagen: ${await response.text()}`);
      }
    } catch (err) {
      showToast("Fehler: " + err);
    }
  };

  renameAttachment = async (oldAttachment: string, newAttachment: string) => {
    showToast("Gesendet");
    let response: Response;
    try {
      response = await fetch(
        `/cars/${this.props.car.autoNr}/dropbox/attachments/${oldAttachment}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: newAttachment,
          }),
        }
      );

      if (response.status === 200) {
        showToast("Umbenannt");
        this.setState({
          newAttachments: this.state.newAttachments.map((att) => {
            if (att !== oldAttachment) return att;
            else return newAttachment;
          }),
        });
      } else {
        showToast(`Umbenennen fehlgeschlagen: ${await response.text()}`);
      }
    } catch (err) {
      showToast("Fehler: " + err);
    }
  };

  render() {
    const { closeHandler } = this.props;
    const { newAttachments: attachments } = this.state;

    return (
      <StyledContainer id="dbx-attachment-edit-dialog">
        <>
          <ul className="attachments">
            {attachments.map((attachment) => (
              <AttachmentItem
                key={attachment}
                attachment={attachment}
                removeAttachment={this.removeAttachment}
                renameAttachment={this.renameAttachment}
              />
            ))}
          </ul>
          <DialogActions>
            <Button onClick={() => closeHandler(attachments)}>Schließen</Button>
          </DialogActions>
        </>
      </StyledContainer>
    );
  }
}
