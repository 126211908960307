export interface AppSettings {
  _id: 0;
  notification: {
    email: string;
  };
  backup: {
    taeglich: number;
    monatlich: number;
  };
}

export interface Credentials {
  email: string;
  expiry?: number;
  hash: string;
}

export interface AppData {
  lastEdit: number;
  artikel: { [key: string]: Artikel };
  dokument: { [key: string]: Dokument };
  kunde: { [key: string]: Kunde };
  auftrag: { [key: string]: Auftrag };
  auto: { [key: string]: Auto };
}

export interface Auto {
  auftraege: Array<string>;
  autoNr: string;
  erstzulassung: string;
  farbe: string;
  hersteller: string;
  hubraum: string;
  kennzeichen: string;
  kommentar: string;
  kraftstoff: string;
  kunden: Array<string>;
  leistung: string;
  modell: string;
  oelwechselDatum: string;
  oelwechselKM: string;
  reifen: string;
  tuev: string;
  vin: string;
  zahnriemenKM: string;
  zahnriemenDatum: string;
  zu2: string;
  zu3: string;
}

export interface Artikel {
  id: string;
  bez: string;
  nr: string;
  menge?: number;
  preis: number;
}

export interface Kunde {
  kundenNr: string;
  vorname: string;
  nachname: string;
  auftraege: Array<string>;
  autos: Array<string>;
  email?: string;
  festnetz?: string;
  firma?: string;
  geburtstag?: string;
  kommentar?: string;
  mobil?: string;
  plz?: string;
  stadt?: string;
  strasse?: string;
}

export interface Auftrag {
  auftragsNr: string;
  auto: string;
  beschreibung: string;
  datum: string;
  dokumente: Array<string>;
  kmStand: string;
  kunde: string;
  positionen: Array<AuftragsPosition>;
  status: AuftragStatus;
  titel: string;
  zahlungsart: ZahlungsArt;
  zahlungsziel: string;
}

export type AuftragsPosition = AuftragsPositionHeader | AuftragsPositionItem;

export interface AuftragsPositionItem {
  artikelNr: string;
  bezeichnung: string;
  menge: number;
  preisEinheit: number;
  rabatt: number;
}

export interface AuftragsPositionHeader {
  ueberschrift: string;
}

export enum PositionsArt {
  item = "Item",
  heading = "Header",
}

export enum AuftragStatus {
  inArbeit = "In Arbeit",
  fertig = "Fertig",
  abgebrochen = "Abgebrochen",
}

export enum ZahlungsArt {
  bar = "Bar",
  ueberweisung = "Überweisung",
}

export interface Dokument {
  art: DokumentArt;
  nummer: string;
  speicherdatum: string;
  titel: string;
  kunde: Kunde;
  auto: Auto;
  auftrag: Auftrag;
}

export enum DokumentArt {
  kostenvoranschlag = "Kostenvoranschlag",
  rechnung = "Rechnung",
}
