import * as React from "react";
import * as ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { subscribe, publish, pubSubTopic } from "../../pubSub";
import { dark1, phoneQuerySize } from "../../styleConstants";

const animationDuration = 300;
const defaultTimeout = 1200;

const StyledToastContainer = styled.div`
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 7;
  display: flex;
  flex-direction: column-reverse;

  @media print {
    display: none;
  }
`;

const StyledToast = styled.div`
  ${() => {
    return css`
      background-color: ${dark1};
      box-sizing: border-box;
      max-width: 100vw;
      min-width: 200px;
      margin-top: 5px;
      transform: translateY(100%);
      transition: transform ${animationDuration}ms;
      will-change: transform;
      border-radius: 2px;
      color: white;
      padding: 12px 24px;
      display: flex;

      & button {
        padding: 0;
        margin: 0;
        background-color: inherit;
        font-weight: bold;
        color: skyblue;
        margin-left: 10px;
        border: none;
        outline: none;
        font-size: 1em;
        overflow: visible;
      }

      &.toast-enter-active,
      &.entered {
        transform: translateY(0);
      }

      @media screen and (max-width: ${phoneQuerySize}px),
        screen and (max-height: ${phoneQuerySize}px) {
        width: 100vw;
      }
    `;
  }}
`;

interface toastArguments {
  text: string;
  timeout?: number;
  button?: {
    text: string;
    callback: (evt: React.MouseEvent<HTMLButtonElement>) => any;
  };
}

export interface toastPubSubPayload extends toastArguments {
  topic: pubSubTopic.showToast;
}

interface toastData extends toastArguments {
  key: string;
}

interface state {
  toastMap: { [key: string]: toastData };
}

export class ToastContainer extends React.Component<{}, state> {
  constructor(props: {}) {
    super(props);
    this.state = {
      toastMap: {},
    };
  }

  componentDidMount() {
    subscribe(pubSubTopic.showToast, this.addToast);
  }

  addToast = (toastArgs: toastArguments) => {
    let tmp = this.state;
    let key = Date.now().toString();
    tmp.toastMap[key] = Object.assign({}, toastArgs, { key });
    if (toastArgs.button) {
      let tmpFnc = toastArgs.button.callback;
      tmp.toastMap[key].button.callback = evt => {
        tmpFnc(evt);
        let tmpState = this.state;
        delete tmpState.toastMap[key];
        this.setState(tmpState);
      };
    }
    this.setState(tmp);
    const timeout = toastArgs.timeout || defaultTimeout;

    window.setTimeout(() => {
      let tmpState = this.state;
      delete tmpState.toastMap[key];
      this.setState(tmpState);
    }, 2 * animationDuration + timeout);
  };

  render() {
    let sortedToasts = Object.values(this.state.toastMap).sort((a, b) => {
      return parseInt(a.key) > parseInt(b.key) ? -1 : 1;
    });

    return (
      <StyledToastContainer>
        <TransitionGroup className="toastContainer">
          {sortedToasts.map(({ text, key, button }) => {
            return (
              <CSSTransition key={key} classNames="toast" appear={true} timeout={animationDuration}>
                {(state: string) => {
                  return (
                    <StyledToast className={state + " toast-element"}>
                      <div>{text}</div>
                      {button && (
                        <button className="ripple" onClick={button.callback}>
                          {button.text}
                        </button>
                      )}
                    </StyledToast>
                  );
                }}
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </StyledToastContainer>
    );
  }
}

export function showToast(
  text: string,
  timeout?: number,
  button?: { text: string; callback: (evt: React.MouseEvent<HTMLButtonElement>) => any }
) {
  publish(pubSubTopic.showToast, { topic: pubSubTopic.showToast, text, timeout, button });
};

export const toastInit = function toastInit() {
  ReactDOM.render(<ToastContainer />, document.querySelector("#toast"));
};
