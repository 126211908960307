import { publish, subscribe, pubSubTopic } from "./pubSub";

import { elementsInit } from "./modules/htmlElements/elementsFunctionality";
import { navigationInit, navigateDefault } from "./modules/navigation/navigation";

import { loginInit, loginStart } from "./modules/loginPages/loginFunctionality";
import { clientPageInit } from "./modules/clientPage/clientFunctionality";
import { carPageInit } from "./modules/carPage/carFunctionality";
import { orderPageInit } from "./modules/orderPage/orderFunctionality";
import { articlePageInit } from "./modules/articlePage/articleFunctionality";
import { settingsPageInit } from "./modules/settingsPage/settingsFunctionality";
import { docsOverviewInit } from "./modules/docsPage/overviewFunctionality";

import { SessionData } from "./modules/localStorageClasses";

import { overviewInit } from "./modules/overviewPage/overview";

export function pageStart() {
  window.sessionData = new SessionData(window.sessionStorage.sessionData);

  window.data = null;
  window.settingsData = null;

  //this code provides a fallback in order to set passive events (non scroll blocking)
  // Test via a getter in the options object to see if the passive property is accessed
  var supportsPassive = false;
  try {
    var opts = Object.defineProperty({}, "passive", {
      get: function () {
        supportsPassive = true;
      },
    });
    window.addEventListener("test", null, opts);
  } catch (e) {}
  window.safePassiveEvent = supportsPassive ? { passive: true } : false;

  elementsInit();
  loginInit();

  // is called by loginStart - if the login succeeds
  subscribe(pubSubTopic.loginSuccessfull, pageInit);

  // the real pageStart goes through the loginStart
  loginStart();

  navigationInit();
  articlePageInit();
  carPageInit();
  clientPageInit();
  orderPageInit();
  settingsPageInit();
  overviewInit();
  docsOverviewInit();

  (function touchHandling() {
    // setting up the touch handling event listeners
    let tStart: { x: number; y: number } = null;
    let tEnd: { x: number; y: number } = null;
    let touchTimer: number;

    document.body.addEventListener(
      "touchstart",
      function (evt: TouchEvent) {
        if (!tStart) {
          tStart = { x: evt.changedTouches[0].pageX, y: evt.changedTouches[0].pageY };
          touchTimer = window.setTimeout(function touchReset() {
            tStart = null;
          }, 1000);
        } else tStart = null;
      },
      window.safePassiveEvent
    );
    document.body.addEventListener(
      "touchend",
      function (evt: TouchEvent) {
        clearTimeout(touchTimer);
        if (tStart) {
          tEnd = { x: evt.changedTouches[0].pageX, y: evt.changedTouches[0].pageY };
          if (tStart.x < 20 && Math.abs(tStart.y - tEnd.y) < 100 && tEnd.x - tStart.x > 75) {
            publish(pubSubTopic.sideNavOpen);
          }
        }
        tStart = null;
      },
      window.safePassiveEvent
    );
  })();
}

async function fetchAllWithRetry() {
  const result = await fetch("/allData", {
    method: "GET",
    credentials: "same-origin",
  });

  if (result.ok) return result;
  else
    return fetch("/allData", {
      method: "GET",
      credentials: "same-origin",
    });
}

export async function pageInit() {
  const res = await fetchAllWithRetry();
  if (!res.ok) throw new Error(res.status + " - " + res.statusText);
  const json = await res.json();
  window.data = json.data;
  window.settingsData = json.settings;

  setTimeout(function () {
    localStorage.data = JSON.stringify(window.data);
    localStorage.settingsData = JSON.stringify(window.settingsData);
  }, 750);
  navigateDefault();
}
