import * as React from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";
// own packages
import { debounce } from "../../../../../globalData/helperFunctions";
import { subscribe, pubSubTopic, pubSubBasePayload, publish } from "../../../pubSub";
import { RightIcon } from "../svgIcons";
import { shadow1, white1 } from "../../../styleConstants";
import { Button } from "../formComponents/button";
import { DialogContainer } from "./genericDialogStyles";

const initialListLength = 20;

interface SelectionDialogOptions {
  searchList?: string[];
  keyArr?: string[] | number[];
  tooltipList?: string[];
  useSearchAsKey?: boolean;
}

export interface selectionDialogPayload extends pubSubBasePayload {
  topic: pubSubTopic.showSelectionDialog;
  selectionList: string[];

  options?: SelectionDialogOptions;
  promiseResolve?: (res: string | number) => void;
}

const StyledDialogContainer = styled(DialogContainer)`
  input {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    display: block;
    font-size: 16px;
    margin: 0;
    padding: 4px 0;
    width: 100%;
    background: 0 0;
    text-align: left;
    color: inherit;
    min-height: 26px;
  }

  input:focus {
    outline: none;
    border-bottom: 1px solid rgb(96, 125, 139);
  }

  ul {
    overflow: auto;
  }

  li:hover {
    box-shadow: ${shadow1};
    background-color: ${white1};
  }

  svg {
    width: 20px;
    margin-right: 1rem;
    pointer-events: none;
  }
`;

interface state {
  visible: boolean;
  selectionList?: string[];
  searchList?: string[];
  keyArr?: string[] | number[];
  options?: SelectionDialogOptions;
  promiseResolve?: (res: string | number) => void;
  listLength?: number;
  filter: string;
}

export class SelectionDialog extends React.Component<{}, state> {
  private inputEl: React.RefObject<HTMLInputElement>;

  private static checkArguments = function checkArguments(args: selectionDialogPayload) {
    let options: SelectionDialogOptions = {
      ...args.options,
      tooltipList: undefined,
      searchList: undefined,
      keyArr: undefined
    };

    if (!args.options.searchList) options.searchList = args.selectionList;
    else if (args.selectionList.length !== args.options.searchList.length) {
      console.warn(
        "searchList-Length and selectionList-Length don't match.",
        args.options.searchList,
        args.selectionList
      );
      options.searchList = args.selectionList;
    } else options.searchList = args.options.searchList;

    if (args.options.useSearchAsKey) options.keyArr = args.selectionList;
    else if (args.options.keyArr) {
      if (args.options.keyArr.length !== args.selectionList.length) {
        console.warn(
          "keyArray-Length and selectionList-Length don't match.",
          args.options.keyArr,
          args.selectionList
        );
      } else if (args.options.keyArr) options.keyArr = args.options.keyArr;
    }

    if (args.options.tooltipList) {
      if (args.options.tooltipList.length !== args.selectionList.length) {
        console.warn(
          "tooltip-Length and selectionList-Length don't match.",
          args.options.tooltipList,
          args.selectionList
        );
      } else options.tooltipList = args.options.tooltipList;
    }

    return options;
  };

  constructor(props: {}) {
    super(props);
    this.state = { visible: false, filter: "" };
    this.inputEl = React.createRef();

    subscribe(pubSubTopic.showSelectionDialog, (pl: selectionDialogPayload) => {
      let options: SelectionDialogOptions;
      if (pl.options) options = SelectionDialog.checkArguments(pl);
      else options = { searchList: pl.selectionList };

      this.setState({
        visible: true,
        selectionList: pl.selectionList,
        options,
        promiseResolve: pl.promiseResolve,
        listLength: initialListLength,
        filter: ""
      });
    });
  }

  showMoreHandler = () => {
    this.setState(
      Object.assign({}, this.state, { listLength: this.state.listLength + initialListLength })
    );
  };

  cancelHandler = (evt: React.MouseEvent<HTMLDivElement>): void => {
    if (evt.target === evt.currentTarget) {
      this.state.promiseResolve(null);
      this.setState({ visible: false });
    }
  };

  itemClick = (evt: React.MouseEvent<HTMLLIElement>) => {
    let key = evt.currentTarget.getAttribute("data-key");
    this.state.promiseResolve(isNaN(key as any) ? key : parseInt(key));
    this.setState({ visible: false });
  };

  setFilteredItems = (): React.ReactElement<HTMLDivElement>[] => {
    const selectionList = this.state.selectionList;
    const filter = this.state.filter;
    const searchList = this.state.options.searchList;
    const keyArr = this.state.options.keyArr;
    const tooltipList = this.state.options.tooltipList;
    const itemClick = this.itemClick;

    return selectionList
      .map(function(item, index) {
        return {
          text: item,
          search: searchList[index],
          id: keyArr ? keyArr[index] : index,
          title: tooltipList ? tooltipList[index] : ""
        };
      })
      .filter(function(item) {
        if (!filter) return true;

        let searchResult = true;
        let search = filter.split(" ");
        search.forEach(function(query) {
          if (query) searchResult = searchResult && item.search.indexOf(query.toLowerCase()) !== -1;
        });

        return searchResult;
      })
      .map(function(item) {
        return (
          <li title={item.title} data-key={item.id} onClick={itemClick} key={item.id}>
            <RightIcon /> {item.text}
          </li>
        );
      });
  };

  itemSearch = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(Object.assign({}, this.state, { filter: this.inputEl.current.value }));
  }, 350);

  componentDidUpdate() {
    if (this.inputEl.current) this.inputEl.current.focus();
  }

  render() {
    if (!this.state.visible) return null;

    let { listLength, filter } = this.state;

    let resultArr = this.setFilteredItems();

    return (
      <StyledDialogContainer id="selection-dialog" onClick={this.cancelHandler}>
        <>
          <input
            ref={this.inputEl}
            onInput={this.itemSearch}
            type="text"
            defaultValue={filter}
            placeholder="Suche..."
          />
          <ul>
            {resultArr.map((item, index) => {
              return index < listLength ? item : null;
            })}
            <Button onClick={this.showMoreHandler} disabled={resultArr.length <= listLength}>
              Mehr anzeigen
            </Button>
          </ul>
        </>
      </StyledDialogContainer>
    );
  }
}

interface stringCase extends SelectionDialogOptions {
  keyArr: string[];
}
interface stringCase2 extends SelectionDialogOptions {
  useSearchAsKey: true;
}

export function getSelectionDialog(selectionList: string[], options: stringCase): Promise<string>;
export function getSelectionDialog(selectionList: string[], options: stringCase2): Promise<string>;
export function getSelectionDialog(selectionList: string[]): Promise<number>;
export function getSelectionDialog(
  selectionList: string[],
  options: SelectionDialogOptions
): Promise<number>;
export function getSelectionDialog(
  selectionList: string[],
  options: SelectionDialogOptions
): Promise<number | string>;

export function getSelectionDialog(
  selectionList: string[],
  options?: SelectionDialogOptions
): Promise<string | number> {
  return new Promise(function(resolve) {
    publish(pubSubTopic.showSelectionDialog, {
      topic: pubSubTopic.showSelectionDialog,
      selectionList,
      options,
      promiseResolve: resolve
    });
  });
}

export const selectionDialogInit = function selectionDialogInit() {
  ReactDOM.render(<SelectionDialog />, document.getElementById("selection-dialog-container"));
};
