import { subscribe, publish, pubSubTopic } from "../../pubSub";

export function spinnerInit() {
  subscribe(pubSubTopic.showSpinner, showSpinner);
  subscribe(pubSubTopic.showOnlySpinner, showOnlySpinner);
  subscribe(pubSubTopic.hideSpinner, hideSpinner);
}

export function showSpinner() {
  if (!document.getElementById("loading-spinner")) {
    var pageContent = document.getElementById("page-content");
    pageContent.insertBefore(getSpinner(), pageContent.children[0]);
  }
}

export function showOnlySpinner() {
  var pageContent = document.getElementById("page-content");
  pageContent.innerHTML = "";
  pageContent.appendChild(getSpinner());
}

export function hideSpinner() {
  var spinnerEl = document.getElementById("loading-spinner");
  if (spinnerEl) spinnerEl.parentNode.removeChild(spinnerEl);
}

export function getSpinner() {
  var newEl = document.createElement("DIV");
  newEl.className = "sk-fading-circle";
  newEl.id = "loading-spinner";

  var loopEl;
  for (var i = 1; i < 13; i++) {
    loopEl = document.createElement("DIV");
    loopEl.className = "sk-circle" + i + " sk-circle";
    newEl.appendChild(loopEl);
  }
  loopEl = null;

  return newEl;
}
