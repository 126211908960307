import { sideNavInit } from "./sideNav";
import { toastInit } from "../components/toast";
import { spinnerInit } from "./spinner";
import { selectionDialogInit } from "../components/dialogs/selectionDialog";
import { confirmDialogInit } from "../components/dialogs/confirmDialog";

export function elementsInit() {
  sideNavInit();
  toastInit();
  spinnerInit();
  selectionDialogInit();
  confirmDialogInit();
}
