import { publish, subscribe, pubSubTopic } from "../../pubSub";
import { loginDialog } from "./loginDialog";
import { Credentials } from "../../../../globalData/appInterfaces";
import { PassLoginRequest, HashLoginRequest, isPassLogin } from "../../../../globalData/backendInterfaces";

export const passLoginURL = "/login";
export const hashLoginURL = "/login/hash";

let credentials: Credentials;
const credentialExpiry = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

export function loginInit() {
  subscribe(pubSubTopic.loginSubmitted, function (payload: { user: string; pass: string; saveLogin: boolean }) {
    serverLogin({ email: payload.user, pass: payload.pass }).then(function (res) {
      return handleLoginResponse(res, payload.saveLogin);
    });
  });
  subscribe(pubSubTopic.logoutInit, logoutFct);

  loadCredentials();
}

export function loginStart() {
  if (credentials) {
    serverLogin({ email: credentials.email, hash: credentials.hash })
      .then(function (res) {
        return handleLoginResponse(res, false);
      })
      .then(function (result) {
        if (result === "LOGIN_REQUIRED") loginDialog("");
      });
  } else loginDialog();
}

function handleLoginResponse(response: Response, persistent: boolean) {
  switch (response.status) {
    case 200:
      publish(pubSubTopic.loginSuccessfull);
      response.json().then(function (json) {
        saveCredentials(json, persistent);
      });
      break;
    case 401:
    case 500:
      publish(pubSubTopic.loginError, {
        topic: pubSubTopic.loginError,
        statusText: response.statusText,
      });
      try {
        response.text().then(function (text) {
          publish(pubSubTopic.loginError, { topic: pubSubTopic.loginError, statusText: text });
        });
      } catch (err) {
        publish(pubSubTopic.loginError, {
          topic: pubSubTopic.loginError,
          statusText: response.statusText,
        });
      }
      return "LOGIN_REQUIRED";
    default:
      publish(pubSubTopic.loginCancel);
  }
}

function saveCredentials(userData: Credentials, persistent: boolean) {
  setTimeout(function () {
    if (persistent) {
      userData.expiry = Date.now() + credentialExpiry;
      localStorage.setItem("credentials", JSON.stringify(userData));
    } else sessionStorage.setItem("credentials", JSON.stringify(userData));
  }, 250);
}

function loadCredentials() {
  if (sessionStorage.getItem("credentials")) {
    try {
      credentials = JSON.parse(sessionStorage.getItem("credentials"));
    } catch (error) {
      credentials = null;
    }
  } else if (localStorage.getItem("credentials")) {
    try {
      credentials = JSON.parse(localStorage.getItem("credentials"));
    } catch (error) {
      credentials = null;
    }

    if (credentials && typeof credentials.expiry === "number" && credentials.expiry < Date.now()) {
      delete localStorage.credentials;
      credentials = null;
    }
  }
}

function logoutFct() {
  sessionStorage.credentials = "";
  localStorage.credentials = "";
  window.location.reload();
}

export function serverLogin(loginBody: PassLoginRequest | HashLoginRequest) {
  const loginURL = isPassLogin(loginBody) ? passLoginURL : hashLoginURL;
  return fetch(loginURL, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(loginBody),
  }).catch(function (err) {
    // resolve an error while fetching to let handleLoginResponse deal with it
    return err;
  });
}
