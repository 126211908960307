import * as React from "react";
import styled from "styled-components";
// own packages
import { getAttachmentLink, getAttachment, listAllAttachments } from "./dbxFunctionality";
import { ImageIcon, FileIcon, FilesIcon } from "../svgIcons";
import { Auto } from "../../../../../globalData/appInterfaces";

const StyledDiv = styled.div`
  display: flex;

  .file_item {
    margin: 10px;
    display: inline-flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    overflow-x: hidden;
    & span {
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
`;

class FileIconEl extends React.PureComponent<{ fileName: string; carID: string }> {
  imageFiles = ["jpg", "jpeg", "png"];

  openFile = async () => {
    const link = await getAttachmentLink(this.props.carID, this.props.fileName);
    if (link) window.open(link, "_blank");
  };

  openImage = async () => {
    const fileName = this.props.fileName;
    const tmpFile = await getAttachment(this.props.carID, fileName);
    if (tmpFile) window.open("/temp/" + tmpFile);
  };

  render() {
    const { fileName } = this.props;
    const imageBln = this.imageFiles.includes(
      fileName
        .split(".")
        .pop()
        .toLocaleLowerCase()
    );

    return (
      <span className="file_item" onClick={imageBln ? this.openImage : this.openFile}>
        {imageBln ? ImageIcon({}) : FileIcon({})}
        <span>{fileName}</span>
      </span>
    );
  }
}

interface EllipsisProps {
  car: Auto;
  attachments: string[];
}

class EllipsisIconEl extends React.PureComponent<EllipsisProps> {
  render() {
    const { car, attachments } = this.props;
    return (
      <span className="file_item" onClick={() => listAllAttachments(car, attachments)}>
        <FilesIcon />
        <span>...</span>
      </span>
    );
  }
}

interface DbxPreviewerProps {
  car: Auto;
  attachments: string[];
}

export class DropboxPreviewer extends React.PureComponent<DbxPreviewerProps> {
  render() {
    const { car, attachments } = this.props;

    const files = attachments
      .slice(0, 5)
      .map(fileName => <FileIconEl key={fileName} fileName={fileName} carID={car.autoNr} />);

    if (attachments.length > 5) {
      files.push(<EllipsisIconEl key="rest" car={car} attachments={attachments} />);
    }

    return <StyledDiv>{files}</StyledDiv>;
  }
}
