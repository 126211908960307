import { publish, pubSubTopic } from "../../pubSub";

import { fetchPage, saveData } from "../globalFunctions";

import { getEditLink } from "../htmlElements/variousElements";
import { showToast } from "../components/toast";

import { editURL, setButtonFunctionality } from "./carFunctionality";
import { Auto } from "../../../../globalData/appInterfaces";
import { EditType, carEditEvent, carNewEvent } from "../../../../globalData/dataEditInterfaces";
import { navDestination } from "../navigation/navConstants";
import { getSelectionDialog } from "../components/dialogs/selectionDialog";
import { getKeySearchString } from "../../../../globalData/helperFunctions";
import { getClientAsString, getSortedClients } from "../clientPage/clientHelpers";

const properties = [
  "kennzeichen",
  "erstzulassung",
  "hubraum",
  "leistung",
  "vin",
  "zu2",
  "zu3",
  "kraftstoff",
  "farbe",
  "oelwechselKM",
  "oelwechselDatum",
  "tuev",
  "zahnriemenKM",
  "zahnriemenDatum",
  "modell",
  "hersteller",
  "kommentar",
];
let tempServiceList: string[] = [];

const fillEditView = function fillEditView(argCar: Auto) {
  tempServiceList = argCar.auftraege;
  var el;
  properties.forEach(function (prop) {
    if ((<any>argCar)[prop]) {
      el = document.getElementById(prop);
      el.value = (<any>argCar)[prop];
    }
  });

  if (argCar.reifen) {
    var tReifen = argCar.reifen;
    el = document.getElementById("reifen-1");
    el.value = tReifen.slice(0, 3);
    el = document.getElementById("reifen-2");
    el.value = tReifen.slice(4, 6);
    el = document.getElementById("reifen-3");
    el.value = tReifen.slice(8, 10);
    el = document.getElementById("reifen-4");
    el.value = tReifen.slice(11);
  }

  var linkTarget = document.getElementById("client-assign-button");
  argCar.kunden.forEach(function (carOwner) {
    linkTarget.parentNode.insertBefore(carEditLink(getEditLink(), carOwner), linkTarget);
  });
};

export function carEdit(autoID?: string) {
  return fetchPage(editURL, "eingabe", navDestination.car).then(function () {
    window.sessionData.setLastView(navDestination.car, autoID);
    if (typeof autoID === "string") fillEditView(window.data.auto[autoID]);

    setButtonFunctionality();
  });
}

function carEditLink(el: HTMLElement, carOwner: string) {
  el.id = carOwner;
  el.innerHTML += getClientAsString(window.data.kunde[carOwner]);

  el.children[0].onclick = function () {
    this.parentNode.parentNode.removeChild(this.parentNode);
  };

  return el;
}

function reifenCheck(strReifen: string) {
  if (strReifen.length < 14) {
    showToast("Reifenangabe nicht vollständig");
    return false;
  } else {
    for (var ii = 0; ii < strReifen.length; ii++) {
      console.log(ii, strReifen.charAt(ii));
      if (
        (ii < 3 || ii === 4 || ii === 5 || ii === 8 || ii === 9) &&
        !RegExp("\\d").test(strReifen.charAt(ii))
      ) {
        showToast("Die " + (ii + 1) + ". Stelle beim Reifen ist keine Zahl.");
        return false;
      }
    }
  }

  return true;
}

function entryCheck(entry: Auto) {
  if (!entry.kennzeichen) {
    showToast("Kennzeichen fehlt.");
    return false;
  } else if (!entry.hersteller) {
    showToast("Hersteller fehlt.");
    return false;
  } else if (!entry.modell) {
    showToast("Modell fehlt.");
    return false;
  } else if (entry.reifen !== undefined && !reifenCheck(entry.reifen)) {
    return false;
  }

  return true;
}

export function saveCar() {
  var newCar: Auto = {
    hersteller: document.getElementById("hersteller").value,
    modell: document.getElementById("modell").value,
    kennzeichen: document.getElementById("kennzeichen").value,
    erstzulassung: document.getElementById("erstzulassung").value,
    hubraum: document.getElementById("hubraum").value,
    leistung: document.getElementById("leistung").value,
    vin: document.getElementById("vin").value,
    zu2: document.getElementById("zu2").value,
    zu3: document.getElementById("zu3").value,
    kraftstoff: document.getElementById("kraftstoff").value,
    farbe: document.getElementById("farbe").value,
    oelwechselKM: document.getElementById("oelwechselKM").value,
    oelwechselDatum: document.getElementById("oelwechselDatum").value,
    tuev: document.getElementById("tuev").value,
    zahnriemenKM: document.getElementById("zahnriemenKM").value,
    zahnriemenDatum: document.getElementById("zahnriemenDatum").value,
    kommentar: document.getElementById("kommentar").value,
    reifen:
      document.getElementById("reifen-1").value +
      "/" +
      document.getElementById("reifen-2").value +
      " R" +
      document.getElementById("reifen-3").value +
      " " +
      document.getElementById("reifen-4").value,
    auftraege: [],
    kunden: [],
    autoNr: window.sessionData.car,
  };
  if (newCar.reifen.length === 4) newCar.reifen = undefined;

  var tempArr = document.querySelectorAll(".data-link");
  for (var i = 0; i < tempArr.length; i++) {
    newCar.kunden.push(tempArr[i].id);
  }
  tempArr = null;
  newCar.auftraege = tempServiceList;

  if (!window.sessionData.car) {
    var tempNr = 0;

    while (window.data.auto["A" + tempNr] !== undefined) {
      tempNr++;
    }
    newCar.autoNr = "A" + tempNr;
  }

  if (entryCheck(newCar)) {
    let editObject: carEditEvent | carNewEvent;
    if (!window.sessionData.car) {
      editObject = { type: EditType.carNew, payload: newCar };
      window.sessionData.car = newCar.autoNr;
    } else editObject = { type: EditType.carEdit, payload: newCar };

    return saveData([editObject]).then(function (result) {
      if (result) {
        publish(pubSubTopic.pageNavInitiated, {
          topic: pubSubTopic.pageNavInitiated,
          destination: navDestination.car,
          destObject: { element: window.sessionData.car },
        });
      }
    });
  }
  return null;
}

export function addClient() {
  const selectionList: string[] = [];
  const searchList: string[] = [];
  const keyArr: string[] = [];

  getSortedClients(Object.values(window.data.kunde)).forEach(function (client) {
    selectionList.push(getClientAsString(client));
    searchList.push(getKeySearchString(client));
    keyArr.push(client.kundenNr);
  });

  getSelectionDialog(selectionList, { searchList, keyArr }).then(function (el) {
    if (typeof el === "string") {
      if (document.getElementById(el)) {
        showToast("Kunde bereits zugeordnet");
      } else {
        var linkTarget = document.getElementById("client-assign-button");
        linkTarget.parentNode.insertBefore(carEditLink(getEditLink(), el), linkTarget);
      }
    }
  });
}
