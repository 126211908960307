import { findFirstParentEl } from "../../../../globalData/helperFunctions";
import { searchIcon, deleteIcon, upIcon } from "../../svgIcons";

import { calculatePositionSum, setPositionNumber } from "./editFunctionality";

import {
  AuftragsPosition,
  PositionsArt,
  AuftragsPositionItem,
  AuftragsPositionHeader,
} from "../../../../globalData/appInterfaces";
import { numToStr, round, strToFloat } from "../../../../globalData/helperFunctions";
import { getArticleSelectionDialog } from "../articlePage/articleFunctionality";

export interface EditPositionDiv extends HTMLDivElement {
  getPositionData: () => AuftragsPosition;
  dataNet: number;
}

export function getOrderEditPosition(pos: AuftragsPositionItem) {
  var newEl = <EditPositionDiv>document.createElement("div");
  newEl.setAttribute("data-art", PositionsArt.item);
  newEl.className = "page-section invoice-position";
  newEl.innerHTML = `<div class="section-title-container"><span class="section-title">Position 1:</span>
      <button class="sf-mdl-button article-select icon-button">
          <svg ${searchIcon.viewBox}>${searchIcon.path}</button>
      <button class="removePosition sf-mdl-button ripple icon-button">
        <svg ${deleteIcon.viewBox}>${deleteIcon.path}</svg>
      </button>
    </div>
    <div class="section-content">
      <div class="position-group">
        <div class="article-title-group">
          <div name="artikelNr" class="content-item sf-mdl-textfield artikelNr">
            <input required placeholder="Artikel-Nr." type="text" id="artikelNr1">
            <label for="artikelNr1">Artikel-Nr.</label>
          </div>
          <div name="preisEinheit" class="content-item sf-mdl-textfield number">
            <input placeholder="Preis / Einheit" required type="text" id="preisEinheit1">
            <label for="preisEinheit1">Preis / Einheit</label>
          </div>
        </div>
        <div name="bezeichnung" class="content-item sf-mdl-textfield textarea">
          <textarea type="text" rows= "1" placeholder="Bezeichnung" id="bezeichnung1"></textarea>
          <label for="bezeichnung1">Bezeichnung</label>
        </div>
      </div>
      <div class="position-group">
        <div name="rabatt" class="content-item sf-mdl-textfield number">
          <input placeholder="Rabatt (%)" required type="text" id="rabatt1">
          <label for="rabatt1">Rabatt (%)</label>
        </div>
        <div name="menge" class="content-item sf-mdl-textfield number">
          <input placeholder="Menge" required type="text" class="menge" id="menge1">
          <label for="menge1">Menge</label>
        </div>
        <span class="preisSumme"></span>
      </div>
    </div>`;

  if (pos !== null && pos !== undefined) {
    newEl.querySelector("#artikelNr1").value = pos.artikelNr;
    newEl.querySelector("#bezeichnung1").value = pos.bezeichnung;
    newEl.querySelector("#preisEinheit1").value = numToStr(pos.preisEinheit);
    newEl.querySelector("#rabatt1").value = numToStr(pos.rabatt);
    newEl.querySelector("#menge1").value = numToStr(pos.menge);

    let summe = round(pos.preisEinheit * pos.menge * (1 - pos.rabatt / 100), 2);
    newEl.querySelector(".preisSumme").innerText = `Summe: ${numToStr(summe)} €`;
    newEl.dataNet = summe;

    newEl.querySelector("#menge1").addEventListener("change", (evt: Event) => {
      amountCheck(evt.target as any as HTMLInputElement, pos.menge);
    });
  }

  newEl.querySelector("button.removePosition").addEventListener("click", function (evt) {
    newEl.parentNode.removeChild(newEl);
    setPositionNumber();
  });
  newEl.querySelector("button.article-select").onclick = articleSelect;

  ["preisEinheit", "rabatt", "menge"].forEach(function (key) {
    newEl.querySelector(`div[name='${key}'] input`).addEventListener("change", handlePriceChange);
  });

  return newEl;
}

function amountCheck(node: HTMLInputElement, amount: number) {
  if (amount && strToFloat(node.value) > amount) {
    node.style.color = "red";
  } else node.style.color = null;
}

function handlePriceChange(evt: Event) {
  let posContainer: Node;
  if (!evt) posContainer = findFirstParentEl(this, "invoice-position");
  else posContainer = findFirstParentEl(<any>evt.target, "invoice-position");
  var price = strToFloat(posContainer.querySelector("div[name='preisEinheit'] input").value);
  var amount = strToFloat(posContainer.querySelector("div[name='menge'] input").value);
  var rebate = strToFloat(posContainer.querySelector("div[name='rabatt'] input").value);
  let sum = round(price * amount * (1 - rebate / 100), 2);
  posContainer.querySelector(".preisSumme").innerText = `Summe: ${numToStr(sum)} €`;

  // this exposes data for the order view
  (<EditPositionDiv>posContainer).dataNet = sum;

  calculatePositionSum();
}

export function articleSelect(evt: Event) {
  let positionEl = evt.currentTarget.parentNode.parentNode;
  getArticleSelectionDialog().then((artikel) => {
    if (artikel) {
      positionEl.querySelector(".artikelNr input").value = artikel.nr;
      positionEl.querySelector(".textarea textarea").value = artikel.bez;
      positionEl.querySelector("div[name=preisEinheit] input").value = numToStr(artikel.preis);

      positionEl.querySelector(".menge").addEventListener("change", (evt: Event) => {
        amountCheck(evt.target as any as HTMLInputElement, artikel.menge);
      });
    }
  });
}

export function getOrderEditPosHeader(pos?: AuftragsPositionHeader) {
  var newEl = <EditPositionDiv>document.createElement("div");
  newEl.setAttribute("data-art", "Header");
  newEl.className = "page-section invoice-position";
  newEl.innerHTML =
    '<span class="section-title">Überschrift</span>' +
    '<div class="section-content">' +
    '<div name="ueberschrift" class="content-item sf-mdl-textfield textarea">' +
    '<textarea style="font-weight:bold" type="text" rows= "1" placeholder' +
    '="Überschrift" id="ueberschrift1"></textarea>' +
    "</div>" +
    '<button class="sf-mdl-button ripple icon-button removePosition">' +
    `<svg ${deleteIcon.viewBox}>${deleteIcon.path}</svg>` +
    "</button>" +
    "</div>";

  if (pos) {
    newEl.querySelector("#" + "ueberschrift1").value = pos.ueberschrift;
  }

  newEl.querySelector("button.removePosition").addEventListener("click", function (evt) {
    newEl.parentNode.removeChild(newEl);
    setPositionNumber();
  });

  newEl.dataNet = 0;

  return newEl;
}

export function getOrderViewPosition(pos: AuftragsPositionItem, i: number) {
  var newEl;
  newEl = document.createElement("div");
  newEl.className = "page-section large-only position";
  newEl.innerHTML =
    '<span class="section-title toggleSectionDisplay">' +
    `Position ${i}: <svg width="24px" class="view-toggle" ${upIcon.viewBox}>${upIcon.path}</svg>` +
    "</span>" +
    '<div class="section-content">' +
    '<div class="page-section__category">' +
    "<span>Aritkel-Nr.: </span>" +
    '<div class="page-data view-artikelNr"></div>' +
    "</div>" +
    '<div class="page-section__category">' +
    "<span>Bezeichnung: </span>" +
    '<div class="page-data view-bezeichnung"></div>' +
    "</div>" +
    '<div class="page-section__category">' +
    "<span>Preis / Einheit: </span>" +
    '<div class="page-data view-preisEinheit"></div>' +
    "</div>" +
    '<div class="page-section__category">' +
    "<span>Rabatt: </span>" +
    '<div class="page-data view-rabatt"></div>' +
    "</div>" +
    '<div class="page-section__category">' +
    "<span>Menge: </span>" +
    '<div class="page-data view-menge"></div>' +
    "</div>" +
    '<div class="page-section__category">' +
    "<span>Preis (Summe): </span>" +
    '<div class="page-data view-preisSumme"></div>' +
    "</div>" +
    "</div>";

  newEl.querySelector(".view-artikelNr").innerText = pos.artikelNr;
  newEl.querySelector(".view-bezeichnung").innerText = pos.bezeichnung;
  newEl.querySelector(".view-preisEinheit").innerText = numToStr(pos.preisEinheit);
  newEl.querySelector(".view-menge").innerText = numToStr(pos.menge);
  newEl.querySelector(".view-rabatt").innerText = numToStr(pos.rabatt) + " %";

  var summe = Math.round(pos.preisEinheit * pos.menge * (1 - pos.rabatt / 100) * 100) / 100;
  newEl.querySelector(".view-preisSumme").innerText = numToStr(summe) + " €";

  return newEl;
}

export function getOrderViewPosHeader(pos: AuftragsPositionHeader) {
  var newEl;
  newEl = document.createElement("div");
  newEl.className = "page-section large-only position header";
  newEl.innerHTML =
    '<span style="font-weight:bold" class="section-title toggleSectionDisplay">Überschrift: ' +
    `<svg width="24px" class="view-toggle" ${upIcon.viewBox}>${upIcon.path}</svg>` +
    "</span>" +
    '<div class="section-content">' +
    '<div class="page-section__category">' +
    '<div style="font-weight:bold" class="page-data view-ueberschrift"></div>' +
    "</div>" +
    "</div>";

  newEl.querySelector(".view-ueberschrift").innerText = pos.ueberschrift;

  return newEl;
}
