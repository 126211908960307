import { navDestination } from "./navigation/navConstants";
import { destObject } from "./navigation/navigation";

export enum sessData {
  client = "client",
  order = "order",
  document = "document"
}

export class SessionData {
  public client: string;
  public car: string;
  public order: string;
  public document: string;
  public lastView: navDestination;

  private sessTimer: number;

  private saveSessionData() {
    window.clearTimeout(this.sessTimer);
    this.sessTimer = window.setTimeout(() => {
      window.sessionStorage.sessionData = JSON.stringify({
        client: this.client,
        order: this.order,
        car: this.car,
        document: this.document,
        lastView: this.lastView
      });
    }, 1000);
  }

  public setLastView(value: navDestination, element?: string): void {
    this.lastView = value;
    // case docsOverview does not need a destObject
    switch (value) {
      case navDestination.client:
        this.client = element;
        break;
      case navDestination.car:
        this.car = element;
        break;
      case navDestination.order:
        this.order = element;
        break;
      case navDestination.singleDocument:
        this.document = element;
        break;
    }
    this.saveSessionData();
  }

  public getLastDest(argDest?: navDestination): destObject {
    // case docsOverview does not need a destObject
    let tempDest = argDest || this.lastView;
    switch (tempDest) {
      case navDestination.client:
        return { element: this.client };
      case navDestination.car:
        return { element: this.car };
      case navDestination.order:
        return { element: this.order };
      case navDestination.singleDocument:
        return { element: this.document };
    }
  }

  constructor(storageArg?: string) {
    let sessObj: any;

    if (storageArg) {
      try {
        sessObj = JSON.parse(storageArg);
      } catch (err) {
        sessObj = { lastView: navDestination.docsOverview };
      }
    } else {
      sessObj = { lastView: navDestination.docsOverview };
    }

    this.client = sessObj.client;
    this.car = sessObj.car;
    this.order = sessObj.order;
    this.document = sessObj.document;
    this.lastView = sessObj.lastView;
  }
}
