import * as React from "react";
// own packages
import { subscribe } from "../../pubSub";
import { getFilteredOrders, getFilteredCars, FilterValue } from "./filterFunctions";
import { HitEl } from "./hitEl";
import { Button } from "../components/formComponents/button";
import { FilterRulesEnum, FilterbasisEnum } from "./constants";

interface SearchResultProps {
  specifySearch: boolean;
  filterList: { [key: string]: FilterValue };
}

interface state {
  listLength: number;
}

export class SearchResults extends React.Component<SearchResultProps, state> {
  private static initialListLength = 20;
  private static subscriberArr: ReturnType<typeof subscribe>[] = [];

  constructor(props: SearchResultProps) {
    super(props);
    this.state = { listLength: SearchResults.initialListLength };
  }

  showMoreItems = () => {
    this.setState(
      Object.assign({}, this.state, {
        listLength: this.state.listLength + SearchResults.initialListLength
      })
    );
  };

  componentWillUnmount() {
    SearchResults.subscriberArr.forEach(item => {
      item.remove();
    });
  }

  render() {
    let filterArr: { key: FilterRulesEnum; value: FilterValue }[] = Object.entries(
      this.props.filterList
    ).map(keyVal => {
      return { key: keyVal[0] as FilterRulesEnum, value: keyVal[1] };
    });
    let filterType = this.props.filterList[FilterRulesEnum.filterbasis];

    let itemArr: JSX.Element[] = [];
    if (filterType === FilterbasisEnum.cars) {
      itemArr = getFilteredCars(this.props.specifySearch, filterArr).map(carHitItem => {
        return (
          <HitEl
            key={carHitItem.searchItem.item.autoNr}
            filterType={filterType as FilterbasisEnum}
            filterHit={carHitItem}
          />
        );
      });
    } else {
      itemArr = getFilteredOrders(this.props.specifySearch, filterArr).map(orderHitItem => {
        return (
          <HitEl
            key={orderHitItem.searchItem.item.auftragsNr}
            filterType={filterType as FilterbasisEnum}
            filterHit={orderHitItem}
          />
        );
      });
    }

    return (
      <div className="filterResults">
        {itemArr.map((item, index) => {
          if (index < this.state.listLength) return item;
          else return null;
        })}
        <Button onClick={this.showMoreItems} disabled={itemArr.length < this.state.listLength}>
          Mehr anzeigen
        </Button>
      </div>
    );
  }
}
