import { pageNavInitPayload } from "../navigation/navigation";
import { navDestination } from "../navigation/navConstants";
import { pubSubTopic } from "../../pubSub";
import { subscribe, publish } from "../../pubSub";
import { fetchPage, saveData, addDisplayToggle } from "../globalFunctions";
import { getViewLink } from "../htmlElements/variousElements";
import { getSelectionDialog } from "../components/dialogs/selectionDialog";
import { getConfirmDialog } from "../components/dialogs/confirmDialog";
import { showToast } from "../components/toast";
import { getOffer, getInvoice } from "../docsPage/docFunctionality";
import { editOrder, saveOrder, orderAddDatalink, oeLinkType } from "./editFunctionality";
import { getOrderViewPosition, getOrderViewPosHeader } from "./orderElements";

import {
  AuftragsPosition,
  DokumentArt,
  Auftrag,
  AuftragStatus,
  AuftragsPositionHeader,
  AuftragsPositionItem,
} from "../../../../globalData/appInterfaces";
import { EditType } from "../../../../globalData/dataEditInterfaces";
import { getKeySearchString, strToDate, getVatRate } from "../../../../globalData/helperFunctions";
import { getClientAsString } from "../clientPage/clientHelpers";

export var viewURL = "pages/auftrag/anzeige.html";
export var editURL = "pages/auftrag/eingabe.html";

export var properties = ["titel", "datum", "zahlungsziel", "beschreibung", "zahlungsart", "kmStand", "status"];

export const orderNavHandling = function orderNavHandling(payload: pageNavInitPayload) {
  if (payload.destination === navDestination.order) {
    if (payload.destObject && window.data.auftrag[payload.destObject.element])
      return viewOrder(payload.destObject.element);
    else {
      return editOrder();
    }
  }
};

export const orderPageInit = function orderPageInit() {
  subscribe(pubSubTopic.pageNavInitiated, orderNavHandling);
};

export function chooseOrder() {
  var selectionList: string[] = [];
  var searchList: string[] = [];
  var keyArr: string[] = [];

  Object.values(window.data.auftrag)
    .sort((a, b) => (a.datum > b.datum ? -1 : 1))
    .forEach(function (order) {
      selectionList.push(order.datum + " - " + order.titel);
      searchList.push(getKeySearchString(order));
      keyArr.push(order.auftragsNr);
    });

  getSelectionDialog(selectionList, { searchList, keyArr }).then(function (el) {
    if (typeof el === "string") viewOrder(el);
  });
}

export function viewOrder(orderID: string) {
  fetchPage(viewURL, "anzeige", navDestination.order).then(function () {
    window.sessionData.setLastView(navDestination.order, orderID);

    setButtonFunctionality();
    auftragsDatenEintragen(window.data.auftrag[window.sessionData.order]);
  });
}

export function setButtonFunctionality() {
  function setFunctionBySelector(querySelector: string, clickFunction: () => void, onclick: boolean = false) {
    var tempList = document.querySelectorAll(querySelector);

    for (var i = 0; i < tempList.length; i++) {
      if (onclick) tempList[i].onclick = clickFunction;
      else tempList[i].addEventListener("click", clickFunction);
    }
  }

  setFunctionBySelector(".saveOrder", saveOrder);
  setFunctionBySelector(".newOrder", editOrder.bind(null, null));
  setFunctionBySelector(".createDocument", createDocument);
  setFunctionBySelector(".chooseOrder", chooseOrder);
  setFunctionBySelector(".deleteOrder", deleteOrder);

  // onclick so it can be accessed by tests
  setFunctionBySelector("button.orderEdit", editOrder.bind(null, window.sessionData.order), true);
  setFunctionBySelector("#btnShowDocuments", showDocuments, true);
  setFunctionBySelector(
    "#auto-assign-button",
    () => {
      orderAddDatalink(oeLinkType.car);
    },
    true
  );
  setFunctionBySelector(
    "#kunde-assign-button",
    () => {
      orderAddDatalink(oeLinkType.client);
    },
    true
  );
}

function showDocuments() {
  const currService = window.data.auftrag[window.sessionData.order];

  if (currService.dokumente.length === 0) showToast("Keine Dokumente für diesen Auftrag vorhanden");
  else {
    const tempArr = currService.dokumente.map(function (elID) {
      var temp = window.data.dokument[elID];
      return temp.nummer + " - " + temp.art + " - " + temp.titel + " - " + temp.speicherdatum;
    });
    getSelectionDialog(tempArr).then(function (index) {
      if (typeof index === "number") {
        publish(pubSubTopic.pageNavInitiated, {
          topic: pubSubTopic.pageNavInitiated,
          destination: navDestination.singleDocument,
          destObject: { element: currService.dokumente[index] },
        });
      }
    });
  }
}

function auftragsDatenEintragen(order: Auftrag) {
  properties.forEach(function (id) {
    document.getElementById("data-auftrag-" + id).innerHTML = (<any>order)[id];
  });

  document.getElementById("data-auftrag-auftragsnummer").innerText = order.auftragsNr;

  document.getElementById("data-auftrag-datum").innerHTML = strToDate(order.datum);
  if (order.zahlungsziel)
    document.getElementById("data-auftrag-zahlungsziel").innerHTML = strToDate(order.zahlungsziel);

  var netSum = order.positionen.reduce(function (curr: number, item: AuftragsPosition) {
    if ("ueberschrift" in item) return curr;
    else return item.preisEinheit * item.menge * (1 - item.rabatt / 100) + curr;
  }, 0);

  const vatRate = getVatRate(order.datum) * 100;
  document.getElementById("data-auftrag-netto").innerText =
    String(Math.round(netSum * 100) / 100).replace(".", ",") + " €";
  document.getElementById("data-auftrag-ust").innerText =
    String(Math.round(netSum * vatRate) / 100).replace(".", ",") + " €";
  document.getElementById("data-auftrag-brutto").innerText =
    String(Math.round(netSum * (100 + vatRate)) / 100).replace(".", ",") + " €";

  if (typeof order.auto === "string") {
    var el = getViewLink();
    el.classList.add("unique-button");
    el.setAttribute("data-element", order.auto);
    el.innerHTML =
      el.innerHTML + window.data.auto[order.auto].kennzeichen + " - " + window.data.auto[order.auto].hersteller;
    el.addEventListener("click", function () {
      publish(pubSubTopic.pageNavInitiated, {
        topic: pubSubTopic.pageNavInitiated,
        destination: navDestination.car,
        destObject: { element: order.auto },
      });
    });
    document.getElementById("data-auftrag-auto").appendChild(el);
  }

  if (typeof order.kunde === "string") {
    var el = getViewLink();
    el.classList.add("unique-button");
    el.setAttribute("data-element", order.kunde);
    el.innerHTML = el.innerHTML + getClientAsString(window.data.kunde[order.kunde]);
    el.addEventListener("click", function () {
      publish(pubSubTopic.pageNavInitiated, {
        topic: pubSubTopic.pageNavInitiated,
        destination: navDestination.client,
        destObject: { element: order.kunde },
      });
    });
    document.getElementById("data-auftrag-kunde").appendChild(el);
  }

  if (order.status === AuftragStatus.inArbeit) {
    document.getElementById("data-auftrag-status").setAttribute("style", "color: red; font-weight: bold");
  }

  window.data.auftrag[window.sessionData.order].positionen.forEach(function (pos, index) {
    if ("ueberschrift" in pos) addViewPositionHeader(pos);
    else addViewPosition(pos, index + 1);
  });
  addDisplayToggle();

  emptyCheck(order);
}

function emptyCheck(order: Auftrag) {
  ["kmStand"].forEach(function (id) {
    if (!(<any>order)[id]) document.getElementById("data-auftrag-" + id).parentNode.style.display = "none";
  });

  if (!order.beschreibung) {
    document.getElementById("data-auftrag-beschreibung").parentNode.parentNode.style.display = "none";
  }
}

function addViewPosition(pos: AuftragsPositionItem, index: number) {
  var carRow = document.getElementById("data-auftrag-auto");
  var el = getOrderViewPosition(pos, index);

  carRow.parentNode.insertBefore(el, carRow);
  carRow = null;
}

function addViewPositionHeader(pos: AuftragsPositionHeader) {
  var carRow = document.getElementById("data-auftrag-auto");
  var el = getOrderViewPosHeader(pos);

  carRow.parentNode.insertBefore(el, carRow);
  carRow = null;
}

export function deleteOrder() {
  getConfirmDialog("Soll dieser Service wirklich permanent gelöscht werden?").then(function (res) {
    if (res) {
      saveData([{ type: EditType.orderDelete, payload: window.sessionData.order }]).then(function (result) {
        if (result) editOrder();
      });
    }
  });
}

function createDocument() {
  var checkDocument = function checkDocument(type: string) {
    var text = type === "Rechnung" ? "eine Rechnung" : "ein Kostenvoranschlag";
    return getConfirmDialog(
      "Zu diesem Auftrag existiert bereits " + text + ". <br> " + "Soll dennoch dieses Dokument erstellt werden?"
    );
  };

  getSelectionDialog(["Kostenvoranschlag", "Rechnung"]).then(function (result) {
    if (typeof result !== "number") return;

    var tempOrder = window.data.auftrag[window.sessionData.order];
    if (result === 0) {
      if (
        tempOrder.dokumente.reduce(function (val, curr) {
          return val || window.data.dokument[curr].art === DokumentArt.kostenvoranschlag;
        }, false)
      ) {
        checkDocument("Kostenvoranschlag").then(function (res) {
          if (res) getOffer(tempOrder);
        });
      } else getOffer(tempOrder);
    } else if (result === 1) {
      if (
        tempOrder.dokumente.reduce(function (val, curr) {
          return val || window.data.dokument[curr].art === "Rechnung";
        }, false)
      ) {
        checkDocument("Rechnung").then(function (res) {
          if (res) getInvoice(tempOrder);
        });
      } else getInvoice(tempOrder);
    }
  });
}
